import { Link } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Error = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);

  return (
    isAuthenticated &&
    <div className="not-found-wrap text-center">
      <h1 className="text-60">Ooopsssss</h1>
      <p className="text-36 subheading mb-3">Errore!</p>
      <p className="mb-5  text-muted text-18">
        La pagina che stai cercando non esiste
      </p>
      <Link to="/" className="btn btn-lg btn-primary btn-rounded">
        Torna alla dashboard
      </Link>
    </div>
  );
};

export default Error;
