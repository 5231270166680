import { lazy } from "react";

const AccettazioneIndex = lazy(() => import("./accettazione/accettazione"));
const MenuAccettazione = lazy(() => import("./accettazione/MenuAccettazione"));
const ContabilitaIndex = lazy(() => import("./contabilita/contabilita"));
const RefertazioneIndex = lazy(() => import("./refertazione/refertazione"));
const MainIndex = lazy(() => import("./Main/MainIndex"));

const dashboardRoutes = [
  {
    path: "/accettazione",
    element: <AccettazioneIndex />
  },
  {
    path: "/accettazione/:isList",  // Aggiungi il parametro isList
    element: <AccettazioneIndex />
  },
  {
    path: "/accettazione/menu",
    element: <MenuAccettazione />
  },
  {
    path: "/contabilita",
    element: <ContabilitaIndex />
  },
  {
    path: "/refertazione",
    element: <RefertazioneIndex />
  },
  {
    path: "/Main",
    element: <MainIndex />
  },
];

export default dashboardRoutes;
