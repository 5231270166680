import axios from "axios";
import sessionStorageService from "./sessionStorageService";
import { fetchDataV2 } from "./apiService";


class JwtAuthService {
  loginWithToken = async () => {
    const data = await new Promise((resolve, reject) => {
      try 
      {
        const userInfo = this.getUser();
        if (!userInfo) {
          // Se il token non è presente, lo consideriamo scaduto
          //reject("Il token è scaduto o non è valido.");
            // eseguo anche il logout 
            this.logout();
            reject();
        
        } else {
          const tokenParts = userInfo.accessToken.split('.');
          if (tokenParts.length === 3) {
            const payload = JSON.parse(atob(tokenParts[1]));
            const tokenExpirationDate = new Date(payload.exp * 1000);
            const currentDate = new Date();
            if (currentDate > tokenExpirationDate) {
              // Il token è scaduto
              this.logout();
              reject();
                      //reject("Il token è scaduto.");
            } else {
              // Il token è valido
              resolve(this.getUser());
              //resolve("Il token è valido.");
            }
          } else {
            this.logout();
            reject();
                  // Token malformato, consideriamo come scaduto
            //reject("Il token è scaduto o non è valido.");
          }
        }
      }
      catch(error)
      {
        console.log(error);
      }
      
    });
    // NON HO BEN CAPITO QUESTE ISTRUZIONI A COSA SERVONO
    //if (data) {
    //  this.setSession(data.token);
    //  this.setUser(data);
    //}
    return data;
  };

  loginWithEmailAndPassword = async (credential) => {
    const apiUrl = "Auth/Login";
    const payload = {
      email: credential.email,
      password: credential.password
    };
    try {
      const result = await fetchDataV2(apiUrl, payload, undefined, "POST");
      if (result) {
        this.setSession(result.token);
        this.setUser(result);         
      }
      return result;
    } catch (error) {
      Promise.reject(error);
    }
  }

  logout = () => {
    this.setSession(null);
    this.removeUser();
    this.removeToken();
  };

  setSession = (token) => {
    if (token) {
      sessionStorageService.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      sessionStorageService.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  setUser = (user) => {
    sessionStorageService.setItem("auth_user", user);
  };

  getUser = () => {
    return sessionStorageService.getItem("auth_user");
  }

  removeUser = () => {
    sessionStorageService.removeItem("auth_user");
  };
  removeToken = () => {
    sessionStorageService.removeItem("jwt_token");
  };
  getToken =()=>{
    sessionStorageService.getItem("jwt_token");
  }

}

export default new JwtAuthService();
