import { Breadcrumb } from "@gull";
import React from "react";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { useEffect, useState, useCallback } from "react"
import { useDispatch } from "react-redux";
import { getQuery, getQueryPostDto } from "../../tabellaPocr/ServiceController";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Table } from "react-bootstrap";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CupPrenotazioniRisorse = () => {
    const initialFiltro = {
        idRisorsa: 0,
        periodo: 0,
        dataInizio: "",
        dataFine: "",
        elencoRisorse: [],
    }

    const [filtro, setFiltro] = useState(initialFiltro);
    const [isLoading, setLoading] = useState(false);
    const [elenco, setElenco] = useState([]);
    const [dottore, setDottore] = useState("");
    const [customDateRange, setCustomDateRange] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inizializzaFiltro = async () => {
        const data = await dispatch(getQuery("CupPrenotazioni/InitFilterRisorse"));
        setFiltro(data);
    }

    const getDottore = async () => {
        if (filtro.idRisorsa > 0) {
            const data = await dispatch(getQuery(`CupRisorse/GetRisorsa/${filtro.idRisorsa}`));
            setDottore(data);
        }
    }

    const handleFieldChange = useCallback((fieldName, value) => {
        setFiltro(prevFiltro => ({
            ...prevFiltro,
            [fieldName]: value,
        }));
    }, [setFiltro]);

    const calcolaDatePeriodo = (periodo) => {
        const oggi = new Date();
        let nuovaDataInizio = oggi.toISOString().split("T")[0];
        let nuovaDataFine = oggi;

        switch (periodo) {
            case 0: // Giornata odierna
                nuovaDataFine.setDate(oggi.getDate());
                nuovaDataFine = nuovaDataFine.toISOString().split("T")[0];
                break;
            case 1: // Questa settimana
                nuovaDataFine.setDate(oggi.getDate() + 7);
                nuovaDataFine = nuovaDataFine.toISOString().split("T")[0];
                break;
            case 2: // Prossimi 15 giorni
                nuovaDataFine.setDate(oggi.getDate() + 15);
                nuovaDataFine = nuovaDataFine.toISOString().split("T")[0];
                break;
            case 3: // Questo Mese
                nuovaDataFine.setDate(oggi.getDate() + 30);
                nuovaDataFine = nuovaDataFine.toISOString().split("T")[0];
                break;
            default:
                if (filtro.dataInizio) {
                    nuovaDataInizio = filtro.dataInizio;
                } else {
                    nuovaDataInizio = nuovaDataInizio;
                }
                if (filtro.dataFine) {
                    nuovaDataFine = filtro.dataFine;
                }
                break;
        }
        handleFieldChange("dataInizio", nuovaDataInizio);
        handleFieldChange("dataFine", nuovaDataFine);
    };

    const handlePrint = () => {
        const doc = new jsPDF({
            orientation: 'landscape'
        });
        const columns = ['#', 'Data', 'Inizio', 'Fine', 'Paziente', 'Esame'];

        const rows = elenco.map((item, index) => {
            return [
                index + 1,
                item.dataPrenotazione,
                item.inizio,
                item.fine,
                item.paziente,
                item.esame
            ];
        });

        let startY = 20;

        const header = dottore === "" ? "Lista Prenotazioni"
            : `${dottore.tipoAsString}: ${dottore.descrizione} - SEDE: ${dottore.sedeAsString}`;

        const addHeader = () => {
            doc.setFontSize(16);
            doc.text(`${header.toUpperCase()}`, 14, startY - 10);
        };

        const addTable = () => {
            addHeader();

            doc.autoTable({
                head: [columns],
                body: [...rows],
                startY: startY,
                margin: { top: 20 },
                didDrawPage: function (data) {
                    addHeader();
                },
                columnStyles: {
                    5: { align: 'left' }
                }
            });

            startY = doc.autoTable.previous.finalY + 10;
        };

        addTable();
        window.open(doc.output('bloburl'), '_blank');
    };

    const getElenco = () => {
        setLoading(true);
        dispatch(getQueryPostDto('CupPrenotazioni/ElencoPrenotazionRisorse', filtro))
            .then((result) => {
                setLoading(false);
                if (result.isSuccess) {
                    setElenco(result.data);
                } else {
                    NotificationManager.error("Error fetching data", result.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                NotificationManager.error("Error fetching data", "Errore");
                console.error('Error fetching data:', error);
            });
    }

    const handleElimina = (id) => {
        swal.fire({
            title: "Attenzione",
            html: "Questa azione non sarà reversibile, continuare?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Continua",
            cancelButtonText: "Annulla",
            reverseButtons: true,
            allowOutsideClick: false
        }).then((answer) => {
            if (answer.isConfirmed) {
                setLoading(true);
                dispatch(getQuery(`CupPrenotazioni/Elimina/${id}`))
                    .then((result) => {
                        if (result.isSuccess) {
                            getElenco();
                            NotificationManager.success("Avvenuta con successo", "Cancellazione");
                        }
                        else {
                            NotificationManager.error(result.message, "Errore");
                        }
                    })
                    .catch((error) => {
                        NotificationManager.error("Error fetching data", "Errore");
                        console.error('Error fetching data:', error);
                    });
                setLoading(false);
            } else {
                return;
            }
        });
    }

    useEffect(() => {
        setLoading(true);
        inizializzaFiltro()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Errore nel recupero dei dati:", error);
            });
    }, []);

    useEffect(() => {
        getDottore()
            .then(() => { }).catch((error) => {
                console.error("Errore nel recupero dei dati:", error);
            });
    }, [filtro.idRisorsa]);

    const handleSposta = async (obj) => {
        setLoading(true);
        const elencoRisorse = await dispatch(getQuery(`CupRisorse/GetRisorseByEsame/${obj.idesame}`));
        const req = {
            idprenotazione: obj.idprenotazione,
            stessoGiorno: false,
            stessoMedico: false,
            elencoEsami: [
                {
                    dataInizio: obj.dataP,
                    idEsame: obj.idesame,
                    prescrivibile: true,
                    esame: obj.esame,
                    listaIDRisorse: elencoRisorse
                }
            ]
        }
        const dataInizio = obj.dataP.split("T")[0];
        dispatch(getQueryPostDto('CupDisponibilitaFull/GetDisponibilitaByEsame', req))
            .then((result) => {
                if (result.isSuccess) {
                    navigate('/tab-cup/prenotazioni/overview', {
                        state: {
                            exams: result.data,
                            dataEsame: dataInizio,
                            data: req
                        }
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });

    }

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Breadcrumb
                        routeSegments={[
                            { name: "Prenotazioni", path: "/tab-cup/prenotazioni" },
                            { name: "Elenco Prenotazioni" },
                        ]}
                    />
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="idRisorsa" className="ul-form__label">
                                Risorse:
                            </label>
                            <select id="idRisorsa" className="form-control"
                                defaultValue={filtro.idRisorsa}
                                onChange={(e) =>
                                    handleFieldChange('idRisorsa', parseInt(e.target.value))
                                }
                                name="idRisorsa">
                                <option key={0} value={0}>Tutte</option>
                                {filtro.elencoRisorse.map((item, index) => (
                                    <option key={index} value={item.idrisorsa}>
                                        {item.descrizione}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="periodo" className="ul-form__label">
                                Periodo
                            </label>
                            <select id="periodo"
                                className="form-control"
                                defaultValue={filtro.periodo}
                                name="periodo"
                                onChange={(e) => {
                                    const valorePeriodo = parseInt(e.target.value, 10);
                                    setCustomDateRange(valorePeriodo === 4);
                                    setDateRangeValue(valorePeriodo);
                                    calcolaDatePeriodo(parseInt(e.target.value));
                                    handleFieldChange("periodo", e.target.value);
                                }}
                            >
                                <option key="0" value={0}>Oggi</option>
                                <option key="1" value={1}>Questa settimana</option>
                                <option key="2" value={2}>Prossimi 15 giorni</option>
                                <option key="3" value={3}>Prossimo mese</option>
                                <option key="4" value={4}>Personalizza</option>
                            </select>
                        </div>
                        <div className={`form-group col-4 ${customDateRange ? "d-block" : "d-none"}`}>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label htmlFor="dataInizio" className="ul-form__label">
                                        Data inizio:
                                    </label>
                                    <input type="date"
                                        id="dataInizio"
                                        className="form-control"
                                        value={filtro.dataInizio}
                                        onChange={(e) => {
                                            handleFieldChange('dataInizio', e.target.value)
                                        }}

                                        name="dataInizio">
                                    </input>
                                </div>
                                <div className="form-group col-4">
                                    <label htmlFor="dataFine" className="ul-form__label">
                                        Data fine:
                                    </label>
                                    <input type="date"
                                        id="dataFine"
                                        className="form-control"
                                        value={filtro.dataFine}
                                        onChange={(e) => handleFieldChange('dataFine', e.target.value)}
                                        name="dataFine">
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="btnGenera" className="ul-form__label">
                                Azioni
                            </label>
                            <div className="row">
                                <div className="col-6">
                                    <button
                                        onClick={getElenco}
                                        id="btnCerca"
                                        name="btnCerca"
                                        className="btn btn-success text-white">
                                        Cerca</button>
                                </div>
                                <div className="col-6">
                                    <button
                                        onClick={handlePrint}
                                        disabled={elenco.length == 0}
                                        id="btnStampa"
                                        name="btnStampa"
                                        className="btn btn-info text-white">
                                        Stampa
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            )}
            <NotificationContainer />
            <div className="row my-2">
                {elenco &&
                    <div className="table-responsive">
                        <Table
                            hover
                            id="p730_table"
                            className="table dataTable-collapse"
                        >
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Risorsa</th>
                                    <th scope="col">Data</th>
                                    <th scope="col">Ora inizio</th>
                                    <th scope="col">Ora fine</th>
                                    <th scope="col">Paziente</th>
                                    <th scope="col">Esame</th>
                                    <th scope="col">Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {elenco.map((mov, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{mov.risorsa}</td>
                                        <td>{mov.dataPrenotazione}</td>
                                        <td>{mov.inizio}</td>
                                        <td>{mov.fine}</td>
                                        <td>{mov.paziente}</td>
                                        <td className="text-left">{mov.esame}</td>
                                        <td>
                                            <div className="d-flex justify-content-between">
                                                <button className="btn btn-warning text-white" title="Sposta" onClick={() =>
                                                    handleSposta(mov)}><i className="i-Left---Right"></i></button>
                                                <span className="mx-1"></span>
                                                <button className="btn btn-danger text-white" title="Elimina" onClick={() =>
                                                    handleElimina(mov.idprenotazione)}><i className="i-Close-Window"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </div>
    )
}
export default CupPrenotazioniRisorse;