// anagraficaSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  anagraficaList: [],          // Stato iniziale per la lista anagrafica
  selectedAnagrafica: null,    // Stato per l'anagrafica corrente selezionata
};

const anagraficaSlice = createSlice({
  name: "anagrafica",
  initialState,
  reducers: {
    populateAnagraficaList: (state, action) => {
      state.anagraficaList = action.payload.anagraficaList; // Popola la lista di anagrafiche
    },
    selectAnagrafica: (state, action) => {
      state.selectedAnagrafica = action.payload.selectedAnagrafica; // Imposta l'anagrafica selezionata
    },
  },
});

export const { populateAnagraficaList,selectAnagrafica } = anagraficaSlice.actions;
export default anagraficaSlice.reducer;
