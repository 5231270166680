import React, { useEffect, useState, useRef } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { getQuery } from "../../tabellaPocr/ServiceController";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';

const CupUtenteForm = ({ oggetto, callback, onClose }) => {
    const initialState = {
        nomeUtente: '',
        email: '',
        password: '',
        cognome: '',
        nome: '',
        dataDiNascita: '',
        telefono: '',
        codiceFiscale: '',
        dataCreazione: ''
    };

    const dispatch = useDispatch();
    const [state, setState] = useState(oggetto || initialState);
    const { id } = useParams();

    const getDataAttuale = () => {
        const date = new Date();
        return date.toISOString().split('T')[0];
    };

    useEffect(() => {
        if (id) {
            dispatch(getQuery(`anagrafica/GetCupUtente/${id}`))
                .then(data => {
                    inizializzaDati(data);
                })
                .catch(error => {
                    console.log(error);
                })
        } else if (oggetto) {
            inizializzaDati(oggetto);
        } else {
            setState((prevState) => ({
                ...prevState,
                dataCreazione: getDataAttuale()
            }));
        }
    }, [id, oggetto]);

    const inizializzaDati = (data) => {
        data.dataDiNascita = data.dataDiNascita !== "" ? data.dataDiNascita.split("T")[0] : "";
        data.dataCreazione = data.dataCreazione !== "" ? data.dataCreazione.split("T")[0] : getDataAttuale();
        setState(data);
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const usernameAndPassword = generateUsernameAndPassword(values.cognome, values.nome);
        const dataToSend = {
            ...values,
            nomeUtente: usernameAndPassword,
            password: usernameAndPassword
        };
        try {
            NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
            callback(dataToSend);
            handleReset(resetForm);
            onClose();
        } catch (error) {
            NotificationManager.error("Errore durante l'invio dei dati", "Errore");
            console.error('Error submitting data:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleReset = (resetForm) => {
        setState(initialState);
        resetForm();
    };

    const generateRandomNumber = () => {
        return Math.floor(100000 + Math.random() * 900000);
    };

    const generateUsernameAndPassword = (cognome, nome) => {
        const sanitizeString = (str) => str.replace(/[^a-zA-Z]/g, '').toUpperCase();
        const randomNumber = generateRandomNumber();
        const user = `${sanitizeString(cognome).slice(0, 3)}_${sanitizeString(nome).slice(0, 3)}.${randomNumber}`;
        return user;
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card-body">
                        <div className="card-title mb-3">Utente</div>
                        <Formik
                            initialValues={state}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                        >
                            {({ values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                isSubmitting,
                                setFieldValue }) => (
                                <Form>
                                    <div className='row'>
                                        <div className="col-md-5 form-group mb-3">
                                            <label>Cognome</label>
                                            <Field
                                                id="cognome"
                                                name="cognome"
                                                placeholder="Cognome"
                                                className="form-control"
                                                type="text"
                                                value={values.cognome}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.toUpperCase();
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.cognome && touched.cognome && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.cognome}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-5 form-group mb-3">
                                            <label>Nome</label>
                                            <Field
                                                id="nome"
                                                name="nome"
                                                placeholder="Nome"
                                                className="form-control"
                                                type="text"
                                                value={values.nome}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.toUpperCase();
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.nome && touched.nome && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.nome}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <label>Data di nascita</label>
                                            <Field
                                                id="dataDiNascita"
                                                name="dataDiNascita"
                                                className="form-control"
                                                type="date"
                                                value={values.dataDiNascita}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.dataDiNascita && touched.dataDiNascita && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.dataDiNascita}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-4 form-group mb-3">
                                            <label>Codice Fiscale</label>
                                            <Field
                                                id="codiceFiscale"
                                                name="codiceFiscale"
                                                placeholder="Codice Fiscale"
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.codiceFiscale && touched.codiceFiscale && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.codiceFiscale}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <label>Telefono</label>
                                            <Field
                                                id="telefono"
                                                name="telefono"
                                                placeholder="Telefono"
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.telefono && touched.telefono && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.telefono}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <label>Email</label>
                                            <Field
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                className="form-control"
                                                type="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.email && touched.email && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.email}
                                                </div>
                                            )}
                                        </div>

                                        <hr />

                                        <div className="col-md-6 form-group mb-3" hidden>
                                            <label>Nome Utente</label>
                                            <Field
                                                id="nomeUtente"
                                                name="nomeUtente"
                                                placeholder="Nome Utente"
                                                className="form-control"
                                                type="text"
                                                value={values.nomeUtente}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.toUpperCase();
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.nomeUtente && touched.nomeUtente && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.nomeUtente}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-6 form-group mb-3" hidden>
                                            <label>Password</label>
                                            <Field
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                                className="form-control"
                                                type="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.password && touched.password && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.password}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-2 form-group mb-3">
                                            <label>Data di creazione</label>
                                            <Field
                                                id="dataCreazione"
                                                name="dataCreazione"
                                                className="form-control"
                                                type="date"
                                                value={values.dataCreazione}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={true}
                                            />
                                            {errors.dataCreazione && touched.dataCreazione && (
                                                <div className="text-danger mt-1 ml-2">
                                                    {errors.dataCreazione}
                                                </div>
                                            )}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 text-start">
                                                <Button variant="secondary" type="button" className='w-50 mt-2' onClick={onClose}>Chiudi</Button>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <Button type="submit" className='w-50 mt-2' disabled={isSubmitting}>Conferma e prenota</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

const validationSchema = yup.object({
    // nomeUtente: yup.string().required('Il campo è obbligatorio'),
    // password: yup.string().required('Il campo è obbligatorio'),
    email: yup.string().email('Email non valida').required('Il campo è obbligatorio'),
    codiceFiscale: yup.string().required('Il campo è obbligatorio'),
    cognome: yup.string().required('Il campo è obbligatorio'),
    nome: yup.string().required('Il campo è obbligatorio'),
    dataDiNascita: yup.date().required('Il campo è obbligatorio'),
    telefono: yup.string().matches(/^[0-9]*$/, 'Solo numeri sono ammessi').required('Il campo è obbligatorio'),
    dataCreazione: yup.date().required('Il campo è obbligatorio')
});
export default CupUtenteForm;
