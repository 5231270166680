import { Modal } from "react-bootstrap";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from "react-notifications";
import { getQueryGetDto } from "app/views/tabellaPocr/ServiceController";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import CupUtenteForm from "./cupUtenteForm";
import { format } from "date-fns";

const CupUtentiCerca = (props) => {
    const { handleSubmit } = props
    const noFlexStyle = {
        display: 'block',
    };

    const dispatch = useDispatch();
    const [cognomeNome, setCognomeNome] = useState('');
    const [elenco, setElenco] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hideShow, setHideShow] = useState(false);
    const [selected, setSelected] = useState();

    const handleHideShow = () => {
        setHideShow(!hideShow);
    }

    const handleSearchChange = (e) => {
        const inputValue = e.target.value;
        setCognomeNome(inputValue);

        if (inputValue.length > 3) {
            setIsLoading(true);
            const payload = {
                searchQuery: cognomeNome
            };
            dispatch(getQueryGetDto('anagrafiche/ElencoAnagrafiche', payload))
                .then(async (data) => {
                    setElenco(data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    NotificationManager.error("Error fetching data", "Errore");
                    console.error('Error fetching data:', error);
                });
        }
    };

    const handleAnagraficaClick = (id) => {
        // Trovo la mia anagrafica
        if (id !== 0) {
            const trova = elenco.find(item => item.id === id);
            if (trova) {
                const utente = {
                    nomeUtente: '',
                    password: '',
                    email: trova.email,
                    cognome: trova.cognome,
                    codiceFiscale: trova.codiceFiscale,
                    nome: trova.nome,
                    dataDiNascita: trova.dataNascita,
                    telefono: trova.telefono1,
                    dataCreazione: ""
                };
                setSelected(utente);
                handleHideShow();
            } else {
                NotificationManager.error("Anagrafica non trovata", "Errore");
            }
        } else {
            setSelected();
            handleHideShow();
        }
    };

    function formattaData(data) {
        const newData = new Date(data);
        return format(newData, 'dd/MM/yyyy')
    }

    return (
        <div>
            <div id="task-manager-list">
                <div className="content">
                    <div className="card" id="card">
                        <div className="card-body" id="card-body">
                            <div className="ul-task-manager__search-inline " style={noFlexStyle}>
                                <div className="col-sm-12">
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            autoComplete="off"
                                            id="ricercaSemplice"
                                            value={cognomeNome}
                                            onChange={handleSearchChange}
                                            placeholder="Ricerca per cognome e nome" />
                                        <button type="button" onClick={() => handleAnagraficaClick(0)} className="btn btn-primary">Nuova anagrafica</button>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {isLoading ? (
                                    <div className="text-center">
                                        <div className="spinner spinner-dark me-3"></div>
                                        <p>Caricamento...</p>
                                    </div>
                                ) : (
                                    <table
                                        id="names"
                                        className="table table-bordered custom-sm-width">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Cognome</th>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Data di nascita</th>
                                                <th scope="col">Telefono</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Azione</th>
                                            </tr>
                                        </thead>
                                        <thead className="bg-light">
                                            <tr>
                                                <th colSpan="7">Primi 30 risultati</th>
                                            </tr>
                                        </thead>
                                        {isLoading && <LoadingSpinner />}
                                        <tbody id="names">
                                            {elenco.map((anagrafica, ind) => (
                                                <tr key={ind} id="names">
                                                    <th scope="row" className="head-width">
                                                        #{ind + 1}
                                                    </th>
                                                    <td className="collection-item">
                                                        <div className="font-weight-bold">
                                                            <span><strong>{anagrafica.cognome} {anagrafica.nome}</strong></span>
                                                        </div>
                                                        <div className="text-muted">
                                                            ({anagrafica.cap}) {anagrafica.comuneResidenzaAsString} {anagrafica.indirizzo}
                                                        </div>
                                                    </td>
                                                    <td className="collection-item">
                                                        <div className="font-weight-bold">
                                                            <span><strong>{formattaData(anagrafica.dataNascita)}</strong></span>
                                                        </div>
                                                        <div className="text-muted">
                                                            età {anagrafica.eta} (sesso {anagrafica.sesso ? "M" : "F"}) nato a :{anagrafica.comuneNascitaAsString}
                                                        </div>
                                                    </td>
                                                    <td className="custom-align">
                                                        <div className="d-inline-flex align-items-center calendar align-middle">
                                                            <i className="i-Calendar-4"></i>
                                                            <span className="">{anagrafica.dataNascitaAsString}</span>
                                                        </div>
                                                    </td>
                                                    <td className="custom-align">
                                                        <div className="d-inline-flex align-items-center calendar align-middle">
                                                            <i className=""></i>
                                                            <span className="">{anagrafica.telefono1}</span>
                                                        </div>
                                                    </td>
                                                    <td className="custom-align">
                                                        <div className="d-inline-flex align-items-center calendar align-middle">
                                                            <i className=""></i>
                                                            <span className="">{anagrafica.email}</span>
                                                        </div>
                                                    </td>
                                                    <td className="custom-align" valign="middle">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <button
                                                                type="button"
                                                                onClick={() => handleAnagraficaClick(anagrafica.id)}
                                                                className="m-1 text-capitalize btn btn-success text-white"
                                                            >
                                                                Seleziona
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={hideShow} onHide={handleHideShow} size="xl" backdrop="static">
                <Modal.Body>
                    <CupUtenteForm oggetto={selected} callback={handleSubmit} onClose={handleHideShow} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CupUtentiCerca;
