import { createSlice } from "@reduxjs/toolkit";
import sessionStorageService from "../../services/sessionStorageService";
import jwtAuthService from "app/services/jwtAuthService";

const initialState = {
  isAuthenticated: false,
  accessToken: undefined,
  success: false,
  loading: false,
  error: {
    username: null,
    password: null,
  },
  errorMessage: null,
  user: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      // console.log(state);
      const user = action.payload;
      state = { ...state, ...user };
    },

    userLoggedIn: (state, action) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },

    userLoggedOut: (state) => {
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.user = undefined;
      sessionStorageService.removeItem("auth_user");
    },

    resetPassword: (state, action) => {
      state.success = true;
      state.loading = false;
    },

    requestStart: (state, action) => {
      state.loading = true;
    },

    loginWithEmailAndPassword: (state, action) => {
      try {
        const user = action.payload;
        state = {
          ...state,
          user: user,
          accessToken: user.token,
          success: true,
          loading: false,
          isAuthenticated: true,
        };
        sessionStorageService.setItem("auth_user", {
          accessToken: user.token,
          user: user,
        });
      } catch (error) {
        console.log(error);
      }
    },
    logoutJWTUser: (state) => {
      jwtAuthService.logout();
      state.user = undefined;
      state.isAuthenticated = false;
    },
  },
});

export const {
  setUserData,
  userLoggedIn,
  userLoggedOut,
  resetPassword,
  logoutJWTUser,
  loginWithEmailAndPassword,
} = authSlice.actions;
export default authSlice.reducer;
