import { format } from "date-fns";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CupPrenotazioniCalendarioForm from "../CupPrenotazioni/CupPrenotazioniCalendarioForm";
import { useNavigate } from "react-router-dom";

const CupPrenotaModal = (props) => {
    const { open, eventObject, date, closeDialog } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function formattaData(data, soloOra) {
        const newData = new Date(data);
        if (soloOra)
            return format(newData, 'HH:mm:ss');
        return format(newData, 'dd/MM/yyyy HH:mm');
    }

    const handleSubmit = (state) => {
        const newState = {
            ...state,
            ora: formattaData(date, true)
        }
        navigate('/tab-cup/prenotazioni/overview', {
            state: newState
        });
    }

    return (
        <Modal size="xl" show={open} centered={true} onHide={closeDialog}>
            <>
                <Modal.Header className="bg-primary text-white d-flex flex-wrap justify-content-between align-items-center">
                    <h4 className="m-0 text-white">Prenota</h4>
                    <i
                        className="i-Close-Window cursor-pointer text-18"
                        onClick={closeDialog}
                    ></i>
                </Modal.Header>
                {eventObject &&
                    <Modal.Body>
                        <div className="w-100">
                            <div className="ul-product-detail__brand-name mb-4">
                                <h5 className="heading">{eventObject.extendedProps.risorsa}</h5>
                            </div>
                            <div className="ul-product-detail__price-and-rating text-center">
                                {date &&
                                    <h4 className="font-weight-700 text-primary mb-0 mr-2">
                                        {formattaData(date)}
                                    </h4>
                                }
                            </div>
                        </div>
                        {eventObject &&
                            <CupPrenotazioniCalendarioForm idRisorsa={eventObject.extendedProps.idrisorsa} data={date} onClose={handleSubmit} />
                        }
                        <hr />
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                variant="primary"
                                onClick={closeDialog}>
                                Chiudi
                            </Button>
                        </div>
                    </Modal.Body>
                }
            </>
        </Modal>
    );
};

export default CupPrenotaModal;
