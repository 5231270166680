import { fetchData } from "app/services/apiService";

export const caricaListini = () => {
    const apiUrl = `Listini/ElencoListiniSelect`;
    return (dispatch) => {
        return dispatch(fetchData(apiUrl))
            .then((data) => {
                return data;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };
}

export const caricaRiepilogo = () => {
    const apiUrl = `MediciCompensi/Riepilogo`;
    return (dispatch) => {
        return dispatch(fetchData(apiUrl))
            .then((data) => {
                return data;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };
}