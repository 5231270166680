import { Fragment, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { format } from "date-fns";

const CupPrenotazioniBanner = (props) => {
    const { prenotazioni } = props;
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (prenotazioni !== null) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [prenotazioni]);

    function formattaData(data) {
        const newData = new Date(data);
        return format(newData, 'dd/MM/yyyy')
    }

    return (
        (show && prenotazioni) &&
        <Fragment>
            <div className="row">
                <div className="col-md-12" >
                    <Accordion className="mb-3">
                        <Accordion.Item >
                            <Accordion.Header className="w-100">
                                <b>Dettaglio Esami Selezionati</b>
                            </Accordion.Header>
                            <Accordion.Body>
                                {prenotazioni.map((item, index) => (
                                    <div className="d-flex align-items-justify" key={index}>
                                        <div className="w-100">
                                            <h6 className="fw-bold"><span className="text-success">#{index + 1} - </span> {item.esame}</h6>
                                        </div>
                                        <div className="w-75">
                                            <div className="row">
                                                <h6 className="mb-2 col-4">
                                                    <i className="fw-bolder i-Doctor text-primary"></i> {item.risorsa}
                                                </h6>
                                                <h6 className="mb-2 col-3">
                                                    <i className="fw-bold i-Calendar text-success"></i>
                                                    <span className="mx-1">{formattaData(item.dataInizio)}</span>
                                                </h6>
                                                <h6 className="mb-2 col-3">
                                                    <i className="fw-bold i-Clock text-info"></i>
                                                    <span className="mx-1">{item.oraInizio}</span>
                                                </h6>
                                                <h6 className="mb-2 col-2">
                                                    <i className="fw-bold i-Stopwatch text-warning"></i>
                                                    <span className="mx-1">{item.durata} min</span>
                                                </h6>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </Fragment>
    );
}

export default CupPrenotazioniBanner;
