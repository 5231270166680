import * as yup from "yup";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { useEffect } from "react";
import { Formik } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Breadcrumb } from "@gull";
import { useDispatch } from "react-redux";
import { salva, getElement, getQuery } from "../ServiceController";
import { Link } from "react-router-dom";

const FormConfigurazioniMail = () => {
    const initialState = {
        id: 0,
        idconfigurazione: 0,
        idstruttura: 0,
        port: 0,
        smtpServer: "",
        username: "",
        password: "",
        struttura: ""
    };

    const [intestazione, setIntestazione] = useState("Nuovo");
    const [state, setState] = useState(initialState);
    const [strutture, setStrutture] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getQuery('Strutture/ElencoStrutture'))
            .then(async (data) => {
                setStrutture(data);
            })
            .catch((error) => {
                NotificationManager.error("Error fetching data", "Errore");
                console.error('Error fetching data:', error);
            });
        if (id) {
            dispatch(getElement('ConfigurazioniMail/GetConfigurazione', id))
                .then(async (data) => {
                    setState(data);
                    setIntestazione("Modifica");
                })
                .catch((error) => {
                    NotificationManager.error("Error fetching data", "Errore");
                    console.error('Error fetching data:', error);
                });
        }
    }, [dispatch, id]);

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setState(values);
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        dispatch(salva('ConfigurazioniMail/Salva', values))
            .then((data) => {
                NotificationManager.success(data.message, "Salva");
                setTimeout(() => {
                    handleReset(resetForm);
                    navigate("/tabelle/configurazioni-mail");
                }, 500);
            }).catch((error) => {
                NotificationManager.error("Error submitting data", "Errore");
                console.error('Error submitting data:', error);
            }).finally(() => {
                setSubmitting(false);
            });
    };

    const handleReset = (resetForm) => {
        setState(initialState);
        resetForm();
    };

    return (
        <div>
            <Breadcrumb
                routeSegments={[
                    { name: "Configurazioni Mail", path: "/tabelle/configurazioni-mail" },
                    { name: intestazione },
                ]}
            />
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="card-title mb-3">{intestazione} Configurazione Mail</div>
                            <Formik
                                initialValues={state}
                                validationSchema={basicFormSchema}
                                onSubmit={handleSubmit}
                                enableReinitialize={true}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="idstruttura">Struttura</label>
                                                <select
                                                    id="idstruttura"
                                                    name="idstruttura"
                                                    className="form-control"
                                                    value={values.idstruttura}
                                                    onChange={(e) => setFieldValue("idstruttura", e.target.value)}
                                                    onBlur={handleBlur}
                                                >
                                                    <option key={-1} value={-1}>Seleziona...</option>
                                                    {strutture.map((item, index) => (
                                                        <option key={index} value={item.idstruttura}>{item.nome}</option>
                                                    ))}
                                                </select>
                                                {errors.idstruttura && touched.idstruttura && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.idstruttura}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="port">Port</label>
                                                <input
                                                    type="number"
                                                    id="port"
                                                    name="port"
                                                    className="form-control"
                                                    value={values.port}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.port && touched.port && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.port}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="smtpServer">SMTP Server</label>
                                                <input
                                                    type="text"
                                                    id="smtpServer"
                                                    name="smtpServer"
                                                    className="form-control"
                                                    value={values.smtpServer}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.smtpServer && touched.smtpServer && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.smtpServer}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="username">Username</label>
                                                <input
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    className="form-control"
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.username && touched.username && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.username}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    className="form-control"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.password && touched.password && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.password}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-lg-12 text-end">
                                                <Button type="submit" disabled={isSubmitting}>Salva</Button>
                                                <Link to="/tabelle/configurazioni-mail">
                                                    <button className="btn btn-outline-secondary m-1" type="button">
                                                        Annulla
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

const basicFormSchema = yup.object().shape({
    idstruttura: yup.number().required("Obbligatorio").min(1, "Seleziona una struttura valida"),
    port: yup.number().required("Obbligatorio").min(1, "Il valore deve essere maggiore di 0"),
    smtpServer: yup.string().required("Obbligatorio"),
    username: yup.string().required("Obbligatorio"),
    password: yup.string().required("Obbligatorio"),
});

export default FormConfigurazioniMail;
