import { lazy } from "react";

const InvoiceList = lazy(() => import("./InvoiceList"));
const InvoiceEditor = lazy(() => import("../../tabellaPocr/Fatture/FatturaCad"));

const invoiceRoutes = [
  {
    path: "/invoice/list",
    element: <InvoiceList />,
  },
  {
    path: "/invoice",
    element: <InvoiceEditor />,
  },
];

export default invoiceRoutes;
