import * as yup from "yup";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { useEffect } from "react";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Breadcrumb } from "@gull";
import { useDispatch } from "react-redux";
import { salva, getQuery } from "../../tabellaPocr/ServiceController";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link } from "react-router-dom";
import { fetchData } from "app/services/apiService";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";


const FormCupDisponibilita = () => {
    const [risorse, setRisorse] = useState([]);
    const [agenda, setAgenda] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const daysOfWeek = [
        { ord: 0, id: 1, nome: "Lunedì" },
        { ord: 1, id: 2, nome: "Martedì" },
        { ord: 2, id: 3, nome: "Mercoledì" },
        { ord: 3, id: 4, nome: "Giovedì" },
        { ord: 4, id: 5, nome: "Venerdì" },
        { ord: 5, id: 6, nome: "Sabato" },
        { ord: 6, id: 0, nome: "Domenica" }
    ];
    daysOfWeek.sort((a, b) => a.ord - b.ord);
    const initialState = {
        id: 0,
        idrisorsa: "",
        iddescrizioneAgenda: "",
        dataInizio: getDataOdierna(),
        dataFine: getDataOdierna(),
        configurazioni: daysOfWeek.map((day) => ({
            id: day.id,
            giorno: day.nome,
            idconfigurazione: 0,
            fasciaOrariaInizio: '',
            fasciaOrariaFine: ''
        }))
    };
    const [configurazioneState, setConfigurazioneState] = useState(initialState);

    const fetchResourcesAndAgendas = async () => {
        await Promise.all([
            dispatch(fetchDataFromApi("CupRisorse/ElencoRisorse", setRisorse)),
            dispatch(fetchDataFromApi(`CupDescrizioneAgenda/Elenco/0`, setAgenda)),
        ]);
    };

    // Funzione per ottenere la data nel formato yyyy-mm-dd
    function getDataOdierna() {
        const oggi = new Date();
        const anno = oggi.getFullYear();
        const mese = (oggi.getMonth() + 1).toString().padStart(2, '0');
        const giorno = oggi.getDate().toString().padStart(2, '0');
        return `${anno}-${mese}-${giorno}`;
    }

    useEffect(() => {
        fetchResourcesAndAgendas();
    }, [dispatch]);

    const handleRisorseChange = async (e) => {
        setConfigurazioneState(initialState);
        let value = e.target.value;
        if (value != "0") {
            await Promise.all([
                dispatch(fetchDataFromApi(`CupDescrizioneAgenda/Elenco/${value}`, setAgenda)),
            ]);
        }
    }

    const caricaElemento = (idrisorsa, iddescrizioneAgenda) => {
        if (idrisorsa != "0") {
            dispatch(getQuery(`CupConfigurazioniRisorse/GetConfigurazioneRisorse/${idrisorsa}/${iddescrizioneAgenda}`))
                .then(data => {
                    setConfigurazioneState((prevState) => ({
                        ...prevState,
                        idrisorsa: data.idrisorsa,
                        iddescrizioneAgenda: data.iddescrizioneAgenda,
                        configurazioni: [...data.configurazioni],
                    }));
                })
                .catch(error => {
                    NotificationManager.error("Error fetching data", "Errore");
                    console.error('Error fetching data:', error);
                });
        }
    }

    const fetchDataFromApi = (apiUrl, setData) => {
        return (dispatch) => {
            return dispatch(fetchData(apiUrl))
                .then((data) => {
                    setData(data);
                })
                .catch((error) => {
                    NotificationManager.error("Error fetching data", "Errore");
                    return Promise.reject(error);
                });
        };
    };

    const estraiConfigurazioniValide = (state) => {
        const configurazioniValide = state.configurazioni.filter(config =>
            config.fasciaOrariaInizio !== '' && config.fasciaOrariaFine !== ''
        );

        const config = configurazioniValide.map(config => ({
            idrisorsa: state.idrisorsa,
            giorno: config.id,
            datainizio: new Date(`${state.dataInizio} ${config.fasciaOrariaInizio}`),
            datafine: new Date(`${state.dataFine} ${config.fasciaOrariaFine}`),
            isodatainizio: new Date(`${state.dataInizio} ${config.fasciaOrariaInizio}`).toLocaleString(),
            isodatafine: new Date(`${state.dataFine} ${config.fasciaOrariaFine}`).toLocaleString(),
        }));
        return config;
    }

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        let validi = estraiConfigurazioniValide(values);
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        setSubmitting(true);
        try {
            const data = await dispatch(salva('CupDisponibilitaFull/CreaDisponibilita', validi));
            setLoading(false);
            if (data.isSuccess) {
                NotificationManager.success("Salvato con successo", "Salva");
                setTimeout(() => {
                    navigate(-1);
                }, 800);
            } else {
                NotificationManager.error(data.message, "Attenzione");
            }
        } catch (error) {
            NotificationManager.error("Error submitting data", "Errore");
            console.log(error);
        } finally {
            setSubmitting(false);
            setLoading(false);
        }
    };


    // Validate time format
    const validateTime = (value) => {
        const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
        if (!value) {
            return null;
        } else if (!timeRegex.test(value)) {
            return "Formato deve essere HH:MM";
        }
        return null;
    };

    const timeToMinutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    // Validate that end time is after start time
    const validateEndTime = (startTime, endTime) => {
        const startTimeError = validateTime(startTime);
        const endTimeError = validateTime(endTime);
        if (startTimeError || endTimeError) {
            return startTimeError || endTimeError;
        }
        const startMinutes = timeToMinutes(startTime);
        const endMinutes = timeToMinutes(endTime);
        if (endMinutes <= startMinutes) {
            return "L'orario di fine deve essere successivo all'orario di inizio";
        }
        return null;
    };

    // Handle changes and validate for end time
    const handleValidateEndTime = (e, values, setFieldError) => {
        const { name } = e.target;
        const index = name.split('.')[1];
        const startTime = values.configurazioni[index].fasciaOrariaInizio;
        const endTime = values.configurazioni[index].fasciaOrariaFine;

        // Validate both times again
        const startTimeError = validateTime(startTime);

        // Set errors appropriately
        if (startTimeError) {
            setFieldError(`configurazioni.${index}.fasciaOrariaInizio`, startTimeError);
        } else {
            setFieldError(`configurazioni.${index}.fasciaOrariaInizio`, null);
        }

        if (endTime !== "") {
            const endTimeError = validateEndTime(startTime, endTime) || validateTime(endTime);

            if (endTimeError) {
                setFieldError(name, endTimeError);
            } else {
                setFieldError(name, null);
            }
        }
    };

    // Gestore dell'evento onBlur
    const handleBlur = (e, values, setFieldError, setFieldTouched) => {
        const { name } = e.target;
        setFieldTouched(name, true, false);
        handleValidateEndTime(e, values, setFieldError);
    };

    const handleUpdate = (e, ris) => {
        var value = e.target.value;
        if (value !== "") {
            caricaElemento(ris, value);
        }
    }

    return (
        <div>
            <Breadcrumb routeSegments={[
                { name: "Disponibilità", path: "/tab-cup/disponibilita" },
                { name: "Nuovo" },
            ]} />
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="card-title mb-3">Disponibilità</div>
                            <Formik initialValues={configurazioneState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                enableReinitialize={true}>
                                {({
                                    values,
                                    handleSubmit,
                                    isSubmitting,
                                    handleChange,
                                    setFieldError,
                                    setFieldTouched
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row mt-2">
                                            <div className="col-md-5">
                                                <label htmlFor="idrisorsa">Risorse</label>
                                                <Field as="select" name="idrisorsa" className="form-control"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleRisorseChange(e);
                                                    }}>
                                                    <option value="">Seleziona...</option>
                                                    {risorse.map(res => (
                                                        <option key={res.idrisorsa} value={res.idrisorsa}>{res.descrizione}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage name="idrisorsa" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-5">
                                                <label htmlFor="iddescrizioneAgenda">Descrizione Agenda</label>
                                                <Field as="select" onChange={(e) => {
                                                    handleChange(e);
                                                    handleUpdate(e, values.idrisorsa);
                                                }} name="iddescrizioneAgenda" className="form-control">
                                                    <option value="">Seleziona...</option>
                                                    {agenda.map(ag => (
                                                        <option key={ag.iddescrizioneAgenda} value={ag.iddescrizioneAgenda}>{ag.descrizione}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage name="iddescrizioneAgenda" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="dataInizio">
                                                    Data inizio:
                                                </label>
                                                <input type="date"
                                                    id="dataInizio"
                                                    className="form-control"
                                                    value={values.dataInizio}
                                                    onChange={handleChange}
                                                    name="dataInizio">
                                                </input>
                                                <ErrorMessage name="dataInizio" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="dataFine">
                                                    Data fine:
                                                </label>
                                                <input type="date"
                                                    id="dataFine"
                                                    className="form-control"
                                                    value={values.dataFine}
                                                    onChange={handleChange}
                                                    name="dataFine">
                                                </input>
                                                <ErrorMessage name="dataFine" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <hr className="bg-primary" />
                                        <FieldArray name="configurazioni">
                                            {() => (
                                                <div>
                                                    {values.configurazioni.length > 0 &&
                                                        values.configurazioni.map((conf, ind) => (
                                                            <div className="mt-1" key={ind}>
                                                                <div className="row mb-2">
                                                                    <div className="col-1 pt-4">
                                                                        <div className="fw-bold" htmlFor={`configurazioni.${ind}.giorno`}>{daysOfWeek[ind].nome}</div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <div className="inline">Inizio</div>
                                                                        <input type="time"
                                                                            name={`configurazioni.${ind}.fasciaOrariaInizio`}
                                                                            className="form-control"
                                                                            onBlur={(e) => handleBlur(e, values, setFieldError, setFieldTouched)}
                                                                            onChange={handleChange}
                                                                            value={conf.fasciaOrariaInizio}
                                                                        />
                                                                        <ErrorMessage name={`configurazioni.${ind}.fasciaOrariaInizio`} component="div" className="text-danger" />
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <span>Fine</span>
                                                                        <input type="time"
                                                                            name={`configurazioni.${ind}.fasciaOrariaFine`}
                                                                            className="form-control"
                                                                            onBlur={(e) => handleBlur(e, values, setFieldError, setFieldTouched)}
                                                                            onChange={handleChange}
                                                                            value={conf.fasciaOrariaFine}
                                                                        />
                                                                        <ErrorMessage name={`configurazioni.${ind}.fasciaOrariaFine`} component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                            )}
                                        </FieldArray>
                                        <div className="row">
                                            <div className="col-lg-12 text-end">
                                                <Button className="mx-2" type="submit" disabled={isSubmitting}>Salva</Button>
                                                <Link to="/tab-cup/disponibilita">
                                                    <button className="btn btn-outline-secondary m-1" type="button">
                                                        Annulla
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </>
            )}
            <NotificationContainer />
        </div>
    );
};

const validationSchema = yup.object().shape({
    idrisorsa: yup.string().required('Il campo è obbligatorio').notOneOf([''], 'Il campo è obbligatorio'),
    dataInizio: yup.date()
        .required("La data di inizio è obbligatoria")
        .max(yup.ref('dataFine'), "La data di inizio non può essere successiva alla data di fine"),
    dataFine: yup.date()
        .required("La data di fine è obbligatoria")
        .min(yup.ref('dataInizio'), "La data di fine non può essere precedente alla data di inizio"),
});

export default FormCupDisponibilita;
