import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

function CupDisponibilitaBars({ data }) {
    // Calcolare l'ora minima e massima
    const getMinMaxHours = () => {
        let minHour = '23:59:59';
        let maxHour = '00:00:00';
        data.forEach(item => {
            const startHour = item.dataInizio.substring(11, 19);
            const endHour = item.dataFine.substring(11, 19);
            if (startHour < minHour) minHour = startHour;
            if (endHour > maxHour) maxHour = endHour;
        });
        return { minHour, maxHour };
    };

    // Convertire l'orario in minuti dall'inizio del giorno
    const timeToMinutes = time => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    // Convertire i minuti in formato orario "HH:mm"
    const minutesToHours = minutes => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    };

    // Raggruppamento delle disponibilità per giorno con intervalli occupati e liberi
    const groupByDate = (minMinutes, maxMinutes) => {
        const availabilityByDate = {};
        data.forEach(item => {
            const date = item.dataInizio.substring(0, 10);
            if (!availabilityByDate[date]) {
                availabilityByDate[date] = {
                    occupied: [],
                    na: [],
                    free: []
                };
            }
            const startMinutes = timeToMinutes(item.dataInizio.substring(11, 16));
            const endMinutes = timeToMinutes(item.dataFine.substring(11, 16));
            if (item.isLibera) {
                availabilityByDate[date].free.push({ start: startMinutes, end: endMinutes, occupied: false });
            } else {
                availabilityByDate[date].occupied.push({ start: startMinutes, end: endMinutes, occupied: true });
            }
        });

        // Calcolare gli intervalli non assegnati per ogni giorno
        Object.keys(availabilityByDate).forEach(date => {
            const dayData = availabilityByDate[date];
            let lastEnd = minMinutes;

            // Unire e ordinare gli intervalli occupati e liberi per trovare gli spazi non assegnati
            const allIntervals = [...dayData.occupied, ...dayData.free].sort((a, b) => a.start - b.start);
            allIntervals.forEach(time => {
                if (time.start > lastEnd) {
                    dayData.na.push({ start: lastEnd, end: time.start, occupied: null });
                }
                lastEnd = Math.max(lastEnd, time.end);
            });

            // Aggiungere l'intervallo non assegnato alla fine della giornata, se presente
            if (lastEnd < maxMinutes) {
                dayData.na.push({ start: lastEnd, end: maxMinutes, occupied: null });
            }
        });

        return availabilityByDate;
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString('it-IT', options);
    };

    // Calcolo e preparazione dei dati
    const { minHour, maxHour } = getMinMaxHours();
    const minMinutes = timeToMinutes(minHour);
    const maxMinutes = timeToMinutes(maxHour);
    const availability = groupByDate(minMinutes, maxMinutes);

    return (
        <div>
            {Object.keys(availability).map(date => (
                <div className='row mt-3' key={date}>
                    <div className='col-2'>
                        <h6 className='text-end fw-bold'>{formatDate(date).toUpperCase()}</h6>
                    </div>
                    <div className='col-9'>
                        <ProgressBar className="h-100">
                            {availability[date].occupied
                                .concat(availability[date].free, availability[date].na)
                                .sort((a, b) => a.start - b.start)
                                .map((interval, index) => (
                                    <ProgressBar
                                        key={index}
                                        now={(interval.end - interval.start) / (maxMinutes - minMinutes) * 100}
                                        label={`${minutesToHours(interval.start)} - ${minutesToHours(interval.end)}`}
                                        variant={interval.occupied === true ? 'warning' : interval.occupied === false ? 'primary' : 'light'}
                                    />
                                ))}
                        </ProgressBar>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CupDisponibilitaBars;