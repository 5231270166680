import { Link } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";

const Forbidden = () => {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className="row">
            <div className="col-lg-12 col-xl-12">
                <div className="ul-pricing__table-1 mb-4">
                    <div className="ul-pricing__image card-icon-bg-primary">
                        <i className="i-Angry"></i>
                    </div>
                    <div className="ul-pricing__title">
                        <h2 className="heading text-primary">Non autorizzato</h2>
                    </div>
                    <div className="ul-pricing__text text-mute">{user.user.email}</div>
                    <div className="ul-pricing__list">
                        <p>
                            L'utente non possiede i permessi per visualizzare questa risorsa
                        </p>
                    </div>
                    <Link to="/" className="btn btn-primary btn-rounded">
                        Torna alla dashboard
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Forbidden;
