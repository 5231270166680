import jsPDF from "jspdf";
import 'jspdf-autotable';
import React from "react";
import { Table } from "react-bootstrap";

const ListaAccettazioniDettaglio = (lista) => {
  const getRefertatoStatus = (dataRefertazione) => {
    const date = new Date(dataRefertazione);
    return date.getFullYear() === 1900 ? 'no' : 'si';
  };
  const handlePrint = () => {
    if (!lista || !lista.lista) {
      console.error("Lista non valida");
      return;
    }


    const doc = new jsPDF({
      orientation: "landscape",
    });

    const columns = [
      "#",
      "Utente",
      "Cod. Accettazione",
      "Data e Ora",
      "Paziente",
      "Grande cliente",
      "Registro Iva",
      "Tipo Pagamento",
      "Sede",
      "Saldo",
      "Completa"
    ];

    const getRefertatoStatus = (dataRefertazione) => {
      const date = new Date(dataRefertazione);
      return date.getFullYear() === 1900 ? 'no' : 'si';
    };

    const rows = lista.lista.reduce((acc, accettazione, index) => {
      const mainRow = [
        index + 1,
        accettazione.utente,
        accettazione.codice,
        accettazione.dataAsString,
        accettazione.paziente,
        accettazione.grandeCliente,
        accettazione.registro,
        accettazione.pagamento,
        accettazione.sede,
        accettazione.saldo ? "saldo aperto" : "",
        accettazione.completa ? "completa" : "NON completa"
      ];

      const detailRows = accettazione.dettaglioAccettazione.map((dettaglio, detIndex) => [
        "",
        accettazione.codice,
        detIndex + 1,
        dettaglio.esame.descrizione,
        dettaglio.prezzo,
        dettaglio.fatturato ? 'si' : 'no',
        getRefertatoStatus(dettaglio.dataRefertazione)
      ]);

      return [...acc, mainRow, ...detailRows];
    }, []);

    const addHeader = () => {

      doc.setFontSize(16);
      doc.text("Lista dettaglio accettazione", 14, 10);
    };

    const addTable = () => {
      addHeader();

      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
        margin: { top: 20 },
        didDrawPage: function (data) {
          addHeader();
        },
        didDrawCell: function (data) {
          if (data.row.section === 'body') {
            const { doc, cell } = data;
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.1);
            doc.line(cell.x, cell.y, cell.x + cell.width, cell.y);
          }
        },
      });
    };

    addTable();
    window.open(doc.output("bloburl"), "_blank");
  };

  if (!Array.isArray(lista.lista) || lista.lista.length === 0) {
    return <div>Nessun dato disponibile</div>;
  }
  return (
    <div>
      {lista.lista.length > 0 && (
        <div className="row">
          <button
            onClick={handlePrint}
            className="col-1 mx-3 my-2 btn btn-primary"
          >
            Stampa Griglia dettaglio
          </button>
        </div>
      )}
      <div className="table-responsive">
        <Table
          hover
          id="user_table"
          className="table dataTable-collapse text-center"
        >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Utente</th>
              <th scope="col">Cod. Accettazione</th>
              <th scope="col">Data e Ora</th>
              <th scope="col">Paziente</th>
              <th scope="col">Grande cliente</th>
              <th scope="col">Registro Iva</th>
              <th scope="col">Tipo Pagamento</th>
              <th scope="col">Sede</th>
              <th scope="col">Saldo</th>
              <th scope="col">completa</th>
            </tr>
          </thead>
          <tbody>
            {lista.lista.map((accettazione, index) => (
              <React.Fragment key={index}>
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{accettazione.utente}</td>
                  <td>{accettazione.codice}</td>
                  <td>{accettazione.dataAsString}</td>
                  <td>{accettazione.paziente}</td>
                  <td>{accettazione.grandeCliente}</td>
                  <td>{accettazione.registro}</td>
                  <td>{accettazione.pagamento}</td>
                  <td>{accettazione.sede}</td>
                  <td> {accettazione.saldo ? "saldo aperto" : ""}</td>
                  <td> {accettazione.completa ? "completa" : "NON completa"}</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="border"><b>{accettazione.codice}</b></td>
                  <td colSpan="9">
                    <Table>
                      <thead>
                        <tr>
                          <td className="text-start">
                            Esame
                          </td>
                          <td>
                            Prezzo
                          </td>
                          <td>
                            Fatturato
                          </td>
                          <td>
                            Refertato
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {accettazione.dettaglioAccettazione && accettazione.dettaglioAccettazione.map((dettaglio, detIndex) => (
                          <tr key={detIndex}>
                            <td className="text-start">{dettaglio.esame.descrizione}</td>
                            <td>{dettaglio.prezzo}</td>
                            <td>{dettaglio.fatturato ? 'si' : 'no'}</td>
                            <td>{getRefertatoStatus(dettaglio.dataRefertazione)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default ListaAccettazioniDettaglio;
