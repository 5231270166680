import { SimpleCard } from "@gull";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { queryServerPost } from "../ServiceController";
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';

const DettaglioCompensi = (props, { onSave }) => {
    const { idListino, fisso, percentuale, fissoSSN, percentualeSSN, dataInizio, dataFine, isModifica, selezioneAbilitata } = props;
    const id = props.idMedico;
    const [state, setState] = useState({
        esamiList: [],
    });

    const [dataReady, setDataReady] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [ambulatorioSelezionato, setAmbulatorioSelezionato] = useState('');

    const handleGridChange = (newGridData) => {
        if (props.onGridChange) {
            props.onGridChange(newGridData);
        }
    };

    const dispatch = useDispatch();
    const GRIGLIA_ESAMI = [
        {
            dataField: "idMedicoCompenso",
            text: "id",
            hidden: true,
            editable: false
        },
        {
            dataField: "idlistino",
            text: "idlistino",
            hidden: true,
            editable: false
        },
        {
            dataField: "ambulatorio",
            text: "Ambulatorio",
            sort: true,
            hidden: false,
            editable: false,
            headerStyle: { width: '25%' },
            style: { width: '25%' },
            formatter: cell => selectOptions[cell],
            filter: selectFilter({
                options: selectOptions,
                onFilter: (filterValue) => {
                    setAmbulatorioSelezionato(filterValue);
                }
            })
        },
        {
            dataField: "esame",
            text: "Esame",
            headerStyle: { width: '25%' },
            style: { width: '25%' },
            sort: true,
            hidden: false,
            editable: false,
        },
        {
            dataField: "prezzoAsl",
            text: "Prezzo Asl",
            sort: true,
            hidden: false,
            editable: false,
            align: 'right'
        },
        {
            dataField: "prezzoPrivato",
            text: "Prezzo privato",
            sort: true,
            hidden: false,
            editable: false,
            align: 'right',
        },
        {
            dataField: "compensoFisso",
            text: "Compenso Fisso",
            sort: true,
            hidden: false,
            align: 'right',
            formatter: (cell) => {
                return parseFloat(cell).toFixed(2);
            }
        },
        {
            dataField: "compensoPercentuale",
            text: "Compenso Percentuale",
            sort: true,
            hidden: false,
            align: 'right'
        },
        {
            dataField: "compensoFissoSsn",
            text: "Compenso Fisso SSN",
            sort: true,
            hidden: false,
            align: 'right',
            formatter: (cell) => {
                return parseFloat(cell).toFixed(2);
            }
        },
        {
            dataField: "compensoPercentualeSsn",
            text: "Compenso Percentuale SSN",
            sort: true,
            hidden: false,
            align: 'right'
        },
        {
            dataField: "dataInizio",
            text: "Inizio",
            sort: true,
            hidden: true,
            editable: false,
            align: 'right',
            formatter: (cell) => {
                if (cell) {
                    const date = new Date(cell);
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    return date.toLocaleDateString('it-IT', options);
                }
                return "";
            }
        },
        {
            dataField: "dataFine",
            text: "Fine",
            sort: true,
            editable: false,
            hidden: true,
            align: 'right',
            formatter: (cell) => {
                if (cell) {
                    const date = new Date(cell);
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    return date.toLocaleDateString('it-IT', options);
                }
                return "";
            },
        },
        {
            dataField: "isEdit",
            text: "isEdit",
            sort: true,
            hidden: true,
        },

    ]

    const options = {
        paginationSize: 20, // Imposta il numero di pagine visibili nella barra di paginazione
    }

    const handleFilterAndModify = () => {
        const updatedEsamiList = state.esamiList.map(esame => {
            if (!ambulatorioSelezionato || esame.ambulatorio === ambulatorioSelezionato) {
                return {
                    ...esame,
                    compensoFisso: fisso,
                    compensoPercentuale: percentuale,
                    compensoFissoSsn: fissoSSN,
                    compensoPercentualeSsn: percentualeSSN,
                    dataInizio: dataInizio,
                    dataFine: dataFine,
                    idListino: idListino,
                    isEdit: true,
                };
            }
            return esame;
        });
        setState({
            ...state,
            esamiList: updatedEsamiList,
        });
        handleGridChange(updatedEsamiList);
    };

    function aggiornaElementoManualmente(idMedicoEsame, compensoFisso, compensoPercentuale,
        compensoFissoSsn, compensoPercentualeSsn, dataInizio, dataFine) {
        const elemento = state.esamiList.find(item => item.idMedicoEsame === idMedicoEsame);
        if (elemento !== undefined) {
            elemento.isEdit = true;
            elemento.compensoFisso = compensoFisso;
            elemento.compensoPercentuale = compensoPercentuale;
            elemento.compensoFissoSsn = compensoFissoSsn;
            elemento.compensoPercentualeSsn = compensoPercentualeSsn;
            elemento.dataInizio = dataInizio;
            elemento.dataFine = dataFine;
        }
        handleGridChange(state.esamiList);
    }

    useEffect(() => {
        if (id) {
            const payload = {
                idMedicoRefertante: id,
                idListino,
                dataInizio: isModifica ? dataInizio : null,
                dataFine: isModifica ? dataFine : null,
            };
            dispatch(queryServerPost(`MediciCompensi/DettaglioEsamiCompensi`, payload))
                .then(async (data) => {
                    const distinctAmbulatorioArray = [...new Set(data.map(item => item.ambulatorio))];
                    distinctAmbulatorioArray.sort();
                    const ambulatoriMenu = distinctAmbulatorioArray.reduce((options, value) => {
                        options[value] = value;
                        return options;
                    }, {});
                    setSelectOptions(ambulatoriMenu);
                    let esamiList = data.map(
                        ({ idMedicoCompenso, idMedicoEsame, idEsame, ambulatorio, esame, prezzoAsl, prezzoPrivato, compensoFisso, compensoPercentuale, compensoFissoSsn, compensoPercentualeSsn, dataInizio, dataFine, isSalvato, isEdit }, ind) => ({
                            idMedicoCompenso,
                            idMedicoEsame,
                            idEsame,
                            ambulatorio,
                            esame,
                            prezzoAsl,
                            prezzoPrivato,
                            compensoFisso,
                            compensoPercentuale,
                            compensoFissoSsn,
                            compensoPercentualeSsn,
                            dataInizio,
                            dataFine,
                            isSalvato,
                            isEdit,
                            index: ind + 1,
                        })
                    );
                    setState({ esamiList });
                    setDataReady(true);
                })
                .catch((error) => {
                    NotificationManager.error("Error fetching data", "Errore");
                    console.error('Error fetching data:', error);

                });
        }
    }, [dispatch, id, idListino]);

    let { esamiList } = state;
    if (!dataReady) {
        return <p></p>;
    }
    return (
        <div>
            <SimpleCard title="Modifica prezzo listino">
                <div className="my-2 row">
                    <div className="col-6">
                        <b>Note: </b> Puoi cambiare il prezzo del singolo compenso manualmente
                    </div>
                    <div className="ul-product-cart__button text-end col-6">
                        <button type="button" disabled={selezioneAbilitata} onClick={handleFilterAndModify} className="btn-icon m-1 text-capitalize text-white btn btn-success">
                            Applica alla selezione
                        </button>
                    </div>
                </div>
                <BootstrapTable
                    bootstrap4
                    keyField="index"
                    data={esamiList}
                    columns={GRIGLIA_ESAMI}
                    cellEdit={cellEditFactory({
                        mode: "click",
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, column) => {
                            row.isEdit = true;
                            switch (column.dataField) {
                                case "compensoFisso":
                                    if (oldValue !== newValue) {
                                        row.compensoFisso = newValue;
                                        row.compensoPercentuale = 0;
                                    }
                                    break;
                                case "compensoPercentuale":
                                    if (oldValue !== newValue) {
                                        row.compensoPercentuale = newValue;
                                        row.compensoFisso = 0;
                                    }
                                    break;
                                case "compensoFissoSsn":
                                    if (oldValue !== newValue) {
                                        row.compensoFissoSsn = newValue;
                                        row.compensoPercentualeSsn = 0;
                                    }
                                    break;
                                case "compensoPercentualeSsn":
                                    if (oldValue !== newValue) {
                                        row.compensoPercentualeSsn = newValue;
                                        row.compensoFissoSsn = 0;
                                    }
                                    break;
                                default:
                                    break;
                            }
                            aggiornaElementoManualmente(
                                row.idMedicoEsame,
                                row.compensoFisso,
                                row.compensoPercentuale,
                                row.compensoFissoSsn,
                                row.compensoPercentualeSsn,
                                row.dataInizio,
                                row.dataFine
                            );
                        },
                    })}
                    pagination={paginationFactory(options)}
                    noDataIndication={"Tabella vuota"}
                    filter={filterFactory()}
                />
            </SimpleCard>
        </div>
    );
};

export default DettaglioCompensi;
