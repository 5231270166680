import { lazy } from "react";
import FormCupDescrizioneAgenda from "./CupAgenda/FormCupDescrizioneAgenda";
import FormCupConfigurazioniRisorse from "./CupConfigurazioneRisorse/FormCupConfigurazioniRisorse";
import ConfigurazioneRisorseTable from "./CupConfigurazioneRisorse/ConfigurazioneRisorseTable";
import FormCupDisponibilita from "./CupDisponibilita/FormCupDisponibilita";
import CupDisponibilitaTable from "./CupDisponibilita/CupDisponibilitaTable";
import CupPrenotazioniForm from "./CupPrenotazioni/CupPrenotazioniForm";
import CupPrenotaForm from "./CupPrenotazioni/CupPrenotaForm";
import CupUtenteForm from "./CupUtenti/cupUtenteForm";
import CupPrenotazioniRisorse from "./CupPrenotazioni/CupPrenotazioniRisorse";
import CupCalendario from "./CupCalendario/CupCalendario";
import CupGestionePrenota from "./CupPrenotazioni/CupGestionePrenota";

const DashboardTabelleCup = lazy(() => import("./DashboardTabelleCup"));
const ListInTable = lazy(() => import("../tabellaPocr/ElencoGenerale/ListInTable"));
const EsamiRisorseTable = lazy(() => import("./CupEsamiRisorse/EsamiRisorseTable"));
const EsamiRisorseForm = lazy(() => import("./CupEsamiRisorse/FormCupEsamiRisorseTable"));
const CupRisorse = lazy(() => import("./CupRisorse/FormCupRisorseTable"));
const CupFestivita = lazy(() => import("./CupFestivita/FormCupFestivitaTable"));


const cupRoutes = [
    // Dashboard tabelle cup
    {
        path: "tab-cup",
        element: <DashboardTabelleCup />,
    },
    // Risorse
    {
        path: "tab-cup/CupRisorse",
        element: <CupRisorse />,
    },
    {
        path: "tab-cup/CupRisorse/:id",
        element: <CupRisorse />,
    },
    // Festivita 
    {
        path: "tab-cup/CupFestivita",
        element: <CupFestivita />,
    },
    {
        path: "tab-cup/CupFestivita/:id",
        element: <CupFestivita />,
    },
    // Esami Risorse
    {
        path: "tab-cup/esami-risorse",
        element: <EsamiRisorseTable />,
    },
    {
        path: "tab-cup/esami-risorse/:id",
        element: <EsamiRisorseForm />,
    },
    // Descrizione Agenda
    {
        path: "tab-cup/agenda/edit",
        element: <FormCupDescrizioneAgenda />,
    },
    {
        path: "tab-cup/agenda/edit/:id",
        element: <FormCupDescrizioneAgenda />,
    },
    // Configurazione Risorse 
    {
        path: "tab-cup/modello-agenda",
        element: <ConfigurazioneRisorseTable />,
    },
    {
        path: "tab-cup/modello-agenda/edit",
        element: <FormCupConfigurazioniRisorse />,
    },
    {
        path: "tab-cup/modello-agenda/edit/:ris/:ag",
        element: <FormCupConfigurazioniRisorse />,
    },
    // Disponibilita
    {
        path: "tab-cup/disponibilita",
        element: <CupDisponibilitaTable />,
    },
    {
        path: "tab-cup/disponibilita/edit",
        element: <FormCupDisponibilita />,
    },
    // Prenotazioni
    {
        path: "tab-cup/prenotazioni",
        element: <CupPrenotazioniForm />,
    },
    {
        path: "tab-cup/prenotazioni/overview",
        element: <CupPrenotaForm />,
    },
    {
        path: "tab-cup/prenotazioni/overview/prenota",
        element: <CupGestionePrenota />,
    },
    {
        path: "tab-cup/prenotazioni-risorse",
        element: <CupPrenotazioniRisorse />,
    },
    // Calendario
    {
        path: "tab-cup/calendario",
        element: <CupCalendario />,
    },
    // Utenti
    {
        path: "tab-cup/utenti/edit",
        element: <CupUtenteForm />,
    },
    {
        path: "tab-cup/utenti/edit/:id",
        element: <CupUtenteForm />,
    },
    // QUESTA DEVE ESSERE SEMPRE L'ULTIMA ROUTE
    // Generica tabelle cup
    {
        path: "tab-cup/:id",
        element: <ListInTable isCup="true" />,
    },
];
export default cupRoutes;
