import { useCallback, useEffect, useState } from "react";
import { getQuery, getQueryPostDto, initialAccettazioniElenchi, initialFiltroAccettazioniStampe } from "../ServiceController";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { Breadcrumb } from "@gull";
import { Table } from "react-bootstrap";
import ListaAccettazioni from "./ListaAccettazioni";
import ListaAccettazioniDettaglio from "./ListaAccettazioniDettaglio";
import { useLocation } from "react-router-dom";

const AccettazioneSaldi = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [elenco, setElenco] = useState([]);
    const [filtro, setFiltro] = useState(initialFiltroAccettazioniStampe);
    const [elenchi, setElenchi] = useState(initialAccettazioniElenchi);
    const [customDateRange, setCustomDateRange] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState(0);
    const { user } = useSelector((state) => state.auth);
    const location = useLocation();
    const state = location.state || {};
    const daAccettazione = state.daAccettazione ?? false;

    const userRuoli = user.user.ruoli;

    // Imposta isAdmin dopo aver ottenuto userRuoli
    useEffect(() => {
        setIsAdmin(userRuoli.includes('ADMIN'));
    }, [userRuoli]);

    const handleFieldChange = useCallback((fieldName, value) => {
        setFiltro(prevFiltro => ({
            ...prevFiltro,
            [fieldName]: value,
        }));
    }, []);

    const calcolaDatePeriodo = (periodo) => {
        const oggi = new Date();
        let nuovaDataInizio = oggi;
        let nuovaDataFine = oggi.toISOString().split("T")[0];

        switch (periodo) {
            case 0: // Giornata odierna
                nuovaDataInizio.setDate(oggi.getDate());
                nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                break;
            case 1: // Ultimi 7 giorni
                nuovaDataInizio.setDate(oggi.getDate() - 7);
                nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                break;
            case 2: // Ultimi 15 giorni
                nuovaDataInizio.setDate(oggi.getDate() - 15);
                nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                break;
            case 3: // Ultimo mese
                nuovaDataInizio.setDate(oggi.getDate() - 30);
                nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                break;
            default:
                if (filtro.dataInizio) {
                    nuovaDataInizio = filtro.dataInizio;
                } else {
                    nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                }
                if (filtro.dataFine) {
                    nuovaDataFine = filtro.dataFine;
                }
                break;
        }
        handleFieldChange("dataInizio", nuovaDataInizio);
        handleFieldChange("dataFine", nuovaDataFine);
    };

    const inizializzaFiltroElenchi = async () => {
        const result = await dispatch(getQuery(`Accettazione/InitFiltriAccettazione/${isAdmin}`));
        if (result.isSuccess) {
            setElenchi(result.data.elenchi);
            setFiltro(result.data.filtro);
        } else {
            NotificationManager.error("Error fetching data", result.message);
        }
    };

    const inizializza = () => {
        setLoading(true);
        inizializzaFiltroElenchi().then(() => {
            calcolaDatePeriodo(0);
            setLoading(false);
        }).catch((error) => {
            console.error("Errore nel recupero dei dati:", error);
            setLoading(false); // Imposta isLoading su false in caso di errore
        });
    };

    const handleAzzeraFiltri = () => {
        inizializza();
        setElenco([]);
    };

    const getSearchResultsFromLocalStorage = () => {
        const savedResults = localStorage.getItem('searchSettings');
        return savedResults ? JSON.parse(savedResults) : [];
    };

    useEffect( () => {
        if (daAccettazione) {
            const results = getSearchResultsFromLocalStorage();

            if (results!==null) {
                setFiltro(results);
                handleCerca(results);
            }
        }
        else
        {
            inizializza();
            localStorage.removeItem('searchSettings')
        }
    }, []);

    const handleCerca = (resultFilter) => {
        setLoading(true);
        let preFilter = filtro; 
        if(resultFilter!=null) 
            preFilter = resultFilter;
        dispatch(getQueryPostDto('Accettazione/FiltraAccettazioni', preFilter))
            .then(async (result) => {
                if (result.isSuccess) {
                    setElenco(result.data);
                    localStorage.setItem('searchSettings', JSON.stringify(preFilter));
                } else {
                    NotificationManager.error("Error fetching data", result.message);
                }
                setLoading(false);
            })
            .catch((error) => {
                NotificationManager.error("Error fetching data", "Errore");
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Breadcrumb
                        routeSegments={[
                            { name: "Accettazioni", path: "/accettazioni" },
                            { name: "Accettazioni Stampe" },
                        ]}
                    />

                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="idUtente" className="ul-form__label">
                                Utente:
                            </label>
                            <div className="input-right-icon">
                                <select id="idUtente" className="form-control"
                                    value={filtro.idUtente}
                                    disabled={!isAdmin}
                                    onChange={(e) =>
                                        handleFieldChange('idUtente', e.target.value)
                                    }
                                    name="idUtente">
                                    <option key={0} value={""}>Tutti</option>
                                    {elenchi.elencoUtenti && elenchi.elencoUtenti.map((item, index) => (
                                        <option key={index} value={item.userID}>
                                            {item.lastName.toUpperCase()} {item.firstName.toUpperCase()}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="idSede" className="ul-form__label">
                                Sede:
                            </label>
                            <div className="input-right-icon">
                                <select id="idSede" className="form-control"
                                    value={filtro.idSede}
                                    onChange={(e) =>
                                        handleFieldChange('idSede', parseInt(e.target.value))
                                    }
                                    name="idSede">
                                    <option key={0} value={0}>Seleziona...</option>
                                    {elenchi.elencoSedi && elenchi.elencoSedi.map((item, index) => (
                                        <option key={index} value={item.idsede}>
                                            {item.descrizione.toUpperCase()}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="idRegistroIva" className="ul-form__label">
                                Registro IVA:
                            </label>
                            <div className="input-right-icon">
                                <select id="idRegistroIva" className="form-control"
                                    value={filtro.idRegistroIva}
                                    onChange={(e) =>
                                        handleFieldChange('idRegistroIva', parseInt(e.target.value))
                                    }
                                    name="idRegistroIva">
                                    <option key={0} value={0}>Seleziona...</option>
                                    {elenchi.elencoRegistriIva && elenchi.elencoRegistriIva.map((item, index) => (
                                        <option key={index} value={item.idregistro}>
                                            {item.descrizione}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="codiceAccettazione" className="ul-form__label">
                                Codice Accettazione:
                            </label>
                            <div className="input-right-icon">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Codice Accettazione"
                                    id="codiceAccettazione"
                                    value={filtro.codiceAccettazione}
                                    name="codiceAccettazione"
                                    onChange={(e) => handleFieldChange('codiceAccettazione', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="idGrandeCliente" className="ul-form__label">
                                Grande Cliente:
                            </label>
                            <div className="input-right-icon">
                                <select id="idGrandeCliente" className="form-control"
                                    value={filtro.idGrandeCliente}
                                    onChange={(e) =>
                                        handleFieldChange('idGrandeCliente', parseInt(e.target.value))
                                    }
                                    name="idGrandeCliente">
                                    <option key={0} value={0}>Seleziona...</option>
                                    {elenchi.elencoGrandiClienti && elenchi.elencoGrandiClienti.map((item, index) => (
                                        <option key={index} value={item.idgrandeCliente}>
                                            {item.ragioneSociale}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 form-group">
                            <label htmlFor="Paziente" className="ul-form__label">
                                Paziente:
                            </label>
                            <div className="input-right-icon">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Paziente"
                                    id="codiceAccettazione"
                                    value={filtro.paziente}
                                    name="codiceAccettazione"
                                    onChange={(e) => handleFieldChange('paziente', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="idTipoPagamento" className="ul-form__label">
                                Tipologia Pagamento:
                            </label>
                            <div className="input-right-icon">
                                <select id="idTipoPagamento" className="form-control"
                                    value={filtro.idTipoPagamento}
                                    onChange={(e) =>
                                        handleFieldChange('idTipoPagamento', parseInt(e.target.value))
                                    }
                                    name="idTipoPagamento">
                                    <option key={0} value={0}>Tutti</option>
                                    {elenchi.elencoTipoPagamenti && elenchi.elencoTipoPagamenti.map((item, index) => (
                                        <option key={index} value={item.idpagamento}>
                                            {item.descrizione.toUpperCase()}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="numeroFattura" className="ul-form__label">
                                Saldo
                            </label>
                            <div className="input-right-icon">
                                <select id="tipoSaldo"
                                    className="form-control"
                                    value={filtro.tipoSaldo}
                                    name="tipoSaldo"
                                    onChange={(e) => {
                                        handleFieldChange("tipoSaldo", e.target.value);
                                    }}
                                >
                                    <option key="0" value={0}>Tutti</option>
                                    <option key="1" value={1}>Saldo aperto</option>
                                    <option key="1" value={2}>Saldo chiuso</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="chiuso" className="ul-form__label">
                                Tipo stampa:
                            </label>
                            <select id="tipoStampa"
                                className="form-control"
                                value={filtro.tipoStampa}
                                name="chiuso"
                                onChange={(e) => {
                                    handleFieldChange("tipoStampa", e.target.value);
                                }}
                            >
                                <option key="0" value={0}>Elenco</option>
                                <option key="1" value={1}>Dettaglio</option>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="chiuso" className="ul-form__label">
                                Tipo referto:
                            </label>
                            <select id="tipoReferto"
                                className="form-control"
                                value={filtro.tipoReferto}
                                name="chiuso"
                                onChange={(e) => {
                                    handleFieldChange("tipoReferto", e.target.value);
                                }}
                            >
                                <option key="0" value={0}>Tutti</option>
                                <option key="1" value={1}>Da refertare</option>
                                <option key="1" value={2}>Refertati</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label htmlFor="periodo" className="ul-form__label">
                                Periodo:
                            </label>
                            <select id="periodo"
                                className="form-control"
                                value={filtro.periodo}
                                name="periodo"
                                onChange={(e) => {
                                    const valorePeriodo = parseInt(e.target.value, 10);
                                    setCustomDateRange(valorePeriodo === 4);
                                    setDateRangeValue(valorePeriodo);
                                    calcolaDatePeriodo(parseInt(e.target.value));
                                    handleFieldChange("periodo", e.target.value);
                                }}
                            >
                                <option key="-1" value={-1}>-</option>
                                <option key="0" value={0}>Oggi</option>
                                <option key="1" value={1}>Ultimi 7 giorni</option>
                                <option key="2" value={2}>Ultimi 15 giorni</option>
                                <option key="3" value={3}>Ultimo mese</option>
                                <option key="4" value={4}>Personalizza</option>
                            </select>
                        </div>
                        <div className={`form-group col-6 ${customDateRange ? "d-block" : "d-none"}`}>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label htmlFor="dataInizio" className="ul-form__label">
                                        Data inizio:
                                    </label>
                                    <input type="date"
                                        id="dataInizio"
                                        className="form-control"
                                        value={filtro.dataInizio}
                                        onChange={(e) => {
                                            handleFieldChange('dataInizio', e.target.value);
                                        }}
                                        name="dataInizio">
                                    </input>
                                </div>
                                <div className="form-group col-4">
                                    <label htmlFor="dataFine" className="ul-form__label">
                                        Data fine:
                                    </label>
                                    <input type="date"
                                        id="dataFine"
                                        className="form-control"
                                        value={filtro.dataFine}
                                        onChange={(e) => handleFieldChange('dataFine', e.target.value)}
                                        name="dataFine">
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className="ms-auto form-group col-3">
                            <label htmlFor="btnCerca" className="ul-form__label">
                                Azione
                            </label>
                            <div className="input-right-icon">
                                <button
                                    onClick={() => handleCerca(null)}
                                    id="btnCerca"
                                    name="btnCerca"
                                    type="button"
                                    className="btn btn-success text-white">
                                    Cerca</button>

                                <button
                                    onClick={handleAzzeraFiltri}
                                    id="btnAzzera"
                                    name="btnAzzera"
                                    type="button"
                                    className="btn btn-danger text-white">
                                    Azzera Filtri</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1>Lista delle Accettazioni</h1>
                            {filtro.tipoStampa == 0 ? (
                                <ListaAccettazioni lista={elenco} />
                            ) : (
                                <ListaAccettazioniDettaglio lista={elenco} />
                            )}
                        </div>
                    </div>

                </>
            )}
        </div>
    );
};

export default AccettazioneSaldi;
