import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { caricaRiepilogo } from "./service";
import { SimpleCard } from "@gull";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { NotificationManager, NotificationContainer } from "react-notifications";
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { useNavigate } from 'react-router-dom';
import { queryServerPost } from "../ServiceController";
import swal from "sweetalert2";
import { Breadcrumb } from "@gull";

const RiepilogoMediciCompensi = () => {
    const [riepilogo, setRiepilogo] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const COLONNE = [
        {
            dataField: "idMedicoRefertante",
            text: "idMedicoRefertante",
            sort: false,
            hidden: true
        },
        {
            dataField: "abilitaNuovo",
            text: "abilitaNuovo",
            sort: false,
            hidden: true
        },
        {
            dataField: "cognome",
            text: "Cognome",
            sort: true,
            hidden: false,
        },
        {
            dataField: "nome",
            text: "Nome",
            sort: true,
            hidden: false,
        },
        {
            dataField: "esamiAssegnati",
            text: "Esami Assegnati",
            sort: true,
            hidden: false,
        },
        {
            dataField: "esamiPrezzati",
            text: "Esami Prezzati",
            sort: true,
            hidden: false,
        },
        {
            dataField: "dataInizio",
            text: "Data Inizio",
            sort: true,
            hidden: false,
            formatter: (cell) => {
                if (cell) {
                    const date = new Date(cell);
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    return date.toLocaleDateString('it-IT', options);
                }
                return "";
            }
        },
        {
            dataField: "dataFine",
            text: "Data Fine",
            sort: true,
            hidden: false,
            formatter: (cell) => {
                if (cell) {
                    const date = new Date(cell);
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    return date.toLocaleDateString('it-IT', options);
                }
                return "";
            }
        },
        {
            dataField: '',
            text: '',
            formatter: (cell, row) => (
                <div>
                    {row.esamiAssegnati > 0 &&
                        <Fragment>
                            <button title="Nuovo"
                                disabled={!row.abilitaNuovo}
                                className={`btn btn-rounded mx-2 ${row.abilitaNuovo ? 'btn-success' : 'btn-light'}`}
                                onClick={() => handleModifica(row, false)}>
                                <i className="i-Add"></i>
                            </button>
                            <button title="Modifica"
                                className="btn btn-primary text-white btn-rounded mx-2"
                                onClick={() => handleModifica(row, true)}>
                                <i className="i-Edit"></i>
                            </button>
                            <button title="Elimina"
                                className="btn btn-danger text-white btn-rounded mx-2"
                                onClick={() => handleElimina(row)}>
                                <i className="i-Remove"></i>
                            </button>
                        </Fragment>
                    }
                </div>
            ),
        },
    ];

    const handleModifica = (row, isModifica) => {
        if (row.esamiAssegnati === 0) {
            NotificationManager.warning("Bisogna assegnare gli esami al medico prima di poter abbinare i compensi.", "Attenzione");
            return;
        }
        const destination = `/tabelle/medici-compensi/form/${row.idMedicoRefertante}`;
        navigate(destination, { state: { ...row, isModifica } });
    }

    const handleElimina = (row) => {
        swal
            .fire({
                title: "Sei sicuro?",
                text: "Questa azione non sarà reversibile",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, cancella",
                cancelButtonText: "Annulla",
            })
            .then((result) => {
                if (result.value) {
                    elimina(row);
                }
            });
    }

    const elimina = (row) => {
        const payload = {
            idMedicoRefertante: row.idMedicoRefertante,
            idListino: 0,
            dataInizio: row.dataInizio,
            dataFine: row.dataFine,
        };
        dispatch(queryServerPost(`MediciCompensi/EliminaEsamiCompensi`, payload))
            .then(async (data) => {
                if (data.isSuccess) {
                    NotificationManager.success(data.message, "Eliminato");
                    caricaDati();
                } else {
                    NotificationManager.error(data.message, "Errore");
                }
            })
            .catch((error) => {
                NotificationManager.error("Error fetching data", "Errore");
                console.error('Error fetching data:', error);
            });
    }

    const caricaDati = () => {
        dispatch(caricaRiepilogo())
            .then((data) => {
                const dataWithIds = data.map((item, index) => ({
                    ...item,
                    id: index
                }));
                setRiepilogo(dataWithIds);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        caricaDati();
    }, []);

    const paginationOptions = {
        paginationSize: 10,
        pageStartIndex: 1,
        sizePerPage: 20,
        firstPageText: "Inizio",
        prePageText: "Indietro",
        nextPageText: "Avanti",
        lastPageText: "Fine",
        nextPageTitle: "Prima",
        prePageTitle: "Indietro",
        firstPageTitle: "Avanti",
        lastPageTitle: "Fine",
        showTotal: true,
        totalSize: riepilogo.length,
    };

    return (
        <div>
            <Breadcrumb
                routeSegments={[
                    { name: "Tabelle Pocr", path: "/tabelle" },
                    { name: "Medici Compensi" },
                ]}
            />
            <div>
                <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={riepilogo}
                    columns={COLONNE}
                    pagination={paginationFactory(paginationOptions)}
                    noDataIndication={"Tabella vuota"}
                    filter={filterFactory()}
                    bordered={false}
                    classes="table table-striped"
                />
            </div>
            <NotificationContainer />
        </div>
    );
}

export default RiepilogoMediciCompensi;