import { useSelector } from "react-redux";
import GullLayout from "app/GullLayout/GullLayout";
import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLoggedIn, userLoggedOut } from "app/redux/auth/authSlice";
import sessionStorageService from "app/services/sessionStorageService";
import Forbidden from "app/views/sessions/Forbidden";

const AuthGuard = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Controlla lo stato di autenticazione quando cambia il pathname
    const data = sessionStorageService.getItem("auth_user");
    if (data) {
      if (!data.accessToken) {
        dispatch(userLoggedOut());
        return;
      }
      const tokenParts = data.accessToken.split('.');
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1]));
        const tokenExpirationDate = new Date(payload.exp * 1000);
        const currentDate = new Date();
        if (currentDate > tokenExpirationDate) {
          // Il token è scaduto
          dispatch(userLoggedOut());
        }
        else {
          dispatch(
            userLoggedIn({
              accessToken: data.accessToken,
              user: data,
            })
          );
        }
      } else {
        dispatch(userLoggedOut());
      }
    }
    setLoading(false);
  }, [dispatch, pathname]);

  // Mostra uno schermo di caricamento durante il recupero dei dati utente
  if (loading) {
    return <div>Loading...</div>;
  }
  // Elenca i ruoli consentiti per le route
  const allowedRolesForRoutes = {
    "accettazione": ["SUPERADMIN", "ADMIN", "ACCETTAZIONE"],
    "movimenti-cassa": ["SUPERADMIN", "ADMIN", "ACCETTAZIONE"],
    "contabilita": ["SUPERADMIN", "ADMIN"],
    "refertazione": ["SUPERADMIN", "ADMIN", "REFERTAZIONE"],
    "tabelle": ["SUPERADMIN", "ADMIN"],
    "utenti": ["SUPERADMIN", "ADMIN"],
    "opzioni": ["SUPERADMIN", "ADMIN"],
    "main": ["SUPERADMIN", "ADMIN", "REFERTAZIONE", "ACCETTAZIONE", "STATISTICHE"],
    "user-profile": ["SUPERADMIN", "ADMIN", "REFERTAZIONE", "ACCETTAZIONE", "STATISTICHE"],
    "tab-cup": ["CUP ADMIN","SUPERADMIN", "ADMIN", "REFERTAZIONE", "ACCETTAZIONE", "STATISTICHE"],

    // route da cancellare 
    "icons": ["SUPERADMIN", "ADMIN", "REFERTAZIONE", "ACCETTAZIONE", "STATISTICHE"]
    // route da cancellare 
  };

  const isAuthorized = () => {
    const rootPath = pathname.split("/")[1]; // Estrapola il primo segmento dalla route
    const allowedRoles = allowedRolesForRoutes[rootPath];
    if (allowedRoles) {
      let verificaRuolo = user.user.ruoli.some((userRole) => allowedRoles.includes(userRole));
      return verificaRuolo;
    }
    return false;
  };

  if (isAuthenticated) {
    if (isAuthorized()) {
      return (
        <GullLayout>
          <Outlet />
        </GullLayout>
      );
    } else {
      // Utente autenticato ma senza autorizzazione per questa route
      return (
        <GullLayout>
          <Forbidden></Forbidden>
        </GullLayout>
      );
    }
  } else {
    return <Navigate to="/login" replace={true} />;
  }
};

export default AuthGuard;