import { userLoggedOut } from "../auth/authSlice";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import sessionStorageService from "app/services/sessionStorageService";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL_API_CONFIG,
  prepareHeaders: async (headers, { getState }) => {
    const token = getState()?.auth?.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
      api.dispatch(userLoggedOut());
      sessionStorageService.clear();
    }
    return result;
  },
  tagTypes: [],
  endpoints: (builder) => ({
    fetchData: builder.query({
      query: (url) => "/api/" + url,
    }),
    postData: builder.mutation({
      query: (url, data) => ({
        url: "/api/" + url,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
// Definisci la configurazione dell'API
