import { Breadcrumb } from "@gull";
import React from "react";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { useEffect, useState, useCallback } from "react"
import { useDispatch } from "react-redux";
import { getQuery, getQueryPostDto } from "../ServiceController";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const ElencoEmail = () => {
    const initialFiltro = {
        idSede: 0,
        idTipo: 0,
        periodo: 0,
        destinatario: "",
        dataInizio: "",
        dataFine: "",
        elencoTipo: [],
        elencoSedi: [],
    }

    const [filtro, setFiltro] = useState(initialFiltro);
    const [isLoading, setLoading] = useState(false);
    const [elenco, setElenco] = useState([]);
    const [customDateRange, setCustomDateRange] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState(0);

    const dispatch = useDispatch();

    function formattaData(data, mostra, tipo) {
        if (mostra) {
            const newData = new Date(data);
            return format(newData, 'dd/MM/yyyy HH:mm:ss');
        }
        if (tipo != 1) {
            return "n/a";
        }
        return "-";
    }

    const inizializzaFiltro = async () => {
        const data = await dispatch(getQuery("PipelineMail/InitFilter"));
        setFiltro(data);
    }

    const handleFieldChange = useCallback((fieldName, value) => {
        setFiltro(prevFiltro => ({
            ...prevFiltro,
            [fieldName]: value,
        }));
    }, [setFiltro]);

    const calcolaDatePeriodo = (periodo) => {
        const oggi = new Date();
        let nuovaDataInizio = oggi;
        let nuovaDataFine = oggi.toISOString().split("T")[0];

        switch (periodo) {
            case 0: // Giornata odierna
                nuovaDataInizio.setDate(oggi.getDate());
                nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                break;
            case 1: // Ultimi 7 giorni
                nuovaDataInizio.setDate(oggi.getDate() - 7);
                nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                break;
            case 2: // Ultimi 15 giorni
                nuovaDataInizio.setDate(oggi.getDate() - 15);
                nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                break;
            case 3: // Ultimo mese
                nuovaDataInizio.setDate(oggi.getDate() - 30);
                nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                break;
            default:
                if (filtro.dataInizio) {
                    nuovaDataInizio = filtro.dataInizio;
                } else {
                    nuovaDataInizio = nuovaDataInizio.toISOString().split("T")[0];
                }
                if (filtro.dataFine) {
                    nuovaDataFine = filtro.dataFine;
                }
                break;
        }
        handleFieldChange("dataInizio", nuovaDataInizio);
        handleFieldChange("dataFine", nuovaDataFine);
    };

    const handleSubmit = () => {
        setLoading(true);
        dispatch(getQueryPostDto('PipelineMail/ElencoEmail', filtro))
            .then(async (result) => {
                setElenco(result);
                setLoading(false);
                console.log(result);
            })
            .catch((error) => {
                setLoading(false);
                NotificationManager.error("Error fetching data", "Errore");
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        setLoading(true);
        inizializzaFiltro()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Errore nel recupero dei dati:", error);
            });
    }, []);

    const handlePrint = () => {
        // const doc = new jsPDF({
        //     orientation: 'landscape'
        // });
        // const columns = ['Registro', 'Paziente',
        //     'Cod.Acc.', 'Esame', 'Data e Ora',
        //     'Tipo Acc.', 'Compenso', 'Tipo Compenso'];
        // const rows = elenco.map(item => {
        //     return [
        //         item.registro,
        //         item.paziente,
        //         item.codAccettazione,
        //         item.esame,
        //         formattaData(item.dataRefertazione),
        //         item.tipoAccettazione,
        //         { content: `${"€" + parseFloat(item.compenso).toFixed(2)}`, styles: { align: 'right' } },
        //         item.tipoCompenso
        //     ];
        // });

        // const totalRow = ['Totale Compensi', '', '', '', '', '',
        //     { content: `€${calcolaTotaleCompensi()}`, styles: { align: 'right' } },
        //     ''
        // ];
        // let startY = 20;

        // const addHeader = () => {
        //     doc.setFontSize(16);
        //     doc.text(`${creaTitoloStampa()}`, 14, startY - 10);
        // };

        // const addTable = () => {
        //     addHeader();

        //     doc.autoTable({
        //         head: [columns],
        //         body: [...rows, totalRow],
        //         startY: startY,
        //         margin: { top: 20 },
        //         didDrawPage: function (data) {
        //             addHeader();
        //         },
        //         didDrawCell: function (data) {
        //             if (data.row.index === rows.length || // indice per totalRow
        //                 data.row.index === rows.length + 1) {// indice di differenzaRow
        //                 const { doc, cell } = data;
        //                 doc.setDrawColor(0, 0, 0); // colore della linea
        //                 doc.setLineWidth(0.1); // spessore della linea
        //                 doc.line(cell.x, cell.y, cell.x + cell.width, cell.y); // Disegna la linea
        //             }
        //         }
        //     });
        //     startY = doc.autoTable.previous.finalY + 10;
        // };
        // addTable();
        // window.open(doc.output('bloburl'), '_blank');
    };

    // const creaTitoloStampa = () => {
    //     let date = ` dal ${formattaData(filtro.dataInizio, true)} al ${formattaData(filtro.dataFine, true)}`;
    //     let medico = filtro.elencoMedici.find(x => x.id == filtro.idMedico);
    //     let nomeMedico = "";
    //     if (medico) {
    //         nomeMedico = `${medico.nome} ${medico.cognome} [${medico.codiceFiscale}]`
    //     }
    //     return nomeMedico + date;
    // }

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Breadcrumb
                        routeSegments={[
                            { name: "Contabilità", path: "/contabilita" },
                            { name: "Email" },
                        ]}
                    />
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="idSede" className="ul-form__label">
                                Sede:
                            </label>
                            <select id="idSede" className="form-control"
                                defaultValue={filtro.idSede}
                                onChange={(e) =>
                                    handleFieldChange('idSede', parseInt(e.target.value))
                                }
                                name="idSede">
                                <option key={0} value={0}>TUTTE</option>
                                {filtro.elencoSedi.map((item, index) => (
                                    <option key={index} value={item.idsede}>
                                        {item.descrizione.toUpperCase()}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="idTipo" className="ul-form__label">
                                Tipo:
                            </label>
                            <select id="idTipo" className="form-control"
                                defaultValue={filtro.idTipo}
                                onChange={(e) =>
                                    handleFieldChange('idTipo', parseInt(e.target.value))
                                }
                                name="idTipo">
                                <option key={0} value={0}>Tutti</option>
                                {filtro.elencoTipo.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.descrizione}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="destinatario" className="ul-form__label">
                                Destinatario:
                            </label>
                            <div className="input-right-icon">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Destinatario"
                                    id="destinatario"
                                    value={filtro.destinatario}
                                    name="destinatario"
                                    onChange={(e) => handleFieldChange('destinatario', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label htmlFor="periodo" className="ul-form__label">
                                Periodo:
                            </label>
                            <select id="periodo"
                                className="form-control"
                                defaultValue={filtro.periodo}
                                name="periodo"
                                onChange={(e) => {
                                    const valorePeriodo = parseInt(e.target.value, 10);
                                    setCustomDateRange(valorePeriodo === 4);
                                    setDateRangeValue(valorePeriodo);
                                    calcolaDatePeriodo(parseInt(e.target.value));
                                    handleFieldChange("periodo", e.target.value);
                                }}
                            >
                                <option key="0" value={0}>Oggi</option>
                                <option key="1" value={1}>Ultimi 7 giorni</option>
                                <option key="2" value={2}>Ultimi 15 giorni</option>
                                <option key="3" value={3}>Ultimo mese</option>
                                <option key="4" value={4}>Personalizza</option>
                            </select>
                        </div>
                        <div className={`form-group col-4 ${customDateRange ? "d-block" : "d-none"}`}>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="dataInizio" className="ul-form__label">
                                        Data inizio:
                                    </label>
                                    <input type="date"
                                        id="dataInizio"
                                        className="form-control"
                                        value={filtro.dataInizio}
                                        onChange={(e) => {
                                            handleFieldChange('dataInizio', e.target.value)
                                        }}
                                        name="dataInizio">
                                    </input>
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="dataFine" className="ul-form__label">
                                        Data fine:
                                    </label>
                                    <input type="date"
                                        id="dataFine"
                                        className="form-control"
                                        value={filtro.dataFine}
                                        onChange={(e) => handleFieldChange('dataFine', e.target.value)}
                                        name="dataFine">
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="btnCerca" className="ul-form__label">
                                Azione
                            </label>
                            <div className="input-right-icon">
                                <button
                                    onClick={handleSubmit}
                                    id="btnCerca"
                                    name="btnCerca"
                                    className="btn btn-success text-white">
                                    Cerca
                                </button>
                                {/* {elenco.length > 0 &&
                                    <button onClick={handlePrint} className=" mx-1 btn btn-info">
                                        Stampa
                                    </button>
                                } */}
                            </div>
                        </div>
                    </div>
                </>
            )}
            <NotificationContainer />
            <div className="row my-2">
                {elenco &&
                    <div className="table-responsive">
                        <Table
                            hover
                            id="riepilogoCompensi_table"
                            className="table dataTable-collapse text-center"
                        >
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Sede</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Riferimento</th>
                                    <th scope="col">Destinatario</th>
                                    <th scope="col">Data Invio</th>
                                    <th scope="col">Data Invio Reminder</th>
                                    <th scope="col">Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {elenco.map((comp, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{comp.sede.toUpperCase()}</td>
                                        <td>{comp.tipo}</td>
                                        <td>{comp.riferimento}</td>
                                        <td>{comp.destinatario.toLowerCase()}</td>
                                        <td>{formattaData(comp.dataInvio, true, comp.idTipo)}</td>
                                        <td>{formattaData(comp.dataInvioReminder, comp.reminderInviato, comp.idTipo)}</td>
                                        <td></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </div>
    )
}
export default ElencoEmail;