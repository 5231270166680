import React from "react";
import { Breadcrumb } from "@gull";
import { Tabs, Tab } from "react-bootstrap";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { useEffect, useState, useCallback } from "react"
import { useDispatch } from "react-redux";
import { getQuery, getQueryPostDto } from "../../tabellaPocr/ServiceController";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import CupDisponibilitaBars from "./CupDisponibilitaBars";
import swal from "sweetalert2";

const CupDisponibilitaTable = () => {
    const initialFiltro = {
        idRisorsa: 0,
        periodo: 0,
        dataInizio: "",
        dataFine: "",
        elencoRisorse: [],
    }

    const [filtro, setFiltro] = useState(initialFiltro);
    const [isLoading, setLoading] = useState(false);
    const [elenco, setElenco] = useState([]);
    const [customDateRange, setCustomDateRange] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState(0);
    const dispatch = useDispatch();

    const inizializzaFiltro = async () => {
        let data = await dispatch(getQuery("CupDisponibilitaFull/InitFilter"));
        data.dataInizio = data.dataInizio.split("T")[0];
        data.dataFine = data.dataFine.split("T")[0];
        setFiltro(data);
    }

    const getElenco = async () => {
        if (filtro.idRisorsa == 0) return;
        const data = await dispatch(getQueryPostDto('CupDisponibilitaFull/Elenco', filtro))
        setElenco(data);
    }

    const handleFieldChange = useCallback((fieldName, value) => {
        setFiltro(prevFiltro => ({
            ...prevFiltro,
            [fieldName]: value,
        }));
    }, [setFiltro]);

    function formattaData(data) {
        const newData = new Date(data);
        return format(newData, 'dd/MM/yyyy HH:mm')
    }

    const calcolaDatePeriodo = (periodo) => {
        const oggi = new Date();
        let nuovaDataInizio = oggi.toISOString().split("T")[0];
        let nuovaDataFine = oggi;

        switch (periodo) {
            case 0: // Giornata odierna
                nuovaDataFine.setDate(oggi.getDate());
                nuovaDataFine = nuovaDataFine.toISOString().split("T")[0];
                break;
            case 1: // Questa settimana
                nuovaDataFine.setDate(oggi.getDate() + 7);
                nuovaDataFine = nuovaDataFine.toISOString().split("T")[0];
                break;
            case 2: // Prossimi 15 giorni
                nuovaDataFine.setDate(oggi.getDate() + 15);
                nuovaDataFine = nuovaDataFine.toISOString().split("T")[0];
                break;
            case 3: // Questo Mese
                nuovaDataFine.setDate(oggi.getDate() + 30);
                nuovaDataFine = nuovaDataFine.toISOString().split("T")[0];
                break;
            default:
                if (filtro.dataInizio) {
                    nuovaDataInizio = filtro.dataInizio;
                } else {
                    nuovaDataInizio = nuovaDataInizio;
                }
                if (filtro.dataFine) {
                    nuovaDataFine = filtro.dataFine;
                }
                break;
        }
        handleFieldChange("dataInizio", nuovaDataInizio);
        handleFieldChange("dataFine", nuovaDataFine);
    };

    useEffect(() => {
        setLoading(true);
        inizializzaFiltro()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Errore nel recupero dei dati:", error);
            });
    }, []);

    useEffect(() => {
        handleCerca();
    }, [filtro.idRisorsa, filtro.periodo]);


    const handleCerca = () => {
        setLoading(true);
        getElenco()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Errore nel recupero dei dati:", error);
            });
    }

    const handleDelete = (id) => {
        swal.fire({
            title: "Attenzione",
            text: "Questa azione non sarà reversibile",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            cancelButtonText: "Annulla",
            confirmButtonText: "Okay, cancella!"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch(getQuery(`CupDisponibilitaFull/Elimina/${id}`))
                    .then((data) => {
                        if (data.isSuccess) {
                            NotificationManager.success("Operazione effettuata con successo", "Elimina");
                            getElenco();
                        } else {
                            NotificationManager.error(data.message, "Errore");
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        console.error("Errore nel recupero dei dati:", error);
                    });
            }
        });
    }

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Breadcrumb
                        routeSegments={[
                            { name: "Tabelle CUP", path: "/tab-cup" },
                            { name: "Disponibilità" },
                        ]}
                    />
                    <div className="mb-2">
                        <Link to="edit">
                            <button type="button" className="btn btn-primary text-white btn-rounded">Nuovo</button>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor="idRisorsa" className="ul-form__label">
                                Risorsa
                            </label>
                            <select id="idRisorsa" className="form-control"
                                defaultValue={filtro.idRisorsa}
                                onChange={(e) =>
                                    handleFieldChange('idRisorsa', parseInt(e.target.value))
                                }
                                name="idRisorsa">
                                <option key={0} value={0}>Seleziona...</option>
                                {filtro.elencoRisorse.map((item, index) => (
                                    <option key={index} value={item.idrisorsa}>
                                        {item.descrizione}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="periodo" className="ul-form__label">
                                Periodo
                            </label>
                            <select id="periodo"
                                className="form-control"
                                defaultValue={filtro.periodo}
                                name="periodo"
                                onChange={(e) => {
                                    const valorePeriodo = parseInt(e.target.value, 10);
                                    setCustomDateRange(valorePeriodo === 4);
                                    setDateRangeValue(valorePeriodo);
                                    calcolaDatePeriodo(parseInt(e.target.value));
                                    handleFieldChange("periodo", e.target.value);
                                }}
                            >
                                <option key="0" value={0}>Oggi</option>
                                <option key="1" value={1}>Questa settimana</option>
                                <option key="2" value={2}>Prossimi 15 giorni</option>
                                <option key="3" value={3}>Prossimo mese</option>
                                <option key="4" value={4}>Personalizza</option>
                            </select>
                        </div>
                        <div className={`form-group col-4 ${customDateRange ? "d-block" : "d-none"}`}>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label htmlFor="dataInizio" className="ul-form__label">
                                        Data inizio:
                                    </label>
                                    <input type="date"
                                        id="dataInizio"
                                        className="form-control"
                                        value={filtro.dataInizio}
                                        onChange={(e) => {
                                            handleFieldChange('dataInizio', e.target.value)
                                        }}
                                        onBlur={handleCerca}
                                        name="dataInizio">
                                    </input>
                                </div>
                                <div className="form-group col-4">
                                    <label htmlFor="dataFine" className="ul-form__label">
                                        Data fine:
                                    </label>
                                    <input type="date"
                                        id="dataFine"
                                        className="form-control"
                                        value={filtro.dataFine}
                                        onChange={(e) => handleFieldChange('dataFine', e.target.value)}
                                        onBlur={handleCerca}
                                        name="dataFine">
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className="ms-auto form-group col-2">
                            <label htmlFor="btnCerca" className="ul-form__label">
                                Azione
                            </label>
                            <div className="input-right-icon">
                                <button
                                    onClick={handleCerca}
                                    disabled={filtro.idRisorsa == 0}
                                    id="btnCerca"
                                    name="btnCerca"
                                    className="btn btn-success text-white">
                                    Aggiorna
                                </button>
                            </div>
                        </div>
                    </div>
                    <Tabs defaultActiveKey="Tabella" className="mb-4">
                        <Tab eventKey="Tabella" title="Tabella">
                            <div className="row my-2">
                                {elenco &&
                                    <div className="table-responsive">
                                        <Table
                                            hover
                                            id="cupDisponbilita_table"
                                            className="table dataTable-collapse text-center"
                                        >
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Risorsa</th>
                                                    <th scope="col">Esame</th>
                                                    <th scope="col">Data inizio</th>
                                                    <th scope="col">Data fine</th>
                                                    <th scope="col">Azioni</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {elenco.map((disp, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{disp.risorsa}</td>
                                                        <td>{disp.esame}</td>
                                                        <td>{formattaData(disp.dataInizio)}</td>
                                                        <td>{formattaData(disp.dataFine)}</td>
                                                        <td>
                                                            <button className="btn btn-danger text-white" title="Elimina" onClick={() =>
                                                                handleDelete(disp.id)}>Elimina</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </div>
                        </Tab>
                        <Tab eventKey="Grafico" title="Grafico">
                            <div className="row">
                                {elenco && elenco.length > 0 &&
                                    <div>
                                        <CupDisponibilitaBars data={elenco} />
                                    </div>
                                }
                            </div>
                        </Tab>
                    </Tabs>

                </>
            )}
            <NotificationContainer />
        </div>
    )
}
export default CupDisponibilitaTable;