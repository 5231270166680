import axios from "axios";
import {
    stopLoading,
    startLoading,
    cleanError,
    catchError,
} from "../redux/app/appSlice"
import sessionStorageService from "./sessionStorageService";

const BASE_URL_API = process.env.REACT_APP_BASE_URL_API_CONFIG;

// import { getTokenFromCookie } from "./jwt";

const trimPayload = (payload) => {

    Object.keys(payload).forEach(key => {
        if (typeof payload[key] === 'string') {
            payload[key] = payload[key].trim();
        }
    });
    return payload;
}

export const fetchData = (path, payload = null, salvaDati = null, method = "GET", state = undefined) => async (dispatch, getState) => {
    dispatch(startLoading());
    dispatch(cleanError());
    const data = sessionStorageService.getItem("auth_user");
    const axiosParams = {
        baseURL: BASE_URL_API,
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + data?.accessToken
        }
    }
    if (method === "GET" || method === "DELETE") {
        if (payload !== null) {
            axiosParams.params = trimPayload(payload);
        }
    }

    const instance = axios.create(axiosParams);
    try {
        let response;
        if (method === "GET") {
            response = await instance.get(path);
        } else if (method === "POST") {
            response = await instance.post(path, payload);
        } else if (method === "PUT") {
            response = await instance.put(path, payload);
        } else if (method === "DELETE") {
            response = await instance.delete(path);
        } else {
            throw new Error("Invalid HTTP method");
        }

        if (salvaDati != null) {
            if (state !== undefined) {
                dispatch(salvaDati({ ...response.data, payload: state }));
            }
            else {
                dispatch(salvaDati(response.data));
            }
        }
        dispatch(stopLoading());
        return Promise.resolve(response.data);
    }
    catch (error) {
        dispatch(catchError(error.errors));
        throw error;
    }
};

export const fetchDataV2 = async (path, payload = undefined, salvaDati = undefined, method = "GET", state = undefined) => {
    //const token = getTokenFromCookie();
    delete axios.defaults.headers.common["Authorization"];
    const axiosParams = {
        baseURL: BASE_URL_API,
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Bearer ' + token
        }
    }
    if ((method === "GET" || method === "DELETE") && payload !== undefined) {
        axiosParams.params = trimPayload(payload);
    }
    const instance = axios.create(axiosParams);
    try {
        let response;
        if (method === "GET") {
            response = await instance.get(path);
        } else if (method === "POST") {
            response = await instance.post(path, payload);
        } else if (method === "PUT") {
            response = await instance.put(path, payload);
        } else if (method === "PATCH") {
            response = await instance.patch(path, payload);
        } else if (method === "DELETE") {
            response = await instance.delete(path);
        } else {
            throw new Error("Invalid HTTP method");
        }
        return Promise.resolve(response.data);
    }
    catch (error) {
        console.log("FetchDataV2", error);
        if (error.response !== undefined && error.response.status === 401) {
            window.location.href = process.env.REACT_APP_NOT_AUTHORIZED_URL;
        }
        throw error;
    }
};

export const getData = async (path) => {
    const data = sessionStorageService.getItem("auth_user");
    const axiosParams = {
        baseURL: BASE_URL_API,
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + data?.accessToken
        }
    }
    const instance = axios.create(axiosParams);
    try {
        let response = await instance.get(path);
        return Promise.resolve(response.data);
    }
    catch (error) {
        console.log("getData", error);
        if (error.response !== undefined && error.response.status === 401) {
            window.location.href = process.env.REACT_APP_NOT_AUTHORIZED_URL;
        }
        throw error;
    }
};