import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getQuery } from "../../tabellaPocr/ServiceController";

const CupEventoModal = (props) => {
  const { open, eventObject, closeDialog } = props;
  const [utente, setUtente] = useState();
  const [confermata, setConfermata] = useState(false);
  const [prenotazione, setPrenotazione] = useState();
  const dispatch = useDispatch();

  const formattaData = (data, soloData) => {
    const newData = new Date(data);
    if (soloData == true)
      return format(newData, 'dd/MM/yyyy');
    else
      return format(newData, 'dd/MM/yyyy HH:mm');
  }

  useEffect(() => {
    if (eventObject) {
      dispatch(getQuery(`CupPrenotazioni/GetUtenteDaPrenotazione/${eventObject.id}`))
        .then((result) => {
          if (result) {
            setUtente(result);
            getPrenotazione(eventObject.id, result.id);
          }
        })
        .catch((error) => {
          console.error(error);
        });

    }
  }, [eventObject]);

  const getPrenotazione = (idDisp, idUtente) => {
    dispatch(getQuery(`CupPrenotazioni/GetPrenotazione/${idDisp}/${idUtente}`))
      .then((result) => {
        if (result) {
          setPrenotazione(result);
          setConfermata(result.utenteConferma !== "");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const confermaPrenotazione = () => {
    const id = prenotazione.id;
    dispatch(getQuery(`CupPrenotazioni/ConfermaPrenotazione/${id}`))
      .then((result) => {
        if (result.isSuccess) {
          setConfermata(true);
          getPrenotazione(prenotazione.iddisponibilita, prenotazione.idutenteCup);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Modal show={open} centered={true} onHide={closeDialog}>
      <>
        <Modal.Header className="bg-primary text-white d-flex flex-wrap justify-content-between align-items-center">
          <h4 className="m-0 text-white">Info Prenotazione</h4>
          <i
            className="i-Close-Window cursor-pointer text-18"
            onClick={closeDialog}
          ></i>
        </Modal.Header>
        {eventObject &&
          <Modal.Body>
            <div className="w-100">
              <div className="ul-product-detail__brand-name mb-4">
                <h5 className="heading">{eventObject.title}</h5>
                <span className="text-mute">{eventObject.extendedProps.risorsa}</span>
              </div>
              <div className="ul-product-detail__price-and-rating text-center">
                <h4 className="font-weight-700 text-primary mb-0 mr-2">
                  {formattaData(eventObject.start)}  -  {formattaData(eventObject.end)}
                </h4>
              </div>
              {utente &&
                <div className="row mt-3 border p-2">
                  <div className="col-md-4 col-6">
                    <div className="mb-4">
                      <p className="text-primary mb-1">
                        <i className="i-Calendar text-16 mr-1"></i> Nominativo
                      </p>
                      <span>{utente.cognome} {utente.nome}</span>
                    </div>
                    <div className="mb-4">
                      <p className="text-primary mb-1">
                        <i className="i-Edit-Map text-16 mr-1"></i> Codice Fiscale
                      </p>
                      <span>{utente.codiceFiscale}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="mb-4">
                      <p className="text-primary mb-1">
                        <i className="i-MaleFemale text-16 mr-1"></i> Data di Nascita
                      </p>
                      <span>{formattaData(utente.dataDiNascita, true)}</span>
                    </div>
                    <div className="mb-4">
                      <p className="text-primary mb-1">
                        <i className="i-Email text-16 mr-1"></i> Email
                      </p>
                      <span>{utente.email}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="mb-4">
                      <p className="text-primary mb-1">
                        <i className="i-Business-Man text-16 mr-1"></i>
                        Telefono
                      </p>
                      <span>{utente.telefono}</span>
                    </div>
                  </div>
                </div>
              }
              <div className="ul-product-detail__features mt-3">
                <h6 className=" font-weight-700">Dettagli:</h6>
                <ul className="m-0 p-0">
                  <li>
                    <i className="i-Right1 text-primary text-15 align-middle font-weight-700"></i>
                    <span className="align-middle">
                      &nbsp;Data Creazione: {formattaData(eventObject.extendedProps.dataOraCreazione)}
                    </span>
                  </li>
                  <li>
                    <i className="i-Right1 text-primary text-15 align-middle font-weight-700"></i>
                    <span className="align-middle">
                      &nbsp;Convenzionato: {eventObject.extendedProps.convenzionato}
                    </span>
                  </li>
                  <li>
                    <i className="i-Right1 text-primary text-15 align-middle font-weight-700"></i>
                    <span className="align-middle">
                      &nbsp;Forzata: {eventObject.extendedProps.forzata ? "SI" : "NO"}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="row mx-1">
                {confermata &&
                  <div className="alert alert-success text-center">
                    Prenotazione confermata il {formattaData(prenotazione.dataConferma)}
                    <p className="text-center"><b>{prenotazione.utente}</b></p>
                  </div>
                }
                {!confermata &&
                  <Button
                    type="button"
                    variant="warning"
                    onClick={confermaPrenotazione}>
                    Conferma Prenotazione
                  </Button>
                }
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                variant="primary"
                onClick={closeDialog}>
                Chiudi
              </Button>
            </div>
          </Modal.Body>
        }
      </>
    </Modal>
  );
};

export default CupEventoModal;
