// anagraficaSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  variabiliStrutture: [],
};

const variabiliStrutturaSlice = createSlice({
  name: "variabiliStruttura",
  initialState,
  reducers: {
    populateVariabiliList: (state, action) => {
      state.variabiliStrutture = action.payload; // Popola la lista di anagrafiche
    },
  },
});

export const { populateVariabiliList} = variabiliStrutturaSlice.actions;
export default variabiliStrutturaSlice.reducer;
