import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from "@gull";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Accordion } from "react-bootstrap";
import { format } from "date-fns";
import { it } from 'date-fns/locale';
import { useDispatch } from "react-redux";
import { getQueryPostDto } from "../../tabellaPocr/ServiceController";

const CupPrenotaForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { exams, dataEsame, data } = location.state;
    const [stessoGiorno, setStessoGiorno] = useState(data.stessoGiorno);
    const [stessoMedico, setStessoMedico] = useState(data.stessoMedico);
    const [dataInizio, setDataInizio] = useState(dataEsame);
    const [isLoading, setLoading] = useState(false);

    const [selectedResources, setSelectedResources] = useState({});
    const [filteredDates, setFilteredDates] = useState({});
    const [selectedDates, setSelectedDates] = useState({});
    const [availability, setAvailability] = useState({});

    useEffect(() => {
        const initialResources = {};
        const initialDates = {};
        const initialTimes = {};
        const initialAvailability = {};

        exams.forEach((exam) => {
            const firstResource = exam.risorse[0].idrisorsa;
            initialResources[exam.idesame] = firstResource;

            const datesWithResource = exam.date.filter(date =>
                date.orari.some(ora => ora.idrisorsa === firstResource)
            );
            initialDates[exam.idesame] = datesWithResource;

            const firstDate = dataInizio || (datesWithResource[0]?.data || '');
            const availableTimes = datesWithResource.length > 0 ? datesWithResource[0].orari.filter(
                ora => ora.idrisorsa === firstResource
            ) : [];
            initialTimes[exam.idesame] = {
                date: firstDate,
                times: availableTimes,
                selectedTime: availableTimes[0]?.ora || ''
            };
            initialAvailability[exam.idesame] = true;
        });

        setSelectedResources(initialResources);
        setFilteredDates(initialDates);
        setSelectedDates(initialTimes);
        setAvailability(initialAvailability);
    }, [exams, dataInizio]);

    const handleResourceChange = (event, examId) => {
        const resourceId = event.target.value;
        const updatedResources = { ...selectedResources };
        const updatedDates = { ...filteredDates };
        const updatedTimes = { ...selectedDates };
        const updatedAvailability = { ...availability };

        if (stessoMedico) {
            exams.forEach((exam) => {
                updatedResources[exam.idesame] = resourceId;
                const datesWithResource = exam.date.filter(date =>
                    date.orari.some(ora => ora.idrisorsa === parseInt(resourceId))
                );

                updatedDates[exam.idesame] = datesWithResource;

                if (datesWithResource.length > 0) {
                    const firstDate = datesWithResource[0].data;
                    const availableTimes = datesWithResource[0].orari.filter(
                        ora => ora.idrisorsa === parseInt(resourceId)
                    );
                    updatedTimes[exam.idesame] = {
                        date: firstDate,
                        times: availableTimes,
                        selectedTime: availableTimes[0]?.ora || ''
                    };
                    updatedAvailability[exam.idesame] = true;
                } else {
                    updatedTimes[exam.idesame] = { date: '', times: [], selectedTime: '' };
                    updatedAvailability[exam.idesame] = false;
                }
            });
        } else {
            updatedResources[examId] = resourceId;
            const datesWithResource = exams.find(exam => exam.idesame === examId).date.filter(date =>
                date.orari.some(ora => ora.idrisorsa === parseInt(resourceId))
            );

            updatedDates[examId] = datesWithResource;

            const firstDate = datesWithResource[0]?.data || '';
            const availableTimes = datesWithResource.length > 0 ? datesWithResource[0].orari.filter(
                ora => ora.idrisorsa === parseInt(resourceId)
            ) : [];

            updatedTimes[examId] = {
                date: firstDate,
                times: availableTimes,
                selectedTime: availableTimes[0]?.ora || ''
            };
            updatedAvailability[examId] = datesWithResource.length > 0;
        }

        setSelectedResources(updatedResources);
        setFilteredDates(updatedDates);
        setSelectedDates(updatedTimes);
        setAvailability(updatedAvailability);
    };

    const handleDateChange = (event, examId) => {
        const date = event.target.value;
        const updatedTimes = { ...selectedDates };
        const updatedAvailability = { ...availability };

        if (stessoGiorno) {
            exams.forEach((exam) => {
                const selectedDateObj = filteredDates[exam.idesame].find(d => d.data === date);
                const availableTimes = selectedDateObj ? selectedDateObj.orari.filter(
                    ora => ora.idrisorsa === parseInt(selectedResources[exam.idesame])
                ) : [];

                updatedTimes[exam.idesame] = {
                    date: selectedDateObj ? date : filteredDates[exam.idesame][0]?.data || '',
                    times: availableTimes,
                    selectedTime: availableTimes[0]?.ora || ''
                };
                updatedAvailability[exam.idesame] = !!selectedDateObj;
            });
        } else {
            const selectedDateObj = filteredDates[examId].find(d => d.data === date);
            const availableTimes = selectedDateObj ? selectedDateObj.orari.filter(
                ora => ora.idrisorsa === parseInt(selectedResources[examId])
            ) : [];

            updatedTimes[examId] = {
                date,
                times: availableTimes,
                selectedTime: availableTimes[0]?.ora || ''
            };
            updatedAvailability[examId] = !!selectedDateObj;
        }

        setSelectedDates(updatedTimes);
        setAvailability(updatedAvailability);
    };

    const handleTimeChange = (event, examId) => {
        const time = event.target.value;
        const updatedTimes = { ...selectedDates };
        updatedTimes[examId] = {
            ...updatedTimes[examId],
            selectedTime: time
        };
        setSelectedDates(updatedTimes);
    };

    function formattaData(data) {
        const newData = new Date(data);
        const formattedDate = format(newData, 'dd/MM/yyyy', { locale: it });
        const formattedDay = format(newData, 'EEEE', { locale: it });
        return `${formattedDate} - ${formattedDay.toUpperCase()}`;
    }

    const handleRefineSearch = async () => {
        setLoading(true);
        let newData = {
            idprenotazione: data.idprenotazione,
            stessoGiorno: stessoGiorno,
            stessoMedico: stessoMedico,
            elencoEsami: data.elencoEsami
        };

        newData.elencoEsami.forEach(esame => {
            esame.dataInizio = dataInizio;
        });

        dispatch(getQueryPostDto('CupDisponibilitaFull/GetDisponibilitaByEsame', newData))
            .then((result) => {
                if (result.isSuccess) {
                    // Aggiorna gli esami con i nuovi dati ricevuti
                    const newExams = result.data;

                    const initialResources = {};
                    const initialDates = {};
                    const initialTimes = {};
                    const initialAvailability = {};

                    newExams.forEach((exam) => {
                        const firstResource = exam.risorse[0].idrisorsa;
                        initialResources[exam.idesame] = firstResource;

                        const datesWithResource = exam.date.filter(date =>
                            date.orari.some(ora => ora.idrisorsa === firstResource)
                        );
                        initialDates[exam.idesame] = datesWithResource;

                        const firstDate = dataInizio || (datesWithResource[0]?.data || '');
                        const availableTimes = datesWithResource.length > 0 ? datesWithResource[0].orari.filter(
                            ora => ora.idrisorsa === firstResource
                        ) : [];
                        initialTimes[exam.idesame] = {
                            date: firstDate,
                            times: availableTimes,
                            selectedTime: availableTimes[0]?.ora || ''
                        };
                        initialAvailability[exam.idesame] = true;
                    });

                    setSelectedResources(initialResources);
                    setFilteredDates(initialDates);
                    setSelectedDates(initialTimes);
                    setAvailability(initialAvailability);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const checkOverlap = () => {
        if (exams.length > 1) {
            const selectedExamTimes = exams.map((exam) => {
                const selectedDate = selectedDates[exam.idesame]?.date;
                const selectedTime = selectedDates[exam.idesame]?.selectedTime;
                const duration = exam.date.find(date => date.data === selectedDate)?.orari.find(ora => ora.ora === selectedTime)?.durata;
                return { idesame: exam.idesame, date: selectedDate, time: selectedTime, duration: duration };
            });

            let isOverlap = false;
            for (let i = 0; i < selectedExamTimes.length; i++) {
                for (let j = i + 1; j < selectedExamTimes.length; j++) {
                    if (selectedExamTimes[i].date === selectedExamTimes[j].date) {
                        const start1 = new Date(`1970-01-01T${selectedExamTimes[i].time}`);
                        const end1 = new Date(start1.getTime() + selectedExamTimes[i].duration * 60000);
                        const start2 = new Date(`1970-01-01T${selectedExamTimes[j].time}`);
                        const end2 = new Date(start2.getTime() + selectedExamTimes[j].duration * 60000);
                        if ((start1 < end2 && start1 >= start2) || (start2 < end1 && start2 >= start1)) {
                            isOverlap = true;
                            break;
                        }
                    }
                }
                if (isOverlap) break;
            }

            if (isOverlap) {
                NotificationManager.warning('Gli orari selezionati si sovrappongono. Per favore, seleziona orari diversi.');
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const handlePrenota = () => {
        if (checkOverlap()) {
            setLoading(true);
            const elenco = exams.map((exam) => {
                const selectedDate = selectedDates[exam.idesame]?.date;
                const selectedTime = selectedDates[exam.idesame]?.selectedTime
                const selectedResource = selectedResources[exam.idesame];
                const duration = exam.date.find(date => date.data === selectedDate)?.orari.find(ora => ora.ora === selectedTime)?.durata;
                const iddisponibilita = exam.date.find(date => date.data === selectedDate)?.orari.find(ora => ora.ora === selectedTime)?.iddisponibilita;
                const risorsa = exam.risorse.find(ris => ris.idrisorsa == selectedResource)?.risorsa;

                return {
                    iddisponibilita: iddisponibilita,
                    idesame: exam.idesame,
                    idrisorsa: selectedResource,
                    dataInizio: selectedDate,
                    oraInizio: selectedTime,
                    durata: duration,
                    esame: exam.esame,
                    risorsa: risorsa
                };
            });
            navigate('prenota', { state: { prenotazioni: elenco, idprenotazione: data.idprenotazione } });
        }
    };

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Breadcrumb
                        routeSegments={[{ name: "Prenotazioni", path: "/tab-cup/prenotazioni" },
                        { name: "Panoramica" }]}
                    />
                    <div className='row'>
                        <div className='col-10'>
                            <div className="ul-card__margin-25">
                                <div className="card-header">
                                    <h2 className="card-title">
                                        Elenco Esami
                                    </h2>
                                </div>
                                <div className="card-body">
                                    {exams.map((exam) => (
                                        <div className="form-group row px-2" key={exam.idesame}>
                                            <label className="ul-form__label ul-form--margin col-lg-3 col-form-label text-start fw-bold">
                                                {exam.esame}
                                            </label>
                                            <label className="ul-form__label ul-form--margin col-lg-1 col-form-label">
                                                Risorsa:
                                            </label>
                                            <div className="col-lg-2">
                                                <select
                                                    onChange={(e) => handleResourceChange(e, exam.idesame)}
                                                    className={`form-control ${!availability[exam.idesame] && stessoMedico ? 'border-danger' : ''}`}
                                                    value={selectedResources[exam.idesame]}
                                                >
                                                    {exam.risorse.map((resource) => (
                                                        <option key={resource.idrisorsa} value={resource.idrisorsa}>
                                                            {resource.risorsa}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {selectedResources[exam.idesame] && (
                                                <>
                                                    <label className="ul-form__label ul-form--margin col-lg-1 col-form-label">
                                                        Data:
                                                    </label>
                                                    <div className="col-2">
                                                        <div className="form-group mb-2">
                                                            <select
                                                                onChange={(e) => handleDateChange(e, exam.idesame)}
                                                                className={`form-control ${!availability[exam.idesame] && stessoGiorno ? 'border-danger' : ''}`}
                                                                value={selectedDates[exam.idesame]?.date}
                                                            >
                                                                {filteredDates[exam.idesame].map((date) => (
                                                                    <option key={date.data} value={date.data}>
                                                                        {formattaData(date.data)}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <label className="ul-form__label ul-form--margin col-lg-1 col-form-label">
                                                        Orario:
                                                    </label>
                                                    <div className="col-2">
                                                        <div className="form-group mb-2">
                                                            {selectedDates[exam.idesame]?.date && (
                                                                <select
                                                                    className='form-control'
                                                                    onChange={(e) => handleTimeChange(e, exam.idesame)}
                                                                    value={selectedDates[exam.idesame]?.selectedTime}
                                                                >
                                                                    {selectedDates[exam.idesame]?.times.map((time) => (
                                                                        <option key={time.ora} value={time.ora}>
                                                                            {time.ora}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="custom-separator"></div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="card-footer">
                                    <div className="mc-footer">
                                        <div className="row text-center">
                                            <div className="col-lg-12 ">
                                                <button type="button" onClick={handlePrenota} className="btn btn-primary m-1">
                                                    Conferma selezione
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => navigate(-1)}
                                                    className="btn btn-outline-secondary m-1">
                                                    Annulla
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-2'>
                            <Accordion className="mb-3">
                                <Accordion.Item >
                                    <Accordion.Header className="w-100">
                                        Impostazioni Ricerca
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div id="custom-toggle3">
                                            <div className="list-group">
                                                <div>
                                                    <label className="form-label" htmlFor="dataInizio">Data disponibilità</label>
                                                    <input className="form-control" type="date" id="dataInizio" value={dataInizio} onChange={(e) => setDataInizio(e.target.value)} />
                                                </div>
                                                <div className="mt-4">
                                                    <input disabled={exams.length < 2} className="form-check-input" type="checkbox" id="medico" defaultChecked={stessoMedico} onChange={(e) => {
                                                        setStessoMedico(e.target.checked);
                                                    }} />
                                                    <label className="form-check-label mx-4" htmlFor="medico">&nbsp;Stesso Medico</label>
                                                </div>
                                                <div className="mt-4">
                                                    <input disabled={exams.length < 2} className="form-check-input" type="checkbox" id="giorno" defaultChecked={stessoGiorno} onChange={(e) => setStessoGiorno(e.target.checked)} />
                                                    <label className="form-check-label mx-4" htmlFor="giorno">&nbsp;Stesso Giorno</label>
                                                </div>

                                                <div className="text-center mt-4">
                                                    <div className="col-12">
                                                        <button className="btn btn-primary w-100" disabled={exams.length == 0} onClick={handleRefineSearch}>
                                                            Ripeti ricerca
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </>
            )}
            <NotificationContainer />
        </div>
    );
};

export default CupPrenotaForm;
