import * as yup from "yup";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import { useEffect, useState } from "react";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Breadcrumb } from "@gull";
import { useDispatch } from "react-redux";
import { salva, getQuery } from "../../tabellaPocr/ServiceController";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link } from "react-router-dom";
import { fetchData } from "app/services/apiService";
import swal from "sweetalert2";

const FormCupConfigurazioniRisorse = () => {
    const [intestazione, setIntestazione] = useState("Nuovo");
    const [isModifica, setIsModifica] = useState(false);
    const [risorse, setRisorse] = useState([]);
    const [agenda, setAgenda] = useState([]);
    const dispatch = useDispatch();
    const { ris, ag } = useParams();
    const navigate = useNavigate();

    const daysOfWeek = [
        { ord: 0, id: 1, nome: "Lunedì" },
        { ord: 1, id: 2, nome: "Martedì" },
        { ord: 2, id: 3, nome: "Mercoledì" },
        { ord: 3, id: 4, nome: "Giovedì" },
        { ord: 4, id: 5, nome: "Venerdì" },
        { ord: 5, id: 6, nome: "Sabato" },
        { ord: 6, id: 0, nome: "Domenica" },
    ];

    const initialState = {
        id: 0,
        idrisorsa: ris ?? "",
        iddescrizioneAgenda: ag ?? "0",
        agenda: "",
        configurazioni: daysOfWeek.map((day) => ({
            id: day.id,
            giorno: day.nome,
            idconfigurazione: 0,
            fasciaOrariaInizio: "",
            fasciaOrariaFine: "",
        })),
    };
    const [configurazioneState, setConfigurazioneState] =
        useState(initialState);

    const fetchResourcesAndAgendas = async () => {
        await Promise.all([
            dispatch(fetchDataFromApi("CupRisorse/ElencoRisorse", setRisorse)),
            dispatch(
                fetchDataFromApi(
                    `CupDescrizioneAgenda/Elenco/${ris ?? 0}`,
                    setAgenda
                )
            ),
        ]);
    };

    useEffect(() => {
        if (ris && ag) {
            caricaElemento(ris, ag);
        }
        fetchResourcesAndAgendas();
    }, [dispatch, ris, ag]);

    const handleRisorseChange = async (e) => {
        const value = e.target.value;
        await dispatch(
            fetchDataFromApi(`CupDescrizioneAgenda/Elenco/${value}`, setAgenda)
        );
    };

    const caricaElemento = (idrisorsa, iddescrizioneAgenda) => {
        dispatch(
            getQuery(
                `CupConfigurazioniRisorse/GetConfigurazioneRisorse/${idrisorsa}/${iddescrizioneAgenda}`
            )
        )
            .then((data) => {
                setConfigurazioneState(data);
                setIntestazione("Modifica");
                setIsModifica(true);
            })
            .catch((error) => {
                NotificationManager.error("Error fetching data", "Errore");
                console.error("Error fetching data:", error);
            });
    };

    const fetchDataFromApi = (apiUrl, setData) => {
        return (dispatch) => {
            return dispatch(fetchData(apiUrl))
                .then((data) => {
                    setData(data);
                })
                .catch((error) => {
                    NotificationManager.error("Error fetching data", "Errore");
                    return Promise.reject(error);
                });
        };
    };

    const verificaConfigurazioneValida = (values) => {
        return values.configurazioni.some(
            (configurazione) =>
                configurazione.fasciaOrariaInizio !== "" &&
                configurazione.fasciaOrariaFine !== ""
        );
    };

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        if (verificaConfigurazioneValida(values)) {
            dispatch(salva("CupConfigurazioniRisorse/Salva", values))
                .then((data) => {
                    NotificationManager.success(data.message, "Salva");
                    console.log(data);
                    setTimeout(() => {
                        handleReset(resetForm);
                        if (data.isSuccess) {
                            navigate(`/tab-cup/modello-agenda`);
                        }
                    }, 500);
                })
                .catch((error) => {
                    NotificationManager.error("Error submitting data", "Errore");
                    console.error("Error submitting data:", error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            swal.fire({
                icon: "warning",
                title: "Attenzione",
                text: "Inserire almeno una configurazione",
            });
            setSubmitting(false);
        }
    };

    const handleReset = (resetForm) => {
        setConfigurazioneState(initialState);
        resetForm();
    };

    // Validate time format
    const validateTime = (value) => {
        const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
        if (!value) {
            return null;
        } else if (!timeRegex.test(value)) {
            return "Formato deve essere HH:MM";
        }
        return null;
    };

    const timeToMinutes = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    };

    // Validate that end time is after start time
    const validateEndTime = (startTime, endTime) => {
        const startTimeError = validateTime(startTime);
        const endTimeError = validateTime(endTime);
        if (startTimeError || endTimeError) {
            return startTimeError || endTimeError;
        }
        const startMinutes = timeToMinutes(startTime);
        const endMinutes = timeToMinutes(endTime);
        if (endMinutes <= startMinutes) {
            return "L'orario di fine deve essere successivo all'orario di inizio";
        }
        return null;
    };

    // Handle changes and validate for end time
    const handleValidateEndTime = (e, values, setFieldError) => {
        const { name } = e.target;
        const index = name.split(".")[1];
        const startTime = values.configurazioni[index].fasciaOrariaInizio;
        const endTime = values.configurazioni[index].fasciaOrariaFine;

        // Validate both times again
        const startTimeError = validateTime(startTime);

        // Set errors appropriately
        if (startTimeError) {
            setFieldError(
                `configurazioni.${index}.fasciaOrariaInizio`,
                startTimeError
            );
        } else {
            setFieldError(`configurazioni.${index}.fasciaOrariaInizio`, null);
        }

        if (endTime !== "") {
            const endTimeError =
                validateEndTime(startTime, endTime) || validateTime(endTime);

            if (endTimeError) {
                setFieldError(name, endTimeError);
            } else {
                setFieldError(name, null);
            }
        }
    };

    // Gestore dell'evento onBlur
    const handleBlur = (e, values, setFieldError, setFieldTouched) => {
        const { name } = e.target;
        setFieldTouched(name, true, false);
        handleValidateEndTime(e, values, setFieldError);
    };

    const handleUpdate = (e) => {
        if (isModifica) {
            const value = e.target.value;
            if (value !== "") {
                caricaElemento(ris, value);
            }
        }
    };

    return (
        <div>
            <Breadcrumb
                routeSegments={[
                    { name: "Modello Agende", path: "/tab-cup/modello-agenda" },
                    { name: intestazione },
                ]}
            />
            <div className="card mb-4">
                <div className="card-body">
                    <div className="card-title mb-3">{intestazione}</div>
                    <Formik
                        initialValues={configurazioneState}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            values,
                            handleSubmit,
                            isSubmitting,
                            handleChange,
                            setFieldError,
                            setFieldTouched,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="idrisorsa">Risorse</label>
                                        <Field
                                            as="select"
                                            name="idrisorsa"
                                            className="form-control"
                                            disabled={isModifica}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleRisorseChange(e);
                                            }}
                                        >
                                            <option value="">Seleziona...</option>
                                            {risorse.map((res) => (
                                                <option key={res.idrisorsa} value={res.idrisorsa}>
                                                    {res.descrizione}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                            name="idrisorsa"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        {isModifica && (
                                            <>
                                                <label htmlFor="iddescrizioneAgenda">
                                                    Descrizione Agenda
                                                </label>
                                                <Field
                                                    as="select"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleUpdate(e);
                                                    }}
                                                    name="iddescrizioneAgenda"
                                                    className="form-control"
                                                    disabled={isModifica}
                                                >
                                                    <option value="">Seleziona...</option>
                                                    {agenda.map((ag) => (
                                                        <option
                                                            key={ag.iddescrizioneAgenda}
                                                            value={ag.iddescrizioneAgenda}
                                                        >
                                                            {ag.descrizione}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage
                                                    name="iddescrizioneAgenda"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </>
                                        )}
                                        {!isModifica && (
                                            <>
                                                <label htmlFor="agenda">Descrizione Agenda</label>
                                                <Field
                                                    type="text"
                                                    name="agenda"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    placeholder="Agenda"
                                                    value={values.agenda}
                                                />
                                                <ErrorMessage
                                                    name="agenda"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <FieldArray name="configurazioni">
                                    {() => (
                                        <div className="mt-2">
                                            {values.configurazioni.length > 0 &&
                                                values.configurazioni.map((conf, ind) => (
                                                    <div className="border-bottom p-2">
                                                        <div className="row" key={ind}>
                                                            <div className="row mt-2">
                                                                <div className="col-2">
                                                                    <h4
                                                                        className="pt-4"
                                                                        htmlFor={`configurazioni.${ind}.giorno`}>
                                                                        {daysOfWeek[ind].nome}
                                                                    </h4>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="inline">Inizio &nbsp;</div>
                                                                    <input
                                                                        type="time"
                                                                        name={`configurazioni.${ind}.fasciaOrariaInizio`}
                                                                        className="form-control"
                                                                        onBlur={(e) =>
                                                                            handleBlur(
                                                                                e,
                                                                                values,
                                                                                setFieldError,
                                                                                setFieldTouched
                                                                            )
                                                                        }
                                                                        onChange={handleChange}
                                                                        value={conf.fasciaOrariaInizio}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`configurazioni.${ind}.fasciaOrariaInizio`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </div>
                                                                <div className="col-3">
                                                                    <span>Fine &nbsp;</span>
                                                                    <input
                                                                        type="time"
                                                                        name={`configurazioni.${ind}.fasciaOrariaFine`}
                                                                        className="form-control"
                                                                        onBlur={(e) =>
                                                                            handleBlur(
                                                                                e,
                                                                                values,
                                                                                setFieldError,
                                                                                setFieldTouched
                                                                            )
                                                                        }
                                                                        onChange={handleChange}
                                                                        value={conf.fasciaOrariaFine}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`configurazioni.${ind}.fasciaOrariaFine`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </FieldArray>
                                <div className="row mt-2">
                                    <div className="col-lg-12 text-end">
                                        <Button type="submit" disabled={isSubmitting}>
                                            Salva
                                        </Button>
                                        <Link to="/tab-cup/modello-agenda">
                                            <button
                                                className="btn btn-outline-secondary m-1"
                                                type="button"
                                            >
                                                Annulla
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

const validationSchema = yup.object().shape({
    idrisorsa: yup
        .string()
        .required("Il campo è obbligatorio")
        .notOneOf([""], "Il campo è obbligatorio"),
    agenda: yup
        .string()
        .required("Il campo Descrizione Agenda è obbligatorio")
        .notOneOf([""], "Il campo Descrizione Agenda è obbligatorio"),
});

export default FormCupConfigurazioniRisorse;