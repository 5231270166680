import * as yup from "yup";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { useEffect } from "react";
import { Formik } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Breadcrumb } from "@gull";
import { useDispatch } from "react-redux";
import { salva, getElement } from "../../tabellaPocr/ServiceController";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link } from "react-router-dom";

const FormCupDescrizioneAgenda = () => {
    const initialState =
    {
        id: 0,
        iddescrizioneAgenda: 0,
        descrizione: "",
        eliminato: false,
    };

    //stato globale
    const [descrizioneState, setDescrizioneState] = useState(initialState);
    const [intestazione, setIntestazione] = useState("Nuovo");
    const dispatch = useDispatch();

    //Se sto in modifica
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            dispatch(getElement('CupDescrizioneAgenda/GetDescrizioneAgenda', id))
                .then(async (data) => {
                    setDescrizioneState(data);
                    setIntestazione("Modifica");
                })
                .catch((error) => {
                    NotificationManager.error("Error fetching data", "Errore");
                    console.error('Error fetching data:', error);
                });
        }
    }, [dispatch, id]);

    const navigate = useNavigate();
    //submit
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setDescrizioneState(values);
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        dispatch(salva('CupDescrizioneAgenda/Salva', values))
            .then((data) => {
                NotificationManager.success(data.message, "Salva");
                console.log(data);
                setTimeout(() => {
                    handleReset(resetForm);
                    if (data.isSuccess) {
                        navigate(`/tab-cup/agenda`);
                    }
                }, 500);
            }).catch((error) => {
                NotificationManager.error("Error fetching data", "Errore");
                console.error('Error submiting data:', error);
            }).finally(() => {
                setSubmitting(false);
            });
    };

    const handleReset = (resetForm) => {
        setDescrizioneState(initialState);
        resetForm();
    };

    return (
        <div>
            <Breadcrumb
                routeSegments={[
                    { name: "Agenda", path: "/tab-cup/agenda" },
                    { name: intestazione },
                ]}
            />
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="card-title mb-3">{intestazione}</div>
                            <Formik
                                initialValues={descrizioneState}
                                validationSchema={basicFormSchema}
                                onSubmit={handleSubmit}
                                enableReinitialize={true}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12 form-group mb-3">
                                                <label htmlFor="descrizione">Descrizione</label>
                                                <input
                                                    id="descrizione"
                                                    name="descrizione"
                                                    className="form-control"
                                                    placeholder="Descrizione"
                                                    autoComplete="off"
                                                    type="text"
                                                    value={values.descrizione}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}

                                                />
                                                {errors.descrizione && touched.descrizione && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.descrizione}
                                                    </div>
                                                )}
                                            </div>
                                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                                            <div className="row">
                                                <div className="col-lg-12 text-end">
                                                    <Button type="submit" disabled={isSubmitting}>Salva</Button>
                                                    <Link to="/tab-cup/agenda">
                                                        <button className="btn btn-outline-secondary m-1" type="button">
                                                            Annulla
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

const basicFormSchema = yup.object().shape({
    descrizione: yup.string().required("obbligatorio"),
});

export default FormCupDescrizioneAgenda;
