import { fetchData } from "app/services/apiService";

export const caricaElenco = (pathElenco) => {
  const apiUrl = pathElenco;
  const payload = {
    Oggetto: {},
    NumeroPagina: "1",
    GrandezzaPagina: "10",
  };
  return (dispatch) => {
    return dispatch(fetchData(apiUrl, payload))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export const queryServer = (pathElenco) => {
  const apiUrl = pathElenco;
  return (dispatch) => {
    return dispatch(fetchData(apiUrl))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export const queryServerPost = (pathElenco, request) => {
  const apiUrl = pathElenco;
  return (dispatch) => {
    return dispatch(fetchData(apiUrl, request, null, "POST"))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}


export const salva = (path, Dto) => {
  const apiUrl = path;
  return (dispatch) => {
    return dispatch(fetchData(apiUrl, Dto, null, "POST"))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export const getQueryPostDto = (path, Dto) => {
  const apiUrl = `${path}`;
  return (dispatch) => {
    return dispatch(fetchData(apiUrl, Dto, null, "POST"))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}
export const getQueryGetDto = (path, Dto) => {
  const apiUrl = `${path}`;
  return (dispatch) => {
    return dispatch(fetchData(apiUrl, Dto, null, "GET"))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export const getQuery = (path) => {
  const apiUrl = `${path}`;
  return (dispatch) => {
    return dispatch(fetchData(apiUrl))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}


export const getElement = (path, id) => {
  const apiUrl = `${path}/${id}`;
  return (dispatch) => {
    return dispatch(fetchData(apiUrl))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export const elimina = (path, id) => {
  const apiUrl = path;
  const payload = {
    IDValue: id
  };
  return (dispatch) => {
    return dispatch(fetchData(apiUrl, payload, null, "POST"))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export const initialStateAsl = {
  idAsl: 0,
  idRegione: 0,
  idProvincia: 0,
  idComune: 0,
  anno: 0,
  codRegione: "",
  codAzienda: "",
  descrizione: "",
  piva: "",
  indirizzo: "",
  telefono: "",
  fax: "",
  email: "",
  sitoWeb: "",
  eliminato: false
};
export const initialStateComune = {
  idComune: 0,
  idProvincia: 0,
  istat: "",
  codice: "",
  cap: "",
  descrizione: "",
  eliminato: false
};
export const initialStateEsenzioni = {
  idEsenzione: 0,
  codice: "",
  descrizione: "",
  tipo: 0,
  gravidaDa: 0,
  gravidaA: 0,
  autocertificata: 0,
  eliminato: false
};

export const initialStateStrutture = {
  id: 0,
  idstruttura: 0,
  nome: "",
  riferimentoCommerciale: "",
  sitoWeb: "",
  dataAttivazione: "",
  eliminato: false,
  idComune: 0,
  indirizzo: "",
  PartitaIva: "",
  pathLogo: "",
  pathFile: "",
  codiceIpa: "",
  dataAttivazioneAsValue: "",
  passwordUtenteStartUp: "",
  comuneAsString: "",
  telefono: "",
  emailRiferimento: ""
};

export const initialStateMedici =
{
  id: 0,
  idmedico: 0,
  idtipologiaMedico: 0,
  tipoMedico: "",
  comuneMedico: "",
  cognomeNome: "",
  tipo: 0,
  codice: "",
  riga1: "",
  riga2: "",
  cfPiva: "",
  idcomune: 0,
  indirizzo: "",
  telefono1: "",
  telefono2: "",
  eliminato: 0
};
export const initialStateBranche =
{
  id: 0,
  idbranca: 0,
  idstruttura: 0,
  codice: "",
  codiceFile: "",
  descrizione: "",
  numeroEsami: 0,
  giorniScadenzaImp: 0,
  eliminato: false
};
export const initialStateCupRisorse =
{
  id: 0,
  idsede: 0,
  idrisorsa: 0,
  descrizione: "",
  sedeAsString: "",
  tipo: 0,
  eliminato: false
};
export const initialStateCupFestivita =
{
  id: 0,
  idrisorsa: 0,
  descrizione: "",
  dataInizio: "",
  dataInizioAsString: "",
  dataFineAsString: "",
  dataFine: "",
  eliminato: false
};

export const initialStateCausaliCassa =
{
  id: 0,
  idcausale: 0,
  idstruttura: 0,
  descrizione: "",
  tipo: 0,
  tipoAsString: "",
  eliminato: false
};
export const initialStateListini =
{
  id: 0,
  idlistino: 0,
  idstruttura: 0,
  descrizione: "",
  dataInizio: "1900-01-01T00:00:00",
  dataFine: "2099-01-01T00:00:00",
  operazione: "+",
  valore: 0,
  eliminato: 0,
  dataInizioAsString: "01/01/1900",
  dataFineAsString: "01/01/1900",
  dalPrezzo: 0,
};
export const initialStateMediciRefertanti =
{
  id: 0,
  idmedicoRefertante: 0,
  idstruttura: 0,
  idUtente: "",
  cognome: "",
  nome: "",
  partitaIva: "",
  codiceFiscale: "",
  firmaMedico: "",
  email: "",
  iban: "",
  codiceIpa: "",
  eliminato: false,
};

export const initialStateCentriDiCosto =
{
  id: 0,
  idcentroCosto: 0,
  idstruttura: 0,
  codice: "",
  descrizione: "",
  eliminato: false,
  strutturaAsString: ""
};
export const initialStateIntestazioni =
{
  id: 0,
  idintestazione: 0,
  idstruttura: 0,
  tipo: 0,
  descrizione: "",
  eliminato: false,
  testoHtml: ""
};
export const initialStateSedi =
{
  id: 0,
  idsede: 0,
  idstruttura: 0,
  descrizione: "",
  intestazioneAsString: "",
};


export const initialStateAllegati =
{
  id: 0,
  idintestazione: 0,
  idstruttura: 0,
  gruppoAsString: "",
  gruppo: 0,
  descrizione: "",
  eliminato: false,
  testoHtml: ""
};
export const initialStatePreimpostati =
{
  id: 0,
  idpreimpostato: 0,
  IdmedicoRefertante: 0,
  eliminato: false,
  TestoHtml: ""
};
export const initialStateRegistriIva =
{
  id: 0,
  idregistro: 0,
  idstruttura: 0,
  idintestazione: 0,
  descrizione: "",
  piva: "",
  codiceFiscale: "",
  lettera: "",
  progressivo: 0,
  intestazioneAsString: "",
  strutturaAsString: "",
  azzeraNumerazione: false,
  codiceUfficio: "",
  utente: "",
  password: "",
  pinCode: "",
  pinCodeCifrato: "",
  codiceRegione: "",
  codiceAsl: "",
  eliminato: false,
};
export const initialStateNumeratoriFatture =
{
  id: 0,
  idregistro: 0,
  idstruttura: 0,
  lettera: "",
  intestazioneAsString: "",
  strutturaAsString: "",
  anno: 0,
  eliminato: false,
  azzeraNumerazione: false,
};
export const initialStateAmbulatori =
{
  id: 0,
  idambulatorio: 0,
  idstruttura: 0,
  idregistroIva: 0,
  idbranca: 1,
  idintestazione: 1,
  idpiede: 0,
  idetichetta: 0,
  codice: "",
  descrizione: "",
  stampaDefault: false,
  copieReferto: 1,
  eliminato: false,
  brancaAsString: "",
  intestazioneAsString: "",
  registroIvaAsString: "(Nessuno)",
  strutturaAsString: ""
};
export const initialStateEsami =
{
  id: 0,
  idesame: 0,
  idstruttura: 0,
  idambulatorio: 0,
  idintestazione: 0,
  idcentroCosto: 0,
  idallegato: 0,
  codice: "",
  codiceMinisteriale: "",
  descrizione: "",
  prescrivibile: false,
  prezzoAsl: 0,
  prezzoPrivato: 0,
  contabile: false,
  titoloReferto: "",
  eliminato: false,
  descrizioneNomenclatore: "",
  codiceNomenclatore: "",
  note: "",
  ambulatorioAsString: "",
  centroDiCostoAsString: "",
  intestazioneAsString: "",
  strutturaAsString: "",
  allegatoAsString: "",
  prezzoPrivatoAsString: "0",
  prezzoAslAsString: "0",
  infoPreparazione: ""
};

export const initialStateGrandiClienti = {
  id: 0,
  idgrandeCliente: 0,
  idlistino: 0,
  idcomune: 0,
  ragioneSociale: "",
  piva: "",
  codiceFiscale: "",
  indirizzo: "",
  telefono: "",
  email: "",
  iban: "",
  listinoDescrizione: "",
  comuneDescrizione: "",
  codiceIPA: "",
  franchigia: 0,
};

export const initialStateMediciCompensi = {
  id: 0,
  idListino: 0,
  idMedicoEsame: 0,
  compensoFisso: 0,
  compensoPercentuale: 0,
  compensoFissoSsn: 0,
  compensoPercentualeSsn: 0,
  dataInizio: "",
  dataFine: "",
  eliminato: false
};
export const impegnative = {
  id: 0,
  index: 0,
  idimpegnativa: 0,
  idaccettazione: 0,
  idbranca: 0,
  idregistro: 0,
  idasl: 0,
  idesenzione: 0,
  idtipoAccettazione: 0,
  Codice: "",
  dataPrescrizione: "",
  dataPrescrizioneAsString: "",
  consegnata: false,
  anno: 0,
  mese: 0,
  mazzetta: 0,
  progressivo: 0,
  lordo: 0,
  ticket: 0,
  esente: 0,
  netto: 0,
  privato: 0,
  eliminato: false,
  diagnosi: "",
  quotaRicetta: 0,
  visibile: false,
  sogliaTicket: 0,
  codice1: "",
  codice2: "",
  aslAsString: "",
  brancheAsString: "",
  esenzioniAsString: "",
  registriIvaAsString: "",
  tipoAccettazioneAsString: ""
}
export const dettaglioAccettazione = {
  id: 0,
  indexImpegnativa: 0,
  iddettaglio: 0,
  idaccettazione: 0,
  idesame: 0,
  idmedicoRefertante: 0,
  idimpegnativa: 0,
  idlistino: 0,
  idtipoAccettazione: 0,
  dataOraAccettazione: "",
  dataOraAccettazioneAsString: "",
  numero: 1,
  prezzo: 0,
  dataRefertazione: "",
  dataRefertazioneAsString: "",
  dataRitiro: "",
  dataRitiroAsString: "",
  fatturato: false,
  ordine: 0,
  esame: {
    id: 0,
    idesame: 0,
    idstruttura: 0,
    idambulatorio: 0,
    idintestazione: 0,
    idcentroCosto: 0,
    idallegato: 4,
    codice: "",
    codiceMinisteriale: "",
    descrizione: "",
    prescrivibile: false,
    prezzoAsl: 0,
    prezzoPrivato: 0,
    prezzoAslAsString: "",
    prezzoPrivatoAsString: "",
    contabile: false,
    titoloReferto: "",
    eliminato: true,
    descrizioneNomenclatore: "",
    codiceNomenclatore: "",
    note: "",
    AmbulatorioAsString: "",
    CentroDiCostoAsString: "",
    IntestazioneAsString: "",
    StrutturaAsString: "",
    allegatoAsString: ""
  },
  impegnativa: null,
  listino: {
    id: 0,
    idlistino: 0,
    idstruttura: 0,
    descrizione: "",
    dataInizio: "",
    dataFine: "",
    operazione: "*",
    valore: 1,
    dalPrezzo: 0,
    dalPrezzoAsString: "",
    eliminato: 0,
    dataInizioAsString: "",
    dataFineAsString: ""
  },
  medicoRefertante: {
    id: 0,
    idmedicoRefertante: 0,
    idstruttura: 0,
    idutente: "",
    cognome: "",
    nome: "",
    partitaIva: "",
    codiceFiscale: "",
    firmaMedico: "",
    email: "",
    iban: "",
    codiceIpa: "",
    eliminato: false
  }
};
export const initialDashboardData =
{
  numeroUltimeAccettazioni: 0,
  numeroNuoviUtenti: 0,
  numeroFattureEmesse: 0,
}

export const initialFiltroFatture = {
  idRegistro: 0,
  dataInizio: '',
  dataFine: '',
  periodo: 0,
  cognomeNome: '',
  numero: '',
  idGrandeCliente: 0,
  elencoRegistri: [],
  elencoGrandiClienti: [],
  isMemo: false,
}

export const initialFiltroReferti = {
  codiceAccettazione: "",
  cognomeNome: "",
  dataInizio: '',
  dataFine: '',
  idAmbulatorio: 0,
  idEsame: 0,
  descEsame: "",
  stato: 0,
  elencoEsami: [],
  elencoAmbulatori: [],
  isMemo: false,
}

export const initialFiltroMovimentiCassa = {
  idUtente: "",
  codiceAccettazione: "",
  paziente: "",
  idAnagarfica: 0,
  idFattura: 0,
  numeroFattura: 0,
  idTipoPagamento: 0,
  idGrandeCliente: 0,
  idRegistroIva: 0,
  idSede: 0,
  idCausaleCassa: 0,
  dataInizio: "",
  dataFine: "",
  periodo: -1,
  chiuso: 0
}

export const initialElenchiMovimentiCassa = {
  elencoFatture: [],
  elencoTipoPagamenti: [],
  elencoUtenti: [],
  elencoGrandiClienti: [],
  elencoRegistriIva: [],
  elencoSedi: [],
  elencoCausaliCassa: []
}

export const initialFiltroAnagrafiche = {
  email: '',
  telefono: '',
  indirizzo: '',
  codice: '',
  esame: '',
  codiceImpegnativa: '',
  datadiNascita: '',
  dataAccettazione: '',
}

export const initialNotaCredito = {
  idFattura: 0,
  idStruttura: 0,
  idEsame: 0,
  idDettaflio: 0,
  descrizione: "",
  importo: 0,
  tipo: 0,
  idUtente: '',
}
export const initialStateAccettazione = {
  id: 0,
  idaccettazione: 0,
  idstruttura: 0,
  idutente: "",
  idanagrafica: 0,
  idgrandeCliente: 0,
  idlistino: 0,
  idregistro: 0,
  idsede: 0,
  idpagamento: 0,
  idtipo: 0,
  codice: "",
  dataOra: "0001-01-01T00:00:00",
  dataOraAsString: "",
  dataOraUltimoSalvataggio: "0001-01-01T00:00:00",
  urgenza: false,
  sconto: 0,
  bollo: 0,
  totaleAsl: 0,
  totaleTicket: 0,
  totalePrivato: 0,
  totaleCauziozne: 0,
  totaleFatturato: 0,
  totaleDaFatturare: 0,
  pagato: 0,
  note: null,
  noteReferto: null,
  dataUm: "0001-01-01T00:00:00",
  dataUmAsString: "",
  dataRitiro: "0001-01-01T00:00:00",
  dataRitiroAsString: "",
  eliminato: false,
  elencoEsami: [],
  elencoListini: [],
  elencoPagamenti: [],
  dettaglioAccettaziones: [dettaglioAccettazione],
  impegnatives: [impegnative],
  incassato: 0,
  saldo: 0,
  aggiornaValori: 0,
  franchigia: 0,
  isFatturata: false,
};
export const initialFiltroAccettazioniStampe = {
  idUtente: "",
  codiceAccettazione: "",
  paziente: "",
  idAnagarfica: 0,
  idFattura: 0,
  numeroFattura: 0,
  idTipoPagamento: 0,
  idGrandeCliente: 0,
  idRegistroIva: 0,
  idSede: 0,
  dataInizio: "",
  dataFine: "",
  periodo: -1,
  tipoStampa: 0,
  tipoSaldo: 0,
  tipoReferto:0,
};
export const initialInfoUser = {
  cognome: "",
  nome: "",
  emailUtente: "",
  dataScadenzaPwd: "",
  cognomeNomeMedico: "",
  codiceFiscale: "",
  emailMedico: "",
  partitaIva: "",
  ruoli: "",
}

export const initialAccettazioniElenchi = {
  elencoFatture: [],
  elencoTipoPagamenti: [],
  elencoUtenti: [],
  elencoGrandiClienti: [],
  elencoRegistriIva: [],
  elencoSedi: [],
}
export const ResultAccettazioni = {
  utente: "",
  tipo: "",
  codice: "",
  dataAsString: "",
  sede: "",
  registro: "",
  grandeCliente: "",
  paziente: "",
  pagamento: "",
  completa: false,
  saldo: true,
  dettaglioAccettazione: []
}

