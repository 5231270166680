import * as yup from "yup";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { useEffect } from "react";
import { Formik } from "formik";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Breadcrumb } from "@gull";
import { useDispatch } from "react-redux";
import { salva, initialStateMediciCompensi } from "../ServiceController";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link } from "react-router-dom";
import { caricaListini } from "./service";
import DettaglioCompensi from "./DettaglioCompensi";
import { queryServerPost } from "../ServiceController";

const FormMediciCompensiTable = () => {
    //stato globale
    const [compensiState, setCompensiState] = useState(initialStateMediciCompensi);
    const dispatch = useDispatch();
    const [gridData, setGridData] = useState([]);
    const location = useLocation();
    const rigaSelezionata = location.state;
    const [isModifica, setIsModifica] = useState(false);

    // Funzione di callback per gestire il cambiamento nella griglia
    const handleGridChange = (newGridData) => {
        setGridData(newGridData);
    };

    const handleListiniChange = (event) => {
        setCompensiState((prevState) => ({
            ...prevState,
            idListino: event.target.value
        }));
    }

    const handleCompensoFissoChange = (event) => {
        const value = event.target.value;
        setCompensiState((prevState) => ({
            ...prevState,
            compensoFisso: value
        }));
    };

    const handleCompensoPercentualeChange = (event) => {
        const value = event.target.value;
        setCompensiState((prevState) => ({
            ...prevState,
            compensoPercentuale: value
        }));
    };

    const handleCompensoFissoSsnChange = (event) => {
        const value = event.target.value;
        setCompensiState((prevState) => ({
            ...prevState,
            compensoFissoSsn: value
        }));
    };

    const handleCompensoPercentualeSsnChange = (event) => {
        const value = event.target.value;
        setCompensiState((prevState) => ({
            ...prevState,
            compensoPercentualeSsn: value
        }));
    };

    const handleDataInizioChange = (event) => {
        setCompensiState((prevState) => ({
            ...prevState,
            dataInizio: event.target.value
        }));
    };

    const handleDataFineChange = (event) => {
        setCompensiState((prevState) => ({
            ...prevState,
            dataFine: event.target.value
        }));
    };

    const { id } = useParams();
    const [listini, setListini] = useState([]);
    useEffect(() => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        if (rigaSelezionata.isModifica) {
            setCompensiState((prevState) => ({
                ...prevState,
                idListino: rigaSelezionata.idListino,
                dataInizio: rigaSelezionata.dataInizio === null ? "" : rigaSelezionata.dataInizio.split("T")[0],
                dataFine: rigaSelezionata.dataFine === null ? "" : rigaSelezionata.dataFine.split("T")[0],
            }));
            setIsModifica(true);
        }
        else {
            setIsModifica(false);
            let dataOggi = new Date();
            setCompensiState((prevState) => ({
                ...prevState,
                idListino: 0,
                dataInizio: dataOggi.toISOString().split("T")[0],
                dataFine: "2099-12-31",
            }));
        }
        dispatch(caricaListini()).then((data) => {
            setListini(data);
        }).catch((error) => {
            console.log(error);
        });
    }, [dispatch]);

    const navigate = useNavigate();

    const clearNotifications = () => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    };

    const prepareDataToSend = (data) => {
        return data
            .filter(item => item.isEdit === true)
            .map(item => {
                const dataInizio = item.dataInizio !== "" || item.dataInizio !== undefined ? item.dataInizio.split("T")[0] : item.dataFine;
                const dataFine = item.dataFine === "" || item.dataFine === undefined ? "2099-12-31" : item.dataFine.split("T")[0];
                return {
                    ...item,
                    dataInizio,
                    dataFine,
                    isModifica: isModifica
                };
            });
    };

    const checkDateRange = async (row) => {
        const payload = {
            idMedicoRefertante: id,
            idListino: 0,
            dataInizio: row.dataInizio,
            dataFine: row.dataFine,
        };

        try {
            const data = await dispatch(queryServerPost(`MediciCompensi/CheckRangeDate`, payload));
            if (data.isSuccess) {
                return true;
            } else {
                NotificationManager.error(data.message, "Errore");
                return false;
            }
        } catch (error) {
            NotificationManager.error("Error fetching data", "Errore");
            console.error('Error fetching data:', error);
            return false;  // Assicurati di ritornare false in caso di errore
        }
    }

    const checkValiditaDati = (dataToSend, values) => {
        if (dataToSend.length === 0 || dataToSend[0].dataInizio !== values.dataInizio
            || (dataToSend[0].dataFine !== "2099-12-31"
                && dataToSend[0].dataFine !== values.dataFine)) {
            NotificationManager.warning("Selezionare 'Applica alla selezione' e poi procedere con il salvataggio", "Dati non aggiornati");
            return false;
        } else {
            return true;
        }
    }

    // submit
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        clearNotifications();
        const dataToSend = prepareDataToSend(gridData);
        const checkSalvataggio = checkValiditaDati(dataToSend, values);
        if (!checkSalvataggio) {
            setSubmitting(false);
            return;
        }
        let checkDate = true;
        if (!isModifica) {
            checkDate = await checkDateRange(dataToSend[0]);
        }
        if (checkDate) {
            dispatch(salva('MediciCompensi/SalvaDettaglio', dataToSend))
                .then((data) => {
                    NotificationManager.success(data.message, "Salva");
                    setTimeout(() => {
                        handleReset(resetForm);
                        navigate("/tabelle/medici-compensi");
                    }, 1000);
                }).catch((error) => {
                    NotificationManager.error("Error fetching data", "Errore");
                    console.error('Error submiting data:', error);
                }).finally(() => {
                    setSubmitting(false);
                });
        } else {
            setSubmitting(false);
        }
    };

    const handleReset = (resetForm) => {
        resetForm();
    };
    const [selezioneAbilitata, setSelezioneAbilitata] = useState(false);

    useEffect(() => {
        const { compensoFisso, compensoPercentuale, compensoFissoSsn, compensoPercentualeSsn } = compensiState;

        // Controlla se almeno un campo ha un valore e tutti gli altri sono 0
        const singleValueCondition = (
            (compensoFisso > 0 && compensoPercentuale === 0 && compensoFissoSsn === 0 && compensoPercentualeSsn === 0) ||
            (compensoPercentuale > 0 && compensoFisso === 0 && compensoFissoSsn === 0 && compensoPercentualeSsn === 0) ||
            (compensoFissoSsn > 0 && compensoFisso === 0 && compensoPercentuale === 0 && compensoPercentualeSsn === 0) ||
            (compensoPercentualeSsn > 0 && compensoFisso === 0 && compensoPercentuale === 0 && compensoFissoSsn === 0)
        );

        // Controlla se due campi hanno un valore ma non possono essere dello stesso tipo
        const twoValuesCondition = (
            (compensoFisso > 0 && compensoPercentuale === 0 && (compensoFissoSsn > 0 || compensoPercentualeSsn > 0)) ||
            (compensoPercentuale > 0 && compensoFisso === 0 && (compensoFissoSsn > 0 || compensoPercentualeSsn > 0)) ||
            (compensoFissoSsn > 0 && compensoPercentualeSsn === 0 && (compensoFisso > 0 || compensoPercentuale > 0)) ||
            (compensoPercentualeSsn > 0 && compensoFissoSsn === 0 && (compensoFisso > 0 || compensoPercentuale > 0))
        );

        const validCondition = singleValueCondition || twoValuesCondition;
        setSelezioneAbilitata(!validCondition);
    }, [compensiState.compensoFisso, compensiState.compensoPercentuale, compensiState.compensoFissoSsn, compensiState.compensoPercentualeSsn]);

    return (
        <div>
            <Breadcrumb
                routeSegments={[
                    { name: "Medici Compensi", path: "/tabelle/medici-compensi" },
                    { name: "Dettaglio Compenso" },
                ]}
            />
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="card-title mb-3">{isModifica ? "Modifica" : "Nuovo"} Dettaglio Compenso</div>
                            <Formik
                                initialValues={compensiState}
                                validationSchema={getValidationSchema(selezioneAbilitata)}
                                onSubmit={handleSubmit}
                                enableReinitialize={true}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 form-group mb-">
                                                <label htmlFor="dataInizio">Data inizio</label>
                                                <input
                                                    id="dataInizio"
                                                    name="dataInizio"
                                                    className="form-control"
                                                    placeholder="Data Inizio"
                                                    type="Date"
                                                    onChange={(event) => {
                                                        handleDataInizioChange(event);
                                                        handleChange(event); // Formik handler
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.dataInizio === "0001-01-01" ? "" : values.dataInizio}
                                                    disabled={isModifica}
                                                />
                                                {errors.dataInizio && touched.dataInizio && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.dataInizio}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <label htmlFor="dataFine">Data fine</label>
                                                <input
                                                    id="dataFine"
                                                    name="dataFine"
                                                    className="form-control"
                                                    placeholder="Data Fine"
                                                    type="Date"
                                                    onChange={(event) => {
                                                        handleDataFineChange(event);
                                                        handleChange(event); // Formik handler
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.dataFine === "0001-01-01" ? "" : values.dataFine}
                                                    disabled={isModifica}
                                                />
                                                {errors.dataFine && touched.dataFine && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.dataFine}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-12 form-group mb-3">
                                                <label htmlFor="idListino">Listino</label>
                                                <select
                                                    id="idListino"
                                                    className="form-control"
                                                    name="idListino"
                                                    value={values.idListino}
                                                    onChange={async event => {
                                                        handleListiniChange(event);
                                                        handleChange(event); // Formik handler
                                                    }}
                                                    onBlur={handleBlur}
                                                >
                                                    {listini.map(option => (
                                                        <option key={option.idlistino} value={option.idlistino}>{option.descrizione}</option>
                                                    ))}
                                                </select>
                                                {errors.idListino && touched.idListino && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.idListino}
                                                    </div>
                                                )}
                                            </div>
                                            <hr />
                                            <div className="col-md-6 form-group mb-3">
                                                <label htmlFor="compensoFisso">Compenso Fisso</label>
                                                <input
                                                    id="compensoFisso"
                                                    name="compensoFisso"
                                                    className="form-control"
                                                    placeholder="Compenso Fisso"
                                                    type="number"
                                                    autoComplete="off"
                                                    value={values.compensoFisso}
                                                    onChange={(event) => {
                                                        handleCompensoFissoChange(event);
                                                        handleChange(event); // Formik handler
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.compensoFisso && touched.compensoFisso && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.compensoFisso}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-6 form-group mb-">
                                                <label htmlFor="compensoPercentuale">Compenso Percentuale</label>
                                                <input
                                                    id="compensoPercentuale"
                                                    name="compensoPercentuale"
                                                    className="form-control"
                                                    placeholder="Compenso Percentuale"
                                                    type="number"
                                                    onChange={(event) => {
                                                        handleCompensoPercentualeChange(event);
                                                        handleChange(event); // Formik handler
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.compensoPercentuale}
                                                />
                                                {
                                                    errors.compensoPercentuale && touched.compensoPercentuale && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.compensoPercentuale}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <label htmlFor="compensoFissoSsn">Compenso Fisso SSN</label>
                                                <input
                                                    id="compensoFissoSsn"
                                                    name="compensoFissoSsn"
                                                    className="form-control"
                                                    placeholder="Compenso Fisso SSN"
                                                    type="number"
                                                    autoComplete="off"
                                                    value={values.compensoFissoSsn}
                                                    onChange={(event) => {
                                                        handleCompensoFissoSsnChange(event);
                                                        handleChange(event); // Formik handler
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.compensoFissoSsn && touched.compensoFissoSsn && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.compensoFissoSsn}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-6 form-group mb-">
                                                <label htmlFor="compensoPercentualeSsn">Compenso Percentuale SSN</label>
                                                <input
                                                    id="compensoPercentualeSsn"
                                                    name="compensoPercentualeSsn"
                                                    className="form-control"
                                                    placeholder="Compenso Percentuale SSN"
                                                    type="number"
                                                    onChange={(event) => {
                                                        handleCompensoPercentualeSsnChange(event);
                                                        handleChange(event); // Formik handler
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.compensoPercentualeSsn}
                                                />
                                                {
                                                    errors.compensoPercentualeSsn && touched.compensoPercentualeSsn && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.compensoPercentualeSsn}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 text-end">
                                                    <Button type="submit" disabled={isSubmitting}>Salva</Button>
                                                    <Link to="/tabelle/medici-compensi">
                                                        <button className="btn btn-outline-secondary m-1" type="button">
                                                            Annulla
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                        </div>
                                    </form>
                                )}
                            </Formik>
                            {
                                compensiState.dataInizio && compensiState.dataFine &&
                                <DettaglioCompensi fisso={compensiState.compensoFisso} percentuale={compensiState.compensoPercentuale}
                                    fissoSSN={compensiState.compensoFissoSsn} percentualeSSN={compensiState.compensoPercentualeSsn}
                                    dataInizio={compensiState.dataInizio} dataFine={compensiState.dataFine}
                                    idMedico={id} idListino={compensiState.idListino} isModifica={rigaSelezionata.isModifica}
                                    onGridChange={handleGridChange} selezioneAbilitata={selezioneAbilitata} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function getValidationSchema(isEdit) {
        const schema = {
            dataInizio: yup.date(),
            dataFine: yup.date()
                .when('dataInizio', (dataInizio, schema) =>
                    dataInizio
                        ? schema.min(dataInizio, 'Data fine deve essere maggiore di data inizio')
                        : schema
                ),
            compensoFisso: yup.number()
                .required('Il compenso fisso è obbligatorio')
                .test(
                    'compensoFisso-compensoPercentuale',
                    'Uno tra compenso fisso e compenso percentuale deve essere 0',
                    function (value) {
                        const { compensoPercentuale } = this.parent;
                        return (value === 0 && compensoPercentuale !== 0) || (value !== 0 && compensoPercentuale === 0);
                    }
                ),
            compensoPercentuale: yup.number()
                .when('compensoFisso', (compensoFisso, schema) => {
                    if (compensoFisso !== 0) return schema.notRequired();
                    return schema.required('Il compenso percentuale è obbligatorio quando il compenso fisso è 0');
                }),
            compensoFissoSsn: yup.number()
                .required('Il compenso fisso SSN è obbligatorio')
                .test(
                    'compensoFissoSsn-compensoPercentualeSsn',
                    'Uno tra compenso fisso SSN e compenso percentuale SSN deve essere 0',
                    function (value) {
                        const { compensoPercentualeSsn } = this.parent;
                        return (value === 0 && compensoPercentualeSsn !== 0) || (value !== 0 && compensoPercentualeSsn === 0);
                    }
                ),
            compensoPercentualeSsn: yup.number()
                .when('compensoFissoSsn', (compensoFissoSsn, schema) => {
                    if (compensoFissoSsn !== 0) return schema.notRequired();
                    return schema.required('Il compenso percentuale SSN è obbligatorio quando il compenso fisso SSN è 0');
                }),
            dataInizio: yup.string()
                .required("obbligatorio")
        };

        if (isEdit) {
            schema.compensoFisso = yup.mixed().notRequired();
            schema.compensoFissoSsn = yup.mixed().notRequired();
            schema.compensoPercentuale = yup.mixed().notRequired();
            schema.compensoPercentualeSsn = yup.mixed().notRequired();
            schema.dataInizio = yup.mixed().notRequired();
        }
        return yup.object().shape(schema);
    }
};

export default FormMediciCompensiTable;