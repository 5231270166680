import { lazy } from "react";
import FormMediciCompensiTable from "./MediciCompensi/FormMediciCompensiTable";
import RiepilogoMediciCompensi from "./MediciCompensi/RiepilogoMediciCompensi";
import AccettazioneSaldi from "./Accettazione/AccettazioneSaldi";
import ElencoCompensiMedico from "./MediciCompensi/ElencoCompensiMedico";
import FormConfigurazioniMail from "./ConfigurazioniMail/FormConfigurazioniMail";
import ElencoEmail from "./Email/elencoEmail";

const FormAslTable = lazy(() => import("./Asl/FormAslTable"));
const FormComuniTable = lazy(() => import("./Comuni/FormComuniTable"));
const ListInTable = lazy(() => import("./ElencoGenerale/ListInTable"));
const FormEsenzioniTable = lazy(() => import("./Esenzioni/FormEsenzioniTable"));
const FormStruttureTable = lazy(() => import("./Strutture/FormStruttureTable"));
const FormMediciTable = lazy(() => import("./Medici/FormMediciTable"));
const FormBrancheTable = lazy(() => import("./Branche/FormBrancheTable"));
const FormCausaliCassaTable = lazy(() => import("./CausaliCassa/FormCausaliCassaTable"));
const FormListiniTable = lazy(() => import("./Listini/FormListiniTable"));
const FormCentriDiCostoTable = lazy(() => import("./CentriDiCosto/FormCentriDiCostoTable"));
const FormIntestazioniTable = lazy(() => import("./Intestazioni/FormIntestazioniTable"));
const FormEditor = lazy(() => import("./Editor/FormEditor"));
const FormAllegatiTable = lazy(() => import("./Allegati/FormAllegatiTable"));
const FormRegistriIvaTable = lazy(() => import("./RegistriIva/FormRegistriIvaTable"));
const FormAmbulatoriTable = lazy(() => import("./Ambulatori/FormAmbulatoriTable"));
const FormEsamiTable = lazy(() => import("./Esami/FormEsamiTable"));
const FormGrandiClientiTable = lazy(() => import("./GrandiClienti/FormGrandiClientiTable"));
const FormMediciRefertantiTable = lazy(() => import("./MediciRefertanti/FormMediciRefertantiTable"));
const DashboardTabelle = lazy(() => import("./DashboardTabelle"));
const ElencoPreimpostati = lazy(() => import("./MediciRefertanti/ElencoPreimpostati"));
const FormPreimpostatiTable = lazy(() => import("./MediciRefertanti/FormPreimpostatiTable"));
const FormSediTable = lazy(() => import("./Sedi/FormSediTable"));
const FormAccettazioneRoute = lazy(() => import("./Accettazione/FormAccettazioneRoute"));
const OpzioniComponent = lazy(() => import("./Opzioni/OpzioniComponent"));
const UploadCertificato = lazy(() => import("./Certificati/FormCertificato"));
const AttivitaAnagrafiche = lazy(() => import("./Accettazione/AttivitaAnagrafiche"));
const FattureIndex = lazy(() => import("./Fatture/FattureIndex"));
const FatturaCad = lazy(() => import("./Fatture/FatturaCad"));
const FormEditorReferti = lazy(() => import("./Editor/FormEditorReferti"));
const MovimentCassa = lazy(() => import("./MovimentiCassa/movimentiCassa"));
const Genera730Index = lazy(() => import("./730"));
const FatturaAssicurazioni = lazy(() => import("./Fatture/FatturaAssicurazioni"));

const tabellePocrRoute = [
  // OPZIONI
  {
    path: "opzioni",
    element: <OpzioniComponent />,
  },
  // DASHBOARD TABELLE
  {
    path: "tabelle",
    element: <DashboardTabelle />,
  },
  // PREIMPOSTATI
  {
    path: "tabelle/preimpostati/:id",
    element: <ElencoPreimpostati />,
  },
  {
    path: "tabelle/preimpostati/form/:id/:idpreimpostato",
    element: <FormPreimpostatiTable />,
  },
  {
    path: "tabelle/preimpostati/form/:id/",
    element: <FormPreimpostatiTable />,
  },
  // MEDICI
  {
    path: "tabelle/medici/form",
    element: <FormMediciTable />,
  },
  {
    path: "tabelle/medici/form/:id",
    element: <FormMediciTable />,
  },
  // SEDI
  {
    path: "tabelle/sedi/form",
    element: <FormSediTable />,
  },
  {
    path: "tabelle/sedi/form/:id",
    element: <FormSediTable />,
  },
  // ASL
  {
    path: "/tabelle/asl/form/:id",
    element: <FormAslTable />,
  },
  {
    path: "/tabelle/asl/form",
    element: <FormAslTable />,
  },
  // COMUNI
  {
    path: "tabelle/comuni/form",
    element: <FormComuniTable />,
  },
  {
    path: "tabelle/comuni/form/:id",
    element: <FormComuniTable />,
  },
  // ESENZIONI
  {
    path: "tabelle/esenzioni/form/:id",
    element: <FormEsenzioniTable />,
  },
  {
    path: "tabelle/esenzioni/form",
    element: <FormEsenzioniTable />,
  },
  // STRUTTURE
  {
    path: "tabelle/strutture/form",
    element: <FormStruttureTable />,
  },
  {
    path: "tabelle/strutture/form/:id",
    element: <FormStruttureTable />,
  },
  // BRANCHE
  {
    path: "tabelle/branche/form",
    element: <FormBrancheTable />,
  },
  {
    path: "tabelle/branche/form/:id",
    element: <FormBrancheTable />,
  },
  // LISTINI
  {
    path: "tabelle/listini/form",
    element: <FormListiniTable />,
  },
  {
    path: "tabelle/listini/form/:id",
    element: <FormListiniTable />,
  },
  // CAUSALI CASSA
  {
    path: "tabelle/causali-cassa/form",
    element: <FormCausaliCassaTable />,
  },
  {
    path: "tabelle/causali-cassa/form/:id",
    element: <FormCausaliCassaTable />,
  },
  // CENTRI DI COSTO
  {
    path: "tabelle/centri-di-costo/form",
    element: <FormCentriDiCostoTable />,
  },
  {
    path: "tabelle/centri-di-costo/form/:id",
    element: <FormCentriDiCostoTable />,
  },
  // INTESTAZIONI
  {
    path: "tabelle/intestazioni/form",
    element: <FormIntestazioniTable />,
  },
  {
    path: "tabelle/intestazioni/form/:id",
    element: <FormIntestazioniTable />,
  },
  // ALLEGATI
  {
    path: "tabelle/allegati/form/",
    element: <FormAllegatiTable />,
  },
  {
    path: "tabelle/allegati/form/:id",
    element: <FormAllegatiTable />,
  },
  // REGISTRI IVA
  {
    path: "tabelle/registri-iva/form",
    element: <FormRegistriIvaTable />,
  },
  {
    path: "tabelle/registri-iva/form/:id",
    element: <FormRegistriIvaTable />,
  },
  // AMBULATORI
  {
    path: "tabelle/ambulatori/form",
    element: <FormAmbulatoriTable />,
  },
  {
    path: "tabelle/ambulatori/form/:id",
    element: <FormAmbulatoriTable />,
  },
  // ESAMI
  {
    path: "tabelle/esami/form",
    element: <FormEsamiTable />,
  },
  {
    path: "tabelle/esami/form/:id",
    element: <FormEsamiTable />,
  },
  // GRANDI CLIENTI
  {
    path: "tabelle/grandi-clienti/form",
    element: <FormGrandiClientiTable />,
  },
  {
    path: "tabelle/grandi-clienti/form/:id",
    element: <FormGrandiClientiTable />,
  },
  // MEDICI REFERTANTI
  {
    path: "tabelle/medici-refertanti/form",
    element: <FormMediciRefertantiTable />,
  },
  {
    path: "tabelle/medici-refertanti/form/:id",
    element: <FormMediciRefertantiTable />,
  },
  // TABELLA GENERICA
  {
    path: "tabelle/:id",
    element: <ListInTable />,
  },
  // REFERTIAZIONE
  {
    path: "refertazione/edit",
    element: <FormEditorReferti />,
  },
  // EDITOR
  {
    path: "tabelle/editor/:id/:tipo",
    element: <FormEditor />,
  },
  // MEDICI COMPENSI
  {
    path: "tabelle/medici-compensi",
    element: <RiepilogoMediciCompensi />,
  },
  {
    path: "tabelle/medici-compensi/form/:id",
    element: <FormMediciCompensiTable />,
  },
  // ACCETTAZIONE
  {
    path: "accettazione/form",
    element: <FormAccettazioneRoute />,
  },
  {
    path: "accettazione/form/:idAnagrafica/:idAccettazione",
    element: <FormAccettazioneRoute />,
  },
  {
    path: "accettazione/saldi",
    element: <AccettazioneSaldi />,
  },
  // FATTURE
  {
    path: "contabilita/fatture",
    element: <FattureIndex />,
  },
  // FATTURA CAD
  {
    path: "contabilita/fatturecad/:id",
    element: <FatturaCad />,
  },
  // MOVIMENTI CASSA
  {
    path: "contabilita/movimenti-cassa/",
    element: <MovimentCassa />,
  },
  // ELENCO EMAIL
  {
    path: "contabilita/email",
    element: <ElencoEmail />,
  },
  // MOVIMENTI CASSA
  {
    path: "movimenti-cassa",
    element: <MovimentCassa isAdmin={false} />,
  },
  // CERTIFICATI
  {
    path: "tabelle/certificati/form",
    element: <UploadCertificato />,
  },
  // AttivitaAnagrafiche
  {
    path: "accettazione/attivita/:id",
    element: <AttivitaAnagrafiche />,
  },
  // 730 precompilato
  {
    path: "contabilita/precompilato-730/",
    element: <Genera730Index />,
  },
  // Compensi medici
  {
    path: "contabilita/compensi",
    element: <ElencoCompensiMedico />,
  },
  // FattureAssicurazioni
  {
    path: "contabilita/fattura-assicurazioni",
    element: <FatturaAssicurazioni />,
  },
  //Configurazioni Mail
  {
    path: "tabelle/configurazioni-mail/form",
    element: <FormConfigurazioniMail />,
  },
  {
    path: "tabelle/configurazioni-mail/form/:id",
    element: <FormConfigurazioniMail />,
  },
];
export default tabellePocrRoute;
