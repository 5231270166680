import jsPDF from "jspdf";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ListaAccettazioni = (lista) => {
  const navigate = useNavigate();
  const handlePrint = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    const columns = [
      "Nome Utente",
      "Cod. Accettazione",
      "Data",
      "Paziente",
      "Grande cliente",
      "Registro IVA",
      "Pagamento",
      "Sede",
      "Note",
      "Saldo",
      "Completa",
    ];

    const rows = lista.lista.map((item) => {
      return [
        item.utente,
        item.codice,
        item.dataAsString,
        item.paziente,
        item.grandeCliente,
        item.registro,
        item.pagamento,
        item.sede,
        item.note,
        item.saldo,
        item.completa,
      ];
    });

    let startY = 20;

    const addHeader = () => {
      doc.setFontSize(16);
      doc.text("Lista Accettazioni", 14, startY - 10);
    };

    const addTable = () => {
      addHeader();

      doc.autoTable({
        head: [columns],
        body: [...rows],
        startY: startY,
        margin: { top: 20 },
        didDrawPage: function (data) {
          addHeader();
        },
        didDrawCell: function (data) {
          if (
            data.row.index === rows.length || // indice per totalRow
            data.row.index === rows.length + 1
          ) {
            // indice di differenzaRow
            const { doc, cell } = data;
            doc.setDrawColor(0, 0, 0); // colore della linea
            doc.setLineWidth(0.1); // spessore della linea
            doc.line(cell.x, cell.y, cell.x + cell.width, cell.y); // Disegna la linea
          }
        },
      });
      startY = doc.autoTable.previous.finalY + 10;
    };
    addTable();
    window.open(doc.output("bloburl"), "_blank");
  };

  const truncateText = (text) => {
    const maxLength = 30;
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const handleApriClick = (pathTo) => {
    navigate(pathTo, { state: { redirectPath: '/accettazione/saldi' } });
  }

  if (!Array.isArray(lista.lista) || lista.lista.length === 0) {
    return <div>Nessun dato disponibile</div>;
  }
  return (
    <div>
      {lista.lista.length > 0 && (
        <div className="row">
          <button
            onClick={handlePrint}
            className="col-1 mx-3 my-2 btn btn-primary"
          >
            Stampa elenco
          </button>
        </div>
      )}
      <div className="table-responsive">
        <Table
          hover
          id="user_table"
          className="table dataTable-collapse text-center"
        >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Utente</th>
              <th scope="col">Cod. Accettazione</th>
              <th scope="col">Data e Ora</th>
              <th scope="col">Paziente</th>
              <th scope="col">Grande cliente</th>
              <th scope="col">Registro Iva</th>
              <th scope="col">Tipo Pagamento</th>
              <th scope="col">Sede</th>
              <th scope="col">Note</th>
              <th scope="col">Saldo</th>
              <th scope="col">Completa</th>
              <th scope="col">Azione</th>
            </tr>
          </thead>
          <tbody>
            {lista.lista.map((accettazione, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{accettazione.utente}</td>
                <td>{accettazione.codice}</td>
                <td>{accettazione.dataAsString}</td>
                <td>{accettazione.paziente}</td>
                <td>{accettazione.grandeCliente}</td>
                <td>{accettazione.registro}</td>
                <td>{accettazione.pagamento}</td>
                <td>{accettazione.sede}</td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${index}`}>
                        {accettazione.note}
                      </Tooltip>
                    }
                  >
                    <span>{truncateText(accettazione.note)}</span>
                  </OverlayTrigger>
                </td>
                <td> {accettazione.saldo ? "saldo aperto" : ""}</td>
                <td> {accettazione.completa ? "completa" : "NON completa"}</td>
                <button
                  className="btn btn-success"
                  onClick={() => handleApriClick(`/accettazione/form/${accettazione.idAnagrafica}/${accettazione.idAccettazione}`)}
                >
                  Apri
                </button>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default ListaAccettazioni;
