import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from '@gull';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import { getQueryPostDto, getQuery } from "../../tabellaPocr/ServiceController";
import { useDispatch } from 'react-redux';
import CupEventoModal from './CupEventoModal';
import CupPrenotaModal from './CupPrenotaModal';
import { useLocation } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { format } from 'date-fns';

const CupCalendario = () => {
    const calendarRef = useRef(null);
    const location = useLocation();
    const [apri, setApri] = useState(false);
    const [apriPrenota, setApriPrenota] = useState(false);
    const [eventoPrenota, setEventoPrenota] = useState();
    const [data, setData] = useState();
    const [events, setEvents] = useState([]);
    const [risorse, setRisorse] = useState();
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [rawDisp, setRawDisp] = useState([]);
    const [selectedResource, setSelectedResource] = useState(0);
    const dispatch = useDispatch();
    const [minTime, setMinTime] = useState('07:00:00');
    const [maxTime, setMaxTime] = useState('21:00:00');
    const [oggetto, setOggetto] = useState();
    const [isLoading, setLoading] = useState(false);
    const clickTimeoutRef = useRef(null);

    const handleDatesSet = (arg) => {
        getElenco(arg.start, arg.end, 0);
    }

    const getElenco = (start, end, idrisorsa) => {
        const filtro = {
            idRisorsa: 0,
            periodo: 0,
            dataInizio: start,
            dataFine: end,
            elencoRisorse: [],
        }

        dispatch(getQueryPostDto('CupDisponibilitaFull/Elenco', filtro))
            .then((result) => {
                setRawDisp(result);
                const mappedEvents = mapCupDisponibilitaToEvents(result);
                setEvents(mappedEvents);
                if (idrisorsa != 0) {
                    filterEventsByResource(idrisorsa, mappedEvents);
                } else {
                    filterEventsByResource(selectedResource, mappedEvents);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        dispatch(getQuery('CupDisponibilitaFull/InitFilter'))
            .then((result) => {
                setRisorse(result.elencoRisorse);
                const calendarApi = calendarRef.current.getApi();
                const view = calendarApi.view;
                const start = view.activeStart;
                const end = view.activeEnd;
                const state = location.state;
                let idrisorsa = 0;
                if (state != null) {
                    idrisorsa = state[0].risorse[0].idrisorsa;
                    const syntheticEvent = {
                        target: {
                            value: idrisorsa
                        }
                    };
                    handleResourceChange(syntheticEvent);
                }
                getElenco(start, end, idrisorsa);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const filterEventsByResource = (resourceId, eventsToFilter = events) => {
        if (resourceId === '') {
            setFilteredEvents(eventsToFilter);
        } else {
            const filtered = eventsToFilter.filter(event => event.extendedProps.idrisorsa == resourceId);
            setFilteredEvents(filtered);
            updateMinMaxTime(filtered);
        }
    };

    const mapCupDisponibilitaToEvents = (disponibilita) => {
        const eventList = [];
        disponibilita.forEach(item => {
            if (item.isLibera) {
                eventList.push({
                    id: item.id,
                    start: item.dataInizio,
                    end: item.dataFine,
                    display: 'background',
                    extendedProps: {
                        note: item.note,
                        isLibera: item.isLibera,
                        idrisorsa: item.idrisorsa,
                        risorsa: item.risorsa,
                        iddisponibilita: item.iddisponibilita
                    }
                });
            } else {
                eventList.push({
                    id: item.id,
                    title: item.esame,
                    start: item.dataInizio,
                    end: item.dataFine,
                    backgroundColor: 'primary',
                    extendedProps: {
                        note: item.note,
                        idrisorsa: item.idrisorsa,
                        convenzionato: `${item.convenzionato ? "SI" : "NO"}`,
                        forzata: item.forzata,
                        isLibera: item.isLibera,
                        dataOraCreazione: item.dataOraCreazione,
                        risorsa: item.risorsa,
                        iddisponibilita: item.iddisponibilita
                    }
                });
            }
        });
        return eventList;
    };

    const handleResourceChange = (event) => {
        let resourceId = event.target.value;
        setSelectedResource(resourceId);
        setFilteredEvents([]);
        setEventoPrenota();
        filterEventsByResource(resourceId);
    };

    const handleChiudi = () => {
        setApri(false);
    }

    const handleChiudiPrenota = () => {
        setApriPrenota(false);
    }

    const handleEventClick = (arg) => {
        const { event } = arg;
        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
            clickTimeoutRef.current = null;
            if (!event.extendedProps.isLibera) {
                setOggetto(event);
                setApri(true);
            } else {
                setEventoPrenota(event);
            }
        } else {
            clickTimeoutRef.current = setTimeout(() => {
                clickTimeoutRef.current = null;
            }, 300);
        }
    };

    const handleDateClick = (arg) => {
        const { date } = arg;
        if (eventoPrenota != undefined) {
            setData(date);
            handlePrenotazione(date, eventoPrenota);
        }
    };

    const handlePrenotazione = (data, evento) => {
        setApriPrenota(true);
    }

    const updateMinMaxTime = (events) => {
        if (events.length > 0) {
            const startTimes = events.map(event => {
                const date = new Date(event.start);
                return date.getHours() + date.getMinutes() / 60;
            });

            const endTimes = events.map(event => {
                const date = new Date(event.end);
                return date.getHours() + date.getMinutes() / 60;
            });

            const minStartTime = Math.min(...startTimes);
            const maxEndTime = Math.max(...endTimes);

            // Use fixed decimal to ensure correct formatting
            setMinTime(`${String(Math.floor(minStartTime)).padStart(2, '0')}:${String(Math.round((minStartTime % 1) * 60)).padStart(2, '0')}:00`);
            setMaxTime(`${String(Math.floor(maxEndTime)).padStart(2, '0')}:${String(Math.round((maxEndTime % 1) * 60)).padStart(2, '0')}:00`);
        }
    }



    const handleEventDrop = (info) => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        const { event } = info;

        const calendar = info.view.calendar;
        const weekStart = calendar.view.currentStart;
        const weekEnd = calendar.view.currentEnd;
        // Verifica sovrapposizione
        if (isEventOverlapping(event)) {
            NotificationManager.warning("Prenotazione sovrapposta", "Attenzione");
            info.revert();
            return;
        }

        // Verifica se è in zona di disponibilità
        const backgroundEventId = isEventInBackground(event);
        if (backgroundEventId === 0) {
            NotificationManager.warning("Prenotazione in zona senza disponibilità", "Attenzione");
            info.revert();
            return;
        }

        setLoading(true);

        var disp = rawDisp.find(x => x.id == event.id);
        const dataToSend = {
            iddisponibilitaVecchio: event.id,
            iddisponibilitaNuovo: backgroundEventId,
            idrisorsa: disp.idrisorsa,
            idesame: disp.idesame,
            dataInizio: formattaData(event.start),
            dataFine: formattaData(event.end)
        }

        dispatch(getQueryPostDto('CupPrenotazioni/SpostaPrenotazione', dataToSend))
            .then((result) => {
                if (result.isSuccess) {
                    NotificationManager.success(result.message, "Prenotazione Spostata");
                    getElenco(weekStart, weekEnd, selectedResource);
                } else {
                    NotificationManager.error(result.message, "Errore");
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    function formattaData(data) {
        const newData = new Date(data);
        return format(newData, 'dd/MM/yyyy HH:mm:ss');
    }

    const isEventOverlapping = (movedEvent) => {
        const calendarApi = calendarRef.current.getApi();
        const events = calendarApi.getEvents().filter(event => event.extendedProps.isLibera === false);

        return events.some((event) => {
            if (event.id !== movedEvent.id) {
                return (
                    (movedEvent.start >= event.start && movedEvent.start < event.end) ||
                    (movedEvent.end > event.start && movedEvent.end <= event.end) ||
                    (movedEvent.start <= event.start && movedEvent.end >= event.end)
                );
            }
            return false;
        });
    };

    const isEventInBackground = (event) => {
        const calendarApi = calendarRef.current.getApi();
        const backgroundEvents = calendarApi.getEvents().filter(event => event.extendedProps.isLibera === true);

        const foundEvent = backgroundEvents.find((bgEvent) => {
            return (
                event.start >= bgEvent.start &&
                event.end <= bgEvent.end
            );
        });

        return foundEvent ? foundEvent.id : 0;
    };

    return (
        <div>
            <Breadcrumb
                routeSegments={[{ name: "Prenotazioni", path: "/tab-cup/prenotazioni" }, { name: "Calendario" }]}
            ></Breadcrumb>
            <div className='row'>
                <div className='col-4 mb-4'>
                    <label htmlFor="elencorisorse">Seleziona risorsa</label>
                    {risorse &&
                        <select id="elencorisorse" className='form-control' value={selectedResource} onChange={handleResourceChange}>
                            <option key={0} value={0}>Seleziona...</option>
                            {risorse.map((item, index) => (
                                <option key={index} value={item.idrisorsa}>
                                    {item.descrizione}
                                </option>
                            ))}
                        </select>
                    }
                </div>
            </div>
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                }}
                buttonText={{
                    today: 'Oggi',
                    month: 'Mese',
                    week: 'Settimana',
                    day: 'Giorno',
                    list: 'Agenda'
                }}
                locale={itLocale}
                events={filteredEvents}
                datesSet={handleDatesSet}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                eventDrop={handleEventDrop}
                editable={true}
                droppable={true}
                slotMinTime={minTime}
                slotMaxTime={maxTime}
                slotDuration="00:05:00"
                slotLabelInterval="00:05:00"
            />
            <CupEventoModal
                open={apri}
                closeDialog={handleChiudi}
                eventObject={oggetto}
                handleDeleteEvent={handleChiudi}
            />
            <CupPrenotaModal
                open={apriPrenota}
                closeDialog={handleChiudiPrenota}
                eventObject={eventoPrenota}
                date={data}
                handleDeleteEvent={handleChiudiPrenota}
            />
            <NotificationContainer />
        </div>
    );
};

export default CupCalendario;
