const CupUtenteBanner = (props) => {
    const { utente, onSubmit } = props;

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card mb-4">
                    <div className="card-body d-flex align-items-justify">
                        <div className="mx-2 w-100">
                            <h5 className="fw-bolder">{utente.cognome} {utente.nome}</h5>
                            <h6 className="fw-bold">{utente.codiceFiscale}</h6>
                        </div>
                        <div className="mx-2 w-100">
                            <h6> <i className="fw-bold i-Telephone text-success"></i> TEL1: {utente.telefono}</h6>
                            <h6><i className="fw-bold i-Email text-info"></i> {utente.email}</h6>
                        </div>
                        <div className="mx-2 w-100">

                        </div>
                        <div className="w-25 d-flex flex-column justify-content-end">
                            <div className="row">
                                <div className="col-12">
                                    <button onClick={onSubmit} className="btn btn-primary w-100">Conferma e prenota</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CupUtenteBanner;