import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserData,userLoggedOut } from "../redux/auth/authSlice";
import jwtAuthService from "../services/jwtAuthService";
import sessionStorageService from "../services/sessionStorageService";

const Auth = ({ children }) => {
  const dispatch = useDispatch();

  const checkJwtAuth = () => {

    jwtAuthService.loginWithToken().then((user) => {
      dispatch(setUserData(user));
    }).catch((error) => {
      dispatch(userLoggedOut());
    });
  };

  useEffect(() => {
    dispatch(setUserData(sessionStorageService.getItem("auth_user")));
    checkJwtAuth();
  }, []);

  return <>{children}</>;
};

export default Auth;
