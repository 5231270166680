import "./index.css";
import "./index.scss";
import React from "react";
import App from "./app/App";
import { Provider } from "react-redux";
import { store } from "./app/redux/Store";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import reportWebVitals from "./reportWebVitals";

// Configura un nuovo QueryClient
const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* Wrap your entire application with QueryClientProvider */}
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools /> {/* Opzionale, per uno sviluppo più agevole */}
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
