import React, { createContext, useContext, useState, useMemo } from 'react';
import { initialFiltroFatture, initialFiltroReferti } from 'app/views/tabellaPocr/ServiceController';

const PocrContext = createContext();

export const usePocrContext = () => useContext(PocrContext);

export const PocrProvider = ({ children }) => {
    //FattureIndex
    const [filtro, setFiltro] = useState(initialFiltroFatture);
    const [datiFatture, setDatiFatture] = useState([]);

    //RefertiIndex
    const [filtroReferti, setFiltroReferti] = useState(initialFiltroReferti);
    const [datiReferti, setDatiReferti] = useState([]);

    const value = useMemo(() => ({
        filtro,
        setFiltro,
        datiFatture,
        setDatiFatture,
        filtroReferti,
        setFiltroReferti,
        datiReferti,
        setDatiReferti,
    }), [filtro, datiFatture, filtroReferti, datiReferti]);

    return (
        <PocrContext.Provider value={value}>
            {children}
        </PocrContext.Provider>
    );
};