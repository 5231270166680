import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authSliceReducer from "./auth/authSlice";
import layoutSliceReducer from "./layout/layoutSlice";
import loginSliceReducer from "./login/authSlice";
import ecommerceSliceReducer from "./ecommerce/ecommerceSlice";
import anagraficaReducer from "./Anagrafica/anagraficaSlice";
import variabiliStrutturaReducer from "./VariabiliStruttura/variabiliStrutturaSlice"

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    layout: layoutSliceReducer,
    login: loginSliceReducer,
    ecommerce: ecommerceSliceReducer,
    anagrafica: anagraficaReducer,
    variabiliStruttura: variabiliStrutturaReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});
