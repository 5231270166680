import React from "react";
import { Breadcrumb } from "@gull";
import { Typeahead } from "react-bootstrap-typeahead";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { getQuery, getQueryPostDto } from "../../tabellaPocr/ServiceController";
import { NotificationContainer } from "react-notifications";
import { Table, Badge } from "react-bootstrap";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import swal from "sweetalert2";

const CupPrenotazioniForm = () => {
    const initialFiltro = {
        idEsame: 0,
        idSede: 0,
        idRisorsa: 0,
        elencoEsami: [],
        elencoSedi: [],
        elencoRisorse: []
    }

    const intialDto = {
        dataInizio: format(new Date(), 'yyyy-MM-dd'),
        medico: false,
        giorno: false,
        listaEsami: []
    }

    const [filtro, setFiltro] = useState(initialFiltro);
    const [selected, setSelected] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [elenco, setElenco] = useState([]);
    const [dto, setDto] = useState(intialDto);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inizializzaFiltro = async () => {
        const data = await dispatch(getQuery(`CupPrenotazioni/InitFilter/`));
        setFiltro(data);
    }

    const handleFieldChange = useCallback((fieldName, value) => {
        setFiltro(prevFiltro => ({
            ...prevFiltro,
            [fieldName]: value,
        }));
    }, [setFiltro]);

    const handleDtoChange = useCallback((fieldName, value) => {
        setDto(prevDto => {
            if (fieldName === 'listaEsami') {
                return {
                    ...prevDto,
                    listaEsami: value,
                };
            } else {
                return {
                    ...prevDto,
                    [fieldName]: value,
                };
            }
        });
    }, [setDto]);

    const handleChangeTypeahead = (e) => {
        if (e && e.length > 0) {
            handleFieldChange("idEsame", e[0].idEsame);
            setSelected(e);
        }
    }

    useEffect(() => {
        setLoading(true);
        inizializzaFiltro()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Errore nel recupero dei dati:", error);
            });
    }, []);

    const handleAggiungi = () => {
        if (filtro.idEsame == 0) return;
        const element = {
            idEsame: filtro.idEsame,
            idRisorsa: filtro.idRisorsa,
            infoPreparazione: getInfoEsame(filtro.idEsame, 1),
            esame: getInfoEsame(filtro.idEsame, 2),
            prescrivibile: getInfoEsame(filtro.idEsame, 3),
            elencoRisorse: getElencoRisorseByEsame(filtro.idEsame),
        }
        setElenco(prevElenco => {
            const newElenco = [...prevElenco, element];
            handleDtoChange("listaEsami", newElenco);
            return newElenco;
        });
        handleFieldChange("idEsame", 0);
        setSelected([]);
    }

    const getInfoEsame = (idEsame, tipoInfo) => {
        const esame = filtro.elencoEsami.find(e => e.idEsame == idEsame);
        switch (tipoInfo) {
            case 1:
                return esame ? esame.infoPreparazione : "";
            case 2:
                return esame ? esame.descrizione : "";
            case 3:
                return esame ? esame.prescrivibile : false;
        }
    }

    const getElencoRisorseByEsame = (idEsame) => {
        const esame = filtro.elencoEsami.find(e => e.idEsame == idEsame);
        if (!esame) return [];
        const risorseFiltrate = filtro.elencoRisorse.filter(r => esame.risorse.includes(r.id));
        return risorseFiltrate;
    }

    const handleElimina = (index) => {
        setElenco(prevElenco => {
            const newElenco = prevElenco.filter((_, i) => i !== index);
            handleDtoChange("listaEsami", newElenco);
            return newElenco;
        });
    }

    const handleResourceChange = (index, value) => {
        setElenco(prevElenco => {
            const newElenco = prevElenco.map((item, i) => {
                if (i === index) {
                    return { ...item, idRisorsa: value };
                }
                return item;
            });
            handleDtoChange("listaEsami", newElenco);
            return newElenco;
        });
    };

    const prepareData = (data) => {
        const { dataInizio, listaEsami } = data;
        let result = {
            stessoGiorno: dto.giorno,
            stessoMedico: dto.medico,
            elencoEsami: []
        };

        listaEsami.forEach((esameSingolo) => {
            const { idEsame, idRisorsa, esame, elencoRisorse, prescrivibile } = esameSingolo;
            let listaIDRisorse = [];
            if (idRisorsa != 0) {
                listaIDRisorse.push(idRisorsa)
            } else {
                listaIDRisorse = elencoRisorse.map(item => item.id)
            }
            result.elencoEsami.push({
                dataInizio,
                idEsame,
                prescrivibile,
                esame,
                listaIDRisorse
            });
        });
        return result;
    };

    const handleSubmit = async () => {
        setLoading(true);
        const data = prepareData(dto);
        dispatch(getQueryPostDto('CupDisponibilitaFull/GetDisponibilitaByEsame', data))
            .then((result) => {
                if (result.isSuccess) {
                    const state = {
                        exams: result.data,
                        dataEsame: dto.dataInizio,
                        data: data
                    }
                    navigate('overview', {
                        state: state
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const getFilteredEsami = () => {
        return filtro.elencoEsami.filter(esame => !elenco.some(el => el.idEsame === esame.idEsame));
    };

    const handleClear = () => {
        setSelected([]);
    }

    const handleInfo = (testo) => {
        swal.fire({
            title: "Informazioni Preparazione",
            text: testo,
            icon: "info",
        });
    }

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Breadcrumb
                        routeSegments={[
                            { name: "Tabelle CUP", path: "/tab-cup" },
                            { name: "Prenotazioni" },
                        ]}
                    />
                    <div className="row">
                        <div className="col-10">
                            <div className="row">
                                <div className="form-group col-10">
                                    <label className="ul-form__label">Esame (codice o descrizione)</label>
                                    <Typeahead
                                        id="elencoEsami"
                                        name="elencoEsami"
                                        labelKey={(option) => `${option.codice} - ${option.descrizione}`}
                                        options={getFilteredEsami()}
                                        placeholder="Cerca esame..."
                                        selected={selected}
                                        onChange={(e) => handleChangeTypeahead(e)}
                                        filterBy={(option, props) => {
                                            const lowercasedInput = props.text.toLowerCase();
                                            const codeMatch = option.codice.toLowerCase().includes(lowercasedInput);
                                            const descriptionMatch = option.descrizione.toLowerCase().includes(lowercasedInput);
                                            return codeMatch || descriptionMatch;
                                        }}
                                    />
                                </div>
                                <div className="ms-auto form-group col-2">
                                    <label htmlFor="btnCerca" className="ul-form__label">
                                        Azione
                                    </label>
                                    <div className="row">
                                        <div className="input-right-icon col-6">
                                            <button
                                                onClick={handleAggiungi}
                                                disabled={filtro.idEsame == 0}
                                                id="btnCerca"
                                                name="btnCerca"
                                                className="btn btn-success text-white">
                                                +Aggiungi
                                            </button>
                                        </div>
                                        <div className="input-right-icon col-6">
                                            <button
                                                onClick={handleClear}
                                                id="btnDelete"
                                                name="btnDelete"
                                                className="btn btn-info text-white">
                                                Pulisci
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {elenco &&
                                <div className="table-responsive">
                                    <Table hover id="cupPrenotazioni_table" className="table dataTable-collapse">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">E/P</th>
                                                <th scope="col">Esame</th>
                                                <th scope="col">Preparazione</th>
                                                <th scope="col">Medico</th>
                                                <th scope="col">Azione</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {elenco.map((disp, index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        {index + 1}
                                                    </th>
                                                    <td>
                                                        <Badge className={`badge-round badge-round-opacity-${disp.prescrivibile ? "success" : "danger"}`}                                                       >
                                                            {disp.prescrivibile ? "E" : "P"}
                                                        </Badge>
                                                    </td>
                                                    <td>{disp.esame}</td>
                                                    <td>
                                                        {disp.infoPreparazione != ''
                                                            &&
                                                            <button
                                                                className="btn btn-info text-white"
                                                                onClick={() => handleInfo(disp.infoPreparazione)}
                                                            >
                                                                <i className="i-Information"></i>
                                                            </button>
                                                        }
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            value={disp.idRisorsa || ''}
                                                            onChange={(e) => handleResourceChange(index, e.target.value)}
                                                        >
                                                            <option key={0} value={0}>
                                                                Tutti
                                                            </option>
                                                            {disp.elencoRisorse.map(risorsa => (
                                                                <option key={risorsa.idrisorsa} value={risorsa.idrisorsa}>
                                                                    {risorsa.descrizione}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-danger text-white"
                                                            onClick={() => handleElimina(index)}
                                                        >
                                                            Elimina
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </div>
                        <div className="col-2">
                            <Button
                                onClick={() => navigate("/tab-cup/calendario")}
                                variant="primary"
                                className="btn-icon m-1 text-capitalize w-100">
                                <span className="ul-btn__icon">
                                    <i className="i-Calendar"></i>
                                </span>
                                <span className="ul-btn__text"> Calendario</span>
                            </Button>
                            <Button
                                onClick={() => navigate("/tab-cup/prenotazioni-risorse")}
                                variant="primary"
                                className="btn-icon m-1 text-capitalize w-100">
                                <span className="ul-btn__icon">
                                    <i className="i-Receipt-4"></i>
                                </span>
                                <span className="ul-btn__text"> Elenco</span>
                            </Button>
                            <Accordion className="my-3">
                                <Accordion.Item >
                                    <Accordion.Header className="w-100">
                                        Impostazioni Ricerca
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div id="custom-toggle3">
                                            <div className="list-group">
                                                <div>
                                                    <label className="form-label" htmlFor="dataInizio">Data disponibilità</label>
                                                    <input className="form-control"
                                                        type="date" id="dataInizio"
                                                        value={dto.dataInizio}
                                                        onChange={(e) => handleDtoChange("dataInizio", e.target.value)} />
                                                </div>

                                                <div className="mt-4">
                                                    <input disabled={elenco.length < 2} className="form-check-input" type="checkbox" id="medico" defaultChecked={dto.medico} onChange={(e) => {
                                                        handleDtoChange("medico", e.target.checked);
                                                    }} />
                                                    <label className="form-check-label mx-4" htmlFor="medico">&nbsp;Stesso Medico</label>
                                                </div>
                                                <div className="mt-4">
                                                    <input disabled={elenco.length < 2} className="form-check-input" type="checkbox" id="giorno" defaultChecked={dto.giorno} onChange={(e) => handleDtoChange("giorno", e.target.checked)} />
                                                    <label className="form-check-label mx-4" htmlFor="giorno">&nbsp;Stesso Giorno</label>
                                                </div>
                                                <div className="text-center mt-4">
                                                    <div className="col-12">
                                                        <button className="btn btn-primary w-100" disabled={elenco.length == 0} onClick={handleSubmit}>
                                                            Cerca
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </>
            )}
            <NotificationContainer />
        </div>
    )
}
export default CupPrenotazioniForm;
