class sessionStorageService {
  lc = window.localStorage;
  ss = window.sessionStorage;

  setItem(key, value) {
    value = JSON.stringify(value)
    this.ss.setItem(key, value)
    return true
  }

  getItem(key) {
    let value = this.ss.getItem(key)
    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }
  
  removeItem(key) {
    this.ss.removeItem(key);
  }

  clear() {
    this.ss.clear();
    this.lc.clear();
  }
}

export default new sessionStorageService();