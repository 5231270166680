import { Breadcrumb } from "@gull";
import React from "react";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { getQuery } from "../../tabellaPocr/ServiceController";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { fetchData } from "app/services/apiService";
import swal from "sweetalert2";

const ConfigurazioneRisorseTable = () => {

    const [elencoRisorse, setElencoRisorse] = useState([]);
    const [risorsa, setRisorsa] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [elenco, setElenco] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getElenco = async () => {
        const data = await dispatch(getQuery(`CupConfigurazioniRisorse/Elenco/${risorsa}`));
        setElenco(data);
    }

    const handleModifica = (idrisorsa, iddescrizioneAgenda) => {
        navigate(`edit/${idrisorsa}/${iddescrizioneAgenda}`);
    }

    const fetchResource = async () => {
        await Promise.all([
            dispatch(fetchDataFromApi("CupRisorse/ElencoRisorse", setElencoRisorse)),
        ]);
    };

    const fetchDataFromApi = (apiUrl, setData) => {
        return (dispatch) => {
            return dispatch(fetchData(apiUrl))
                .then((data) => {
                    setData(data);
                })
                .catch((error) => {
                    NotificationManager.error("Error fetching data", "Errore");
                    return Promise.reject(error);
                });
        };
    };

    useEffect(() => {
        fetchResource();
    }, [])

    useEffect(() => {
        setLoading(true);
        getElenco()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Errore nel recupero dei dati:", error);
            });
    }, [risorsa]);

    const handleDelete = (idrisorsa, iddescrizioneAgenda) => {
        swal.fire({
            title: "Attenzione",
            text: "Questa azione non sarà reversibile",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            cancelButtonText: "Annulla",
            confirmButtonText: "Okay, cancella!"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch(getQuery(`CupConfigurazioniRisorse/Elimina/${idrisorsa}/${iddescrizioneAgenda}`))
                    .then((data) => {
                        if (data.isSuccess) {
                            NotificationManager.success("Operazione effettuata con successo", "Elimina");
                            getElenco();
                        } else {
                            NotificationManager.error(data.message, "Errore");
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        console.error("Errore nel recupero dei dati:", error);
                    });
            }
        });
    }

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Breadcrumb
                        routeSegments={[
                            { name: "Tabelle CUP", path: "/tab-cup" },
                            { name: "Modello Agende" },
                        ]}
                    />
                    <Link className="pull-right my-3" to={"edit"}>
                        <button type="button" className="btn btn-primary text-white btn-rounded">Nuovo</button>
                    </Link>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor="tipo" className="ul-form__label">
                                Risorsa:
                            </label>
                            <select id="tipo" className="form-control"
                                defaultValue={risorsa}
                                onChange={(e) =>
                                    setRisorsa(e.target.value)
                                }
                                name="tipo">
                                <option key={0} value={0}>Tutti...</option>
                                {elencoRisorse.map(res => (
                                    <option key={res.idrisorsa} value={res.idrisorsa}>{res.descrizione}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            )}
            <NotificationContainer />
            <div className="row my-2">
                {elenco &&
                    <div className="table-responsive">
                        <Table
                            hover
                            id="configurazioniRisorse_table"
                            className="table dataTable-collapse text-center">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Risorsa</th>
                                    <th scope="col">Agenda</th>
                                    <th scope="col">Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {elenco.map((mov, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{mov.risorsa}</td>
                                        <td>{mov.agenda}</td>
                                        <td>
                                            <button className="btn btn-primary mx-2" title="Modifica" onClick={() =>
                                                handleModifica(mov.idrisorsa, mov.iddescrizioneAgenda)}><i className="i-Edit"></i></button>
                                            <button title="Elimina" className="btn btn-danger text-white" onClick={() =>
                                                handleDelete(mov.idrisorsa, mov.iddescrizioneAgenda)} >
                                                <i className="i-Remove"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </div>
    )
}
export default ConfigurazioneRisorseTable;