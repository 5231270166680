import React from "react";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { getQuery, getQueryPostDto } from "../../tabellaPocr/ServiceController";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import { Alert } from "react-bootstrap";

const CupPrenotazioniCalendarioForm = (props) => {
    const { idRisorsa, data } = props;

    const initialFiltro = {
        idEsame: 0,
        idSede: 0,
        idRisorsa: 0,
        elencoEsami: [],
        elencoSedi: [],
        elencoRisorse: []
    }

    const [filtro, setFiltro] = useState(initialFiltro);
    const [isLoading, setLoading] = useState(false);
    const [prenotabile, setPrenotabile] = useState(false);
    const [idDisponibilita, setIdDisponibilita] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inizializzaFiltro = async (idRisorsa) => {
        const data = await dispatch(getQuery(`CupPrenotazioni/InitFilter/${idRisorsa}`));
        setFiltro(data);
    }

    const handleFieldChange = useCallback((fieldName, value) => {
        setFiltro(prevFiltro => ({
            ...prevFiltro,
            [fieldName]: value,
        }));
    }, [setFiltro]);

    useEffect(() => {
        setLoading(true);
        inizializzaFiltro(idRisorsa)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Errore nel recupero dei dati:", error);
            });
    }, [idRisorsa]);

    const getInfoEsame = (idEsame, tipoInfo) => {
        const esame = filtro.elencoEsami.find(e => e.idEsame == idEsame);
        switch (tipoInfo) {
            case 1:
                return esame ? esame.infoPreparazione : "";
            case 2:
                return esame ? esame.durata : 0;
            case 3:
                return esame ? esame.descrizione : "";
        }
    }

    const handleSubmit = () => {
        if (idDisponibilita != 0) {
            const info = [{
                iddisponibilita: idDisponibilita,
                idesame: filtro.idEsame,
                idrisorsa: idRisorsa,
                dataInizio: format(data, 'yyyy-MM-dd'),
                oraInizio: format(data, 'HH:mm:ss'),
                durata: getInfoEsame(filtro.idEsame, 2),
                esame: getInfoEsame(filtro.idEsame, 3),
                risorsa: filtro.elencoRisorse.find(r => r.idrisorsa == idRisorsa).descrizione ?? ""
            }];
            navigate('/tab-cup/prenotazioni/overview/prenota', { state: { prenotazioni: info, idprenotazione: 0 } });
        } else {
            setPrenotabile(false);
        }
    };

    const handleEsameChange = (idEsame) => {
        if (idEsame > 0) {
            NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
            setLoading(true);
            const dto = {
                idEsame: idEsame,
                idRisorsa,
                durata: getInfoEsame(idEsame, 2),
                forzata: false,
                dataInizio: data,
                dataString: format(data, 'dd/MM/yyyy HH:mm:ss')
            };
            dispatch(getQueryPostDto('CupDisponibilitaFull/GetDisponibilitaSingola', dto))
                .then((result) => {
                    if (result.isSuccess) {
                        setPrenotabile(result.data != 0);
                        setIdDisponibilita(result.data);
                    } else {
                        NotificationManager.error(result.message, "Errore");
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                });
        }

    };

    const handleInfo = (testo) => {
        swal.fire({
            title: "Informazioni Preparazione",
            text: testo,
            icon: "info",
        });
    }

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="row">
                        <div className="form-group col-10">
                            <label className="ul-form__label">Esame</label>
                            <select
                                id="elencoEsami"
                                name="elencoEsami"
                                className="form-control"
                                value={filtro.idEsame}
                                onChange={(e) => { handleFieldChange("idEsame", e.target.value); handleEsameChange(e.target.value) }}>
                                <option value="0" key="-1">Seleziona esame...</option>
                                {filtro.elencoEsami.map((option) => (
                                    <option key={option.idEsame} value={option.idEsame}>
                                        {`${option.codice} - ${option.descrizione}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="btnSubmit" className="ul-form__label">
                                Azione
                            </label>
                            <div className="input-right-icon">
                                <button
                                    onClick={handleSubmit}
                                    disabled={filtro.idEsame == 0 || !prenotabile}
                                    id="btnSubmit"
                                    name="btnGenera"
                                    className="btn btn-primary text-white">
                                    Prenota
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row p-3">
                        {filtro.idEsame > 0
                            &&
                            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                                <div className="flex-grow-1">
                                    <h5 className="">
                                        Durata Esame: {getInfoEsame(filtro.idEsame, 2)} min.
                                    </h5>
                                    <p className="m-0 text-small text-muted">
                                        Info Preparazione: {getInfoEsame(filtro.idEsame, 1)}
                                    </p>
                                </div>
                                {!prenotabile &&
                                    <div>
                                        <Alert variant="warning">
                                            <Alert.Heading>Esame non prenotabile</Alert.Heading>
                                            <p>
                                                L'orario selezionato e la sua durata si sovrappongono con prenotazioni esistenti. Si prega di scegliere un orario alternativo.
                                            </p>
                                        </Alert>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </>
            )}
            <NotificationContainer />
        </div>
    )
}
export default CupPrenotazioniCalendarioForm;
