import "../styles/app/app.scss";
import Auth from "./auth/Auth";
import React from "react";
import routes from "./routes";
import { RouterProvider } from "react-router-dom";
import { PocrProvider } from "./context/pocrProvider";

function App() {
  return (<Auth>
    <PocrProvider>
      <RouterProvider router={routes} />;
    </PocrProvider>
  </Auth>)
}

export default App;
