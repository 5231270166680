import { useDispatch } from "react-redux";
import CupPrenotazioniBanner from "./CupPrenotazioniBanner";
import { useLocation, useNavigate } from "react-router-dom";
import CupUtentiCerca from "../CupUtenti/cupUtentiCerca";
import { getQueryPostDto, getQuery } from "../../tabellaPocr/ServiceController";
import { useEffect, useState } from "react";
import LoadingSpinner from "@gull/components/Loading/LoadingSpinner";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Breadcrumb } from "@gull";
import CupUtenteBanner from "../CupUtenti/cupUtenteBanner";

const CupGestionePrenota = () => {
    const location = useLocation();
    const { prenotazioni, idprenotazione } = location.state;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [utente, setUtente] = useState();

    const handleDataSubmit = (utente) => {
        setLoading(true);
        const dataToSend = {
            elenco: prenotazioni,
            utente: utente,
            idprenotazione: idprenotazione ?? 0
        }

        dispatch(getQueryPostDto('CupPrenotazioni/Prenota', dataToSend))
            .then((result) => {
                if (result.isSuccess) {
                    NotificationManager.success(result.message, "Salva");
                    setTimeout(() => {
                        navigate("/tab-cup/prenotazioni/")
                    }, 500);
                } else {
                    NotificationManager.error(result.message, "Errore");
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (idprenotazione && idprenotazione > 0) {
            setLoading(true);
            dispatch(getQuery(`CupPrenotazioni/GetUtenteDaPrenotazione/${idprenotazione}`))
                .then((result) => {
                    setUtente(result);
                })
                .catch((error) => {
                    console.error(error);
                });
            setLoading(false);
        }
    });


    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div>
                    <Breadcrumb
                        routeSegments={[
                            { name: "Prenotazioni", path: "/tab-cup/prenotazioni" },
                            { name: "Elenco Utenti" },
                        ]}
                    />
                    {prenotazioni &&
                        <CupPrenotazioniBanner prenotazioni={prenotazioni} />
                    }
                    {(idprenotazione == undefined || idprenotazione == 0)
                        &&
                        <CupUtentiCerca handleSubmit={handleDataSubmit} />
                    }
                    {
                        (idprenotazione > 0 && utente)
                        &&
                        <CupUtenteBanner utente={utente} onSubmit={() => { handleDataSubmit(utente) }} />
                    }
                    <NotificationContainer />
                </div>
            )}
        </>
    )
}

export default CupGestionePrenota;