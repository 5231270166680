import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    seCaricamento: false,
    error: { status: false, message: "" }
};

const appSlice = createSlice({
    name: "appSlice",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.seCaricamento = true;
        },
        stopLoading: (state) => {
            state.seCaricamento = false;
        },
        salvaDati: (state, action) => {
        },
        catchError: (state, action) => {
            state.error.status = true;
            state.error.message = action.payload;
            state.seCaricamento = false;
        },
        cleanError: (state) => {
            state.error.status = false;
            state.error.message = "";
        },
    }
});

export const { startLoading, stopLoading, saveData, catchError, cleanError } = appSlice.actions;
export default appSlice;